import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from 'src/app/_models/store';
import { StoreService } from 'src/app/_services/store.service';

@Component({
  selector: 'app-order-sub-cat-grid',
  templateUrl: './order-sub-cat-grid.component.html',
  styleUrls: ['./order-sub-cat-grid.component.scss']
})
export class OrderSubCatGridComponent implements OnInit {
  @Input() validUserData = "";
  @Input() subcat: any;
  @Input() selected: string;
  @Input() storeMenuFeatureFlag: boolean;
  @Input() storeDynamivHeightFeatureFlag: boolean;
  breakpoint: number;
  heightBox: any;
  @Output() onSubSelectHandler: EventEmitter<any> = new EventEmitter<any>();

  maxHeightMenuItem: number = 0;
  buttonHeight: number = 0;
  maxRowsPerPage: number = 0;
  itemsPerPage: number = 0;
  currentPage: number = 0;
  totalPages: number = 0;
  displayedCategories: any[] = [];
  totalPagesArray: number[] = [];
  touchStartX: number = 0;
  touchEndX: number = 0;
  mouseStartX: number = 0;
  mouseEndX: number = 0;
  isMouseDown: boolean = false;
  SWIPE_THRESHOLD: number = 50;
  @ViewChild('swipeSubCatContainer', { static: false }) swipeSubCatContainer!: ElementRef;
  private destroySubject: Subject<void> = new Subject();
  store: Store;
  storeSub: Subscription;

  constructor(private storeService: StoreService) {

  }

  ngOnInit() {
    this.maxHeightMenuItem = window.innerHeight - 350;
    if (this.storeMenuFeatureFlag) {
      if (window.innerWidth <= 767) {
        this.breakpoint = 3;
        this.heightBox = '100px';
      } else if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
        this.breakpoint = 5;
        this.heightBox = '130px';
      } else if (window.innerWidth >= 1024 && window.innerWidth <= 1279) {
        this.breakpoint = 5;
        this.heightBox = '135px';
      } else if (window.innerWidth >= 1280 && window.innerWidth <= 1535) {
        this.breakpoint = 6;
        this.heightBox = '162px';
      } else {
        this.breakpoint = 6;
        this.heightBox = '240px';
      }
    }
    else {
      if (window.innerWidth <= 499) {
        this.breakpoint = 3;
        this.heightBox = '65px';
      } else if (window.innerWidth >= 500 && window.innerWidth <= 599) {
        this.breakpoint = 3;
        this.heightBox = '70px';
      } else if (window.innerWidth >= 600 && window.innerWidth <= 900) {
        this.breakpoint = 4;
        this.heightBox = '80px';
      } else if (window.innerWidth >= 901 && window.innerWidth <= 1100) {
        this.breakpoint = 4;
        this.heightBox = '100px';
      } else {
        this.breakpoint = 5;
        this.heightBox = '100px';
      }
    }
  }

  onResize(event) {
    if (this.storeMenuFeatureFlag) {
      if (window.innerWidth <= 767) {
        this.breakpoint = 3;
        this.heightBox = '100px';
      } else if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
        this.breakpoint = 5;
        this.heightBox = '130px';
      } else if (window.innerWidth >= 1024 && window.innerWidth <= 1279) {
        this.breakpoint = 5;
        this.heightBox = '135px';
      } else if (window.innerWidth >= 1280 && window.innerWidth <= 1535) {
        this.breakpoint = 6;
        this.heightBox = '162px';
      } else {
        this.breakpoint = 6;
        this.heightBox = '240px';
      }
    }
    else {
      if (event.target.innerWidth <= 499) {
        this.breakpoint = 3;
        this.heightBox = '65px';
      } else if (event.target.innerWidth >= 500 && event.target.innerWidth <= 599) {
        this.breakpoint = 3;
        this.heightBox = '75px';
      } else if (event.target.innerWidth >= 600 && event.target.innerWidth <= 900) {
        this.breakpoint = 4;
        this.heightBox = '80px';
      } else if (event.target.innerWidth >= 901 && event.target.innerWidth <= 1100) {
        this.breakpoint = 4;
        this.heightBox = '100px';
      } else {
        this.breakpoint = 5;
        this.heightBox = '100px';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes['items'] && changes['items'].currentValue) {
        this.isStoreUndef();
      }
    }
  

  isStoreUndef() {
    if (!this.store) {
      this.storeSub = this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe((store) => {
        if (store) {
          this.store = Object.assign(new Store(), store);
          this.updateMaxHeight();
        }
      });
    } else {
      this.updateMaxHeight();
    }

  }

  updateMaxHeight() {
    this.maxHeightMenuItem = window.innerHeight - 320;
    if (this.storeDynamivHeightFeatureFlag) {
      this.buttonHeight = this.getButtonHeight();
      this.calculatePagination();
    }

  }

  getColumnsCount(): number {
    const width = window.innerWidth;
    if (width >= 1536) return 6;
    else if (width >= 1280) return 5;
    else if (width >= 1024) return 5;
    else if (width >= 768) return 5;
    else return 3;
  }

  getButtonHeight(): number {
    return window.innerWidth < 768 ? 100 : 200;
  }

  updateDisplayedSubCategories() {
    const start = this.currentPage * this.itemsPerPage;
    this.displayedCategories = this.subcat.slice(start, start + this.itemsPerPage);
  }

  getPaginatedSubCategories() {
    if (!this.subcat || this.subcat.length === 0) {
      return [];
    }

    const start = this.currentPage * this.itemsPerPage;
    return this.subcat.slice(start, start + this.itemsPerPage);
  }

  getHeightRange() {
    const width = window.innerWidth;
    if (width >= 1536) return { minHeight: 150, maxHeight: 240 };
    if (width >= 1280) return { minHeight: 150, maxHeight: 220 };
    if (width >= 1024) return { minHeight: 120, maxHeight: 200 };
    if (width >= 768) return { minHeight: 100, maxHeight: 180 };
    return { minHeight: 90, maxHeight: 150 };
  }

  calculatePagination() {
    if (!this.subcat || this.subcat.length === 0) {
      console.warn("subcat not loaded yet, skipping pagination calculation.");
      return;
    }

    const containerHeight = this.maxHeightMenuItem - 20;
    const columns = this.getColumnsCount();
    let { minHeight, maxHeight } = this.getHeightRange();
    let buttonHeight = this.buttonHeight || maxHeight;
    let bestHeight = buttonHeight;
    let fitsBetter = false;
    const adjustedItemLength = this.subcat.length;
    while (buttonHeight >= minHeight) {
      let testRows = Math.floor(containerHeight / buttonHeight);
      let testTotalItemsPerPage = testRows * columns;
      if (testTotalItemsPerPage >= adjustedItemLength) {
        bestHeight = buttonHeight;
        fitsBetter = true;
        break;
      }

      buttonHeight -= 2;
    }

    this.buttonHeight = fitsBetter ? bestHeight : Math.min(maxHeight, containerHeight / 3);

    let maxRowsThatFit = Math.floor(containerHeight / this.buttonHeight);

    if (maxRowsThatFit < 2) {
      maxRowsThatFit = 2;
      this.buttonHeight = containerHeight / maxRowsThatFit;
    }

    if (this.buttonHeight < 80) {
      this.buttonHeight = 80;
    }

    this.itemsPerPage = Math.max(1, maxRowsThatFit * columns);
    this.totalPages = Math.max(1, Math.ceil(adjustedItemLength / this.itemsPerPage));
    this.totalPagesArray = Array.from({ length: this.totalPages }, (_, index) => index);
    this.currentPage = Math.min(this.currentPage, this.totalPages - 1);
    this.updateDisplayedSubCategories();
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].clientX;
  }

  onTouchEnd(event: TouchEvent) {
    this.touchEndX = event.changedTouches[0].clientX;
    this.detectSwipe(this.touchStartX, this.touchEndX);
  }

  /* SWIPE FOR NON TOUCH DEVICE */
  // onMouseDown(event: MouseEvent) {
  //   this.isMouseDown = true;
  //   this.mouseStartX = event.clientX;
  // }

  // onMouseMove(event: MouseEvent) {
  //   if (this.isMouseDown) {
  //     this.mouseEndX = event.clientX;
  //   }
  // }

  // onMouseUp(event: MouseEvent) {
  //   this.isMouseDown = false;
  //   this.detectSwipe(this.mouseStartX, this.mouseEndX);
  // }

  detectSwipe(startX: number, endX: number) {
    const swipeDistance = startX - endX;
    if (swipeDistance > this.SWIPE_THRESHOLD) {
      this.goToNextPage();
    } else if (swipeDistance < -this.SWIPE_THRESHOLD) {
      this.goToPrevPage();
    }
  }

  goToPage(pageIndex: number) {
    if (pageIndex >= 0 && pageIndex < this.totalPages) {
      this.currentPage = pageIndex;
      this.updateDisplayedSubCategories();
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.updateDisplayedSubCategories();
    }
  }

  goToPrevPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.updateDisplayedSubCategories();
    }
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
