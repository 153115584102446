import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, Inject, Injector, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CashPaymentComponent } from '../cash-payment/cash-payment.component';
import { TipDialogComponent } from '../tip-dialog/tip-dialog.component';
import { CardPaymentConsumerChoiceComponent } from '../card-payment-consumer-choice/card-payment-consumer-choice.component';
import { OrderService } from '../../_services/order.service';
import { OrdersWithPayment } from 'src/app/_models/order';
import { OrderHelpers } from 'src/app/_helpers/helpers';
import { ManualCardDialogComponent } from '../manual-card-dialog/manual-card-dialog.component';
import { Store } from 'src/app/_models/store';
import { SplitPaymentComponent } from '../split-payment/split-payment.component';
import { RefreshService } from 'src/app/_services/refresh.service';
import { OpenOrderToggleService } from 'src/app/_services/open-order-toggle.service';
import { CardPaymentMqttComponent } from '../card-payment-mqtt/card-payment-mqtt.component';
import { FrontFacingMqttService } from 'src/app/_services/front-facing-mqtt.service';
import { ManualPrintControlComponent } from '../order-summary/manual-print-control/manual-print-control.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CallerIdService } from 'src/app/_services/caller-id.service';
import { ActivateGiftCardDialogComponent } from '../activate-gift-card-dialog/activate-gift-card-dialog.component';
import { GiftCardPartialPaymentComponent } from '../gift-card-partial-payment/gift-card-partial-payment.component';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

declare var iTransact;

@Component({
  selector: 'app-checkout-dialog',
  templateUrl: './checkout-dialog.component.html',
  styleUrls: ['./checkout-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutDialogComponent implements OnInit, OnDestroy {
  @Output() closeMultiOverlay = new EventEmitter<any>();
  @Output() closeOpenOrderOverlay = new EventEmitter<any>();
  @Output() closeSplitMultiOverlay = new EventEmitter<any>();
  @Output() closeDeliveryTabOverlay = new EventEmitter<boolean>();
  @Output() closeOverlay = new EventEmitter<boolean>();
  @Output() closeGiftOverlay = new EventEmitter<boolean>();

  input = '';
  result = '0.00';
  dueTotal = this.data.total;
  cashDueTotal = this.data.cashTotal;
  subTotal = this.data.subTotal;
  terminalId = this.data.terminalId;
  val1;
  val2;
  val3;
  goBackSub: Subscription;
  orderHash: string;
  orderWithPayment: OrdersWithPayment;
  store: Store;
  dollarInput1: any;
  dollarInput2: any;
  dollarInput3: any;
  sub: Subscription
  openFromSplit: any;
  firstVal: any;
  seondVal: any;
  splitAmount: any;
  private destroySubject: Subject<void> = new Subject();
  paymentType: string;
  openFromgift: boolean = false;
  giftPartialAmount: any;
  private overlayRef!: OverlayRef;

  constructor(
    public dialog: MatDialog,
    private orderService: OrderService,
    private refreshService: RefreshService,
    private ffmq: FrontFacingMqttService,
    private openOrderSummaryToggleService: OpenOrderToggleService,
    private snackBar: MatSnackBar,
    @Inject('OVERLAY_DATA') public data: any,
    private callerIdService: CallerIdService,
    private chRef: ChangeDetectorRef,
    private overlay: Overlay,
    private injector: Injector,
  ) {
    this.orderWithPayment = data.orderWithPayment;
    this.orderHash = this.orderWithPayment.bot_order.order_hash;
    this.store = this.orderWithPayment.store;
    this.openFromSplit = this.data.openFromSplit;
    this.openFromgift = data?.openFromgift;
    this.splitAmount = data.cashTotal ? data.cashTotal : ''
    this.paymentType = data?.payment_type;
    this.giftPartialAmount = data?.cashTotal;
  }

  ngOnInit() {
    console.log(this.orderWithPayment);

    this.initVals();
    console.log(this.data.from);

    // this.sub = this.ffmq.paymentMode.pipe(takeUntil(this.destroySubject)).subscribe(
    //   data => {
    //     if (data.type === 'paymentTypeCard') {
    //       this.openCardDialog();
    //     } else {
    //       this.ffmq.publishPaymentInProgress;
    //     }
    //   }
    // );
    this.goBackSub = this.ffmq.goBackMqttSub().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (JSON.parse(data.payload.toString()).goBack == true) {
        // this.dialogRef.close();
        if (this.data.eventsource === 'open_orders') {
          this.closeOpenOrderOverlay.emit(false);
        } else if (this.data.eventsource === 'delivery-tab') {
          this.closeDeliveryTabOverlay.emit(false);
        } else if (this.data.eventsource === 'order-smry') {
          this.closeOverlay.emit(false);
        } else if (this.data.eventsource === 'gift-cd') {
          this.closeGiftOverlay.emit(false);
        } else if (this.data.eventsource === 'split-cash') {
          this.closeSplitMultiOverlay.emit(false);
        } else {
          this.closeMultiOverlay.emit(false);
        }

      }
    });
  }

  initVals() {
    // const valOne = Math.ceil(+this.input + this.cashDueTotal);
    this.firstVal = this.cashDueTotal;
    this.val1 = '$' + this.cashDueTotal;

    const valTwo = Math.ceil(+this.input + this.cashDueTotal);
    this.seondVal = '$' + Math.ceil(+this.input + this.cashDueTotal);
    // if (valTwo == valOne) {
    //   this.val2 = '$' + (valTwo + 1);
    // } else {
    //   this.val2 = '$' + valTwo;
    // }
    this.val2 = '$' + Math.ceil(+this.input + this.cashDueTotal);

    const valThree = (Math.ceil(+this.input + this.cashDueTotal / 10) * 10);
    if (valThree == valTwo) {
      this.val3 = '$' + (valThree + 1);
    } else {
      this.val3 = '$' + valThree;
    }
  }

  pressNum(num: string) {
    if (this.input === '') {
      this.input = '0.00';
    }

    this.dollarInput1 = '';
    this.dollarInput2 = '';
    this.dollarInput3 = '';
    const resArr: any = this.input.split('');

    const tiv: any = `${num}`.split('');
    tiv.forEach(e => resArr.push(e));
    for (let i = tiv.length; i >= 0; i--) {
      resArr[resArr.length - (3 + i)] = resArr[resArr.length - (2 + i)];
    }
    resArr[resArr.length - 3] = '.';

    if (resArr.length >= 4 && resArr[0] === '0') {
      resArr.shift();
    }
    this.input = resArr.join('');
    this.calcAnswer();
  }

  pressing(vall) {
    this.input = '0.00';
    this.firstVal = this.cashDueTotal
    this.input = this.cashDueTotal.toString();
    this.dollarInput1 = vall;
    this.dollarInput2 = '';
    this.dollarInput3 = '';
    if (this.input) {
      this.calcAnswer();
      return;
    }
  }

  pressing2(vall) {
    this.input = '0.00';
    this.input = Math.ceil(+this.input + this.cashDueTotal).toString();
    // if (this.firstVal == input) {
    //   this.input = (input + 1).toString();
    // } else {
    //   this.input = input.toString();
    // }
    this.seondVal = Math.ceil(+this.input + this.cashDueTotal);
    this.dollarInput1 = '';
    this.dollarInput2 = vall;
    this.dollarInput3 = '';
    if (this.input) {
      this.calcAnswer();
      return;
    }
  }

  pressing3(vall) {
    this.input = '0.00';
    const input = (Math.ceil(+this.input + this.cashDueTotal / 10) * 10);
    if (this.seondVal == input) {
      this.input = (input + 1).toString();
    } else {
      this.input = input.toString();
    }
    this.dollarInput1 = '';
    this.dollarInput2 = '';
    this.dollarInput3 = vall;
    if (this.input) {
      this.calcAnswer();
      return;
    }
  }

  clear() {
    if (this.input !== '0.00') {
      this.input = this.input.substr(0, this.input.length - 1);
      this.calcAnswer();
    }

    this.dollarInput1 = '';
    this.dollarInput2 = '';
    this.dollarInput3 = '';
  }

  allClear() {
    this.result = '0.00';
    this.input = '0.00';
    this.dollarInput1 = '';
    this.dollarInput2 = '';
    this.dollarInput3 = '';
  }

  calcAnswer() {
    const formula = this.input;
    // tslint:disable-next-line:no-eval
    this.result = eval(String(+formula));
  }

  getAnswer() {
    this.calcAnswer();
    this.input = this.result;
    if (this.input === '0.00') {
      this.input = '';
    }
  }

  onNoClick(): void {
    this.ffmq.goBackMqttPub();
    if (this.data.eventsource === 'open_orders') {
      this.closeOpenOrderOverlay.emit(false);
    } else if (this.data.eventsource === 'delivery-tab') {
      this.closeDeliveryTabOverlay.emit(false);
    } else if (this.data.eventsource === 'order-smry') {
      this.closeOverlay.emit(false);
    }else if (this.data.eventsource === 'gift-cd') {
      this.closeGiftOverlay.emit(false);
    } else if (this.data.eventsource === 'split-cash') {
      this.closeSplitMultiOverlay.emit(false);
    } else {
      this.closeMultiOverlay.emit(false);
    }
    // this.dialogRef.close(false);
  }

  refreshOrder() {
    this.orderService.getOrder(this.orderHash).pipe(takeUntil(this.destroySubject))
      .subscribe((result) => {
        this.orderWithPayment = OrderHelpers.parseOrderwithPayment(result);
        console.log(this.orderWithPayment);

        this.dueTotal = this.orderWithPayment.payment.due_total;
        if (this.dueTotal <= 0) {
          // this.dialogRef.close(true);
          this.allClear();
          this.initVals();
          if (this.data.eventsource === 'open_orders') {
            this.closeOpenOrderOverlay.emit(true);
          } else if (this.data.eventsource === 'delivery-tab') {
            this.closeDeliveryTabOverlay.emit(false);
          } else if (this.data.eventsource === 'order-smry') {
            this.closeOverlay.emit(false);
          } else if (this.data.eventsource === 'gift-cd') {
            this.closeGiftOverlay.emit(false);
          } else if (this.data.eventsource === 'split-cash') {
            this.closeSplitMultiOverlay.emit(false);
          } else {
            this.closeMultiOverlay.emit(true);
          }

        }

      });
  }

  openCashDialog() {
    if (this.result === '0.00') {
      return;
    } else {
      const cashData = {
        cashDueTotal: this.cashDueTotal,
        result: this.result,
        from: this.data.from ? this.data.from : null,
        card_amnt: this.data.card_amount ? this.data.card_amount : null,
        card_id: this.data.card_id ? this.data.card_id : null
      };
      if (this.openFromgift ? Number(this.result) >= Number(this.cashDueTotal) : (Number(this.result) >= Number(this.orderWithPayment.payment.cash_due_total) || Number(this.result) >= Number(this.orderWithPayment.payment.due_total))) {
        this.openOverlay(CashPaymentComponent, { ...this.data, ...cashData }, '')
      } else {
        this.snackBar.open('Please select full amount', 'OK', { duration: 2000, verticalPosition: 'top' });
      }
    }
  }

  submitKeyedOrder() {
    const orderWithPayment = this.data.orderWithPayment;
    const payload = {
      terminal_id: localStorage.getItem('selectedTerminalName'),
      terminal_name: localStorage.getItem('selectedTerminalName'),
      cashier_id: localStorage.getItem('posLoggedUser'),
      amount: this.data.total,
      pin_user: localStorage.getItem('pinUser')
    };

    this.orderService.closeKeyedOrder(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.destroySubject))
      .subscribe((result) => {
        this.result = result;
        localStorage.removeItem('order_hash');
        this.openOrderSummaryToggleService.toggle('CLOSE');
        localStorage.removeItem('setOccasionDetail');
        orderWithPayment.bot_order.is_closed = true;
        this.orderService.orderDoneSubject.next();
        this.refreshService.refreshModule('OPEN_TAB');
        // this.dialogRef.close(true);
        this.ffmq.publishPaymentDone();
        this.callerIdService.setPhoneNumbertoOrderAndDelivery.next(null);
        if (this.store.feature_flag['manual_print_standard_checkout']) {
          this.manualReceiptOptions();
        } else {
          if (this.data.eventsource === 'open_orders') {
            this.closeOpenOrderOverlay.emit(true);
          } else if (this.data.eventsource === 'delivery-tab') {
            this.closeDeliveryTabOverlay.emit(false);
          } else if (this.data.eventsource === 'order-smry') {
            this.closeOverlay.emit(false);
          } else if (this.data.eventsource === 'gift-cd') {
            this.closeGiftOverlay.emit(false);
          } else if (this.data.eventsource === 'split-cash') {
            this.closeSplitMultiOverlay.emit(false);
          } else {
            this.closeMultiOverlay.emit(true);
          }
        }
      });
  }


  openCardDialog() {
    if (+this.result > 0) {
      this.data.toCharge = +this.result;
    } else {
      this.data.toCharge = this.dueTotal;
    }
    if (this.store.enable_consumer_choice_tip) {
      const isMobile = window.innerWidth <= 470;
      this.openOverlay(TipDialogComponent, this.data, 'card_select')
    } else {
      let dialogRef;
      if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
        console.log('BBPOS chipper dialog');
        this.data.action = 'SALE';
        this.data.payment_type = 'CREDIT';
        console.log(this.data);
        this.openOverlay(CardPaymentMqttComponent, this.data, 'card_select')

      } else {
        this.openOverlay(CardPaymentConsumerChoiceComponent, this.data, 'card_select')
      }
    }
  }

  openManualCardDialog() {
    const isMobile = window.innerWidth <= 470;
    this.openOverlay(ManualCardDialogComponent, this.data, 'manual')
  }

  number(str) {
    return +str;
  }

  openSplitPayment() {
    this.data.payment_type = 'SPLIT';
    const isMobile = window.innerWidth <= 470;
    this.openOverlay(SplitPaymentComponent, this.data, '');
  }

  openOtherPaymentDialog(payment_type) {
    if (this.result === '0.00') {
      return;
    } else {
      const cashData = {
        cashDueTotal: this.cashDueTotal,
        result: this.result
      };

      if (Number(this.result) >= Number(this.orderWithPayment.payment.cash_due_total) || Number(this.result) >= Number(this.orderWithPayment.payment.due_total)) {
        this.openOverlay(CashPaymentComponent, { ...this.data, ...cashData, is_other: true, split_type: payment_type }, '')
      } else {
        this.snackBar.open('Please select full amount', 'OK', { duration: 2000, verticalPosition: 'top' });
      }
    }
  }

  splitCashPayment() {
    if (this.result === '0.00') {
      return;
    } else {
      const cashData = {
        cashDueTotal: this.cashDueTotal,
        result: this.result
      }
      // this.dialogRef.close(cashData);
      if (this.data.eventsource === 'open_orders') {
        this.closeOpenOrderOverlay.emit(cashData);
      } else if (this.data.eventsource === 'split-cash') {
        const d = {
          key: cashData,
          value: true
        }
        this.closeSplitMultiOverlay.emit(d);
      } else if (this.data.eventsource === 'delivery-tab') {
        this.closeDeliveryTabOverlay.emit(false);
      } else if (this.data.eventsource === 'order-smry') {
        this.closeOverlay.emit(false);
      } else if (this.data.eventsource === 'gift-cd') {
        this.closeGiftOverlay.emit(false);
      } else if (this.data.eventsource === 'split-cash') {
        this.closeSplitMultiOverlay.emit(false);
      } else {
        this.closeMultiOverlay.emit(cashData);
      }

    }
  }

  manualReceiptOptions() {
    const data = {
      value: 1,
      order_hash: this.orderHash,
      key: 'manual_card'
    }
    this.openOverlay(ManualPrintControlComponent, data, '');
  }

  openGiftCardDialog() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(ActivateGiftCardDialogComponent, {
      disableClose: true,
      width: isMobile ? '100vw' : '600px',
      data: { for: 'gift-card-redeem', amount: this.data.total, store: this.orderWithPayment.store },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data) {
        this.giftPayment(result);
      }
    });
  }

  giftPayment(r) {
    const orderWithPayment = this.data.orderWithPayment;
    if (this.data.total >= r.cardDetails.balance) {
      const dialogData = {
        amnt: this.data.total,
        card_blnce: r.cardDetails.balance,
        card_id: r.cardDetails.id,
        order: orderWithPayment,
        subTotal: this.data.subTotal,
        terminalId: this.data.terminalId,
      }
      const dialogRef = this.dialog.open(GiftCardPartialPaymentComponent, {
        width: '500px',
        data: { "data": dialogData },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result?.data) {
          if (result.type === 'card') {
            this.partialCardPay(Number((this.data.total - r.cardDetails.balance).toFixed(2)), r.cardDetails.balance, r.cardDetails.id);
          }
        
        } else if (result) {
          this.closeLay();
          this.closeMultiOverlay.emit(true);
        }
        
      });
      
    } else {
      const orderWithPayment = this.data.orderWithPayment;
      const payload = {
        terminal_id: localStorage.getItem('selectedTerminalName'),
        terminal_name: localStorage.getItem('selectedTerminalName'),
        cashier_id: localStorage.getItem('posLoggedUser'),
        amount: this.data.total,
        pin_user: localStorage.getItem('pinUser'),
        card_id: r.cardDetails.id
      };
      this.orderService.closeGiftCardOrder(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.destroySubject))
        .subscribe((result) => {
          if (result) {
            // this.dialogRef.close(true);
            if (this.store.feature_flag['manual_print_standard_checkout']) {
              this.manualReceiptOptions();
            } else {
              if (this.data.eventsource === 'open_orders') {
                this.closeOpenOrderOverlay.emit(true);
              } else if (this.data.eventsource === 'delivery-tab') {
                this.closeDeliveryTabOverlay.emit(true);
              } else if (this.data.eventsource === 'order-smry') {
                this.closeOverlay.emit(false);
              } else if (this.data.eventsource === 'gift-cd') {
                this.closeGiftOverlay.emit(true);
              } else if (this.data.eventsource === 'split-cash') {
                this.closeSplitMultiOverlay.emit(true);
              } else {
                this.closeMultiOverlay.emit(true);
              }
            }
          }
        });
    }
  }

  partialCardPay(r, balance, id) {
    const dialogData = {
      from: 'gift-partial-payment',
      card_amnt: balance,
      card_id: id
    }
    if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
      this.data.amount = r;
      this.data.action = 'TIP';
      this.openOverlay(CardPaymentMqttComponent, { ...this.data, ...dialogData }, 'card_select')

    } else {
      this.data.toCharge = r;
      this.openOverlay(CardPaymentConsumerChoiceComponent, { ...this.data, ...dialogData }, 'card_select')
    }
  }

  openOverlay(component, data, e) {
    if (this.overlayRef) {
      this.closeLay();
    }

    if (e === 'card_select') {
      if (this.data.eventsource === 'open_orders') {
        this.closeOpenOrderOverlay.emit(false);
      } else if (this.data.eventsource === 'delivery-tab') {
        this.closeDeliveryTabOverlay.emit(false);
      } else if (this.data.eventsource === 'order-smry') {
        // this.closeOverlay.emit(false);
      } else if (this.data.eventsource === 'gift-cd') {
        this.closeGiftOverlay.emit(false);
      } else if (this.data.eventsource === 'split-cash') {
        this.closeSplitMultiOverlay.emit(false);
      } else {
        // this.closeMultiOverlay.emit(false);
      }
      // this.orderService.closePreviousOverlays.next(true);
    }

    if (this.data.eventsource === 'split-cash') {
      data.eventsource = 'split-cash';
    } else {
      data.eventsource = 'checkout';
    }

    let overlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
    };
    if (component === CheckoutDialogComponent) {
      overlayConfig = { ...overlayConfig, positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(), };
    }

    this.overlayRef = this.overlay.create(overlayConfig);


    const injector = this.createInjector(component === CheckoutDialogComponent ? {
      orderWithPayment: this.orderWithPayment,
      terminalId: this.terminalId,
      total: this.orderWithPayment.payment.total,
      cashTotal: this.orderWithPayment.payment.cash_due_total,
      subTotal: this.orderWithPayment.payment.sub_total,
    } : data);

    const portal = new ComponentPortal(component, null, injector);
    const componentRef: ComponentRef<any> = this.overlayRef.attach(portal);

    componentRef.instance.closeCheckoutOverlay?.pipe(takeUntil(this.destroySubject)).subscribe((isComplete: any) => {
      console.log('CheckOUT DIALOG closeCheckoutOverlay', isComplete);
      if (isComplete?.key) {
        this.closeLay();
        this.closeOverlay.emit(true);
        this.closeMultiOverlay.emit(true);
        this.closeGiftOverlay.emit(true);

        // this.closeAllLay();
      } else {
        if (isComplete) {
          if (e === 'card_select') {
            this.ffmq.resetTip();
            this.refreshOrder();
            this.chRef.detectChanges();
            this.closeMultiOverlay.emit(true);
          } else if (component === ManualPrintControlComponent) {
            if (this.data.eventsource === 'open_orders') {
              this.closeOpenOrderOverlay.emit(true);
            } else if (this.data.eventsource === 'delivery-tab') {
              this.closeDeliveryTabOverlay.emit(true);
            } else if (this.data.eventsource === 'order-smry') {
              this.closeOverlay.emit(true);
            } else if (this.data.eventsource === 'gift-cd') {
              this.closeGiftOverlay.emit(true);
            } else if (this.data.eventsource === 'split-cash') {
              this.closeSplitMultiOverlay.emit(true);
            } else {
              this.closeMultiOverlay.emit(true);
            }
          } else if (e === 'manual') {
            this.closeMultiOverlay.emit(true);
          }
        }
        this.closeLay();
      }
    });
  }

  closeLay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  closeAllLay(): void {
    console.log('Closing overlay:', this.overlayRef);

    if (this.overlayRef?.hasAttached()) {
      console.log('Detaching overlay...');
      this.overlayRef.detach(); // ✅ Ensure component is detached
    }

    if (this.overlayRef) {
      console.log('Disposing overlay...');
      this.overlayRef.dispose();
      this.overlayRef = null; // ✅ Remove reference to prevent re-use issues
      this.chRef.detectChanges(); // ✅ Ensure UI updates
      console.log('Overlay closed.');
    }

    // ✅ Manually clear overlay container if necessary
    const overlayContainers = document.querySelectorAll('.cdk-overlay-container');
    overlayContainers.forEach(container => {
      container.innerHTML = ''; // ✅ Clear any leftover overlay elements
    });
  }

  private createInjector(data: any): Injector {
    return Injector.create({
      providers: [
        { provide: 'OVERLAY_DATA', useValue: data },
        { provide: OverlayRef, useValue: this.overlayRef },
      ],
      parent: this.injector,
    });
  }


  ngOnDestroy() {
    this.destroySubject.next();
    if (this.goBackSub) {
      this.goBackSub.unsubscribe()
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.ffmq.paymentModeSubject.next(0);
    console.log('NG ON DESTROY OF CHECOUT DIALOG COMPONENT');
  }

}
