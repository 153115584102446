import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Permission } from '../_models/permission';
import { Store } from '../_models/store';
import { PermissionService } from '../_services/permission.service';
import { StoreService } from '../_services/store.service';
import { UnreadService } from '../_services/unread-message.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  store: Store;
  unreadCounter;
  permission: Permission;
  @Input() opened: boolean;
  private destroySubject: Subject<void> = new Subject();
  currentVersion: string = '';
  latestVersion: string = '';
  isLatestVersion: boolean = false;

  constructor(private storeService: StoreService,
    private unreadService: UnreadService,
    private permissionService: PermissionService,
    private http: HttpClient, private updates: SwUpdate,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      if (this.store) {
        this.unreadService.current.pipe(takeUntil(this.destroySubject)).subscribe(counter => this.unreadCounter = counter);
      }
    });

    this.permissionService.current.pipe(takeUntil(this.destroySubject)).subscribe(permission => this.permission = permission);
    this.loadCurrentVersionAndLatest();
  }

  ngAfterViewInit() {
    console.log('After View In it');

    // this.loadCurrentVersion();
    // this.loadLatestAppVersion();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  loadLatestAppVersion(): void {
    const envID = environment.apiUrl == 'https://backend.text-order.com' ? 1 :
      environment.apiUrl == 'https://beta.labrador.ai' ? 2 : 3;

    this.storeService.getLatestAppVersion(envID, 'business').pipe(takeUntil(this.destroySubject)).subscribe(
      (data: any) => {
        this.latestVersion = data.version;
        this.isLatestVersion = this.currentVersion === this.latestVersion;

        if (!this.isLatestVersion) {
          this.snackBar.open('A new version is available. Please refresh.', 'Update', {
            duration: 4000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          }).onAction().subscribe(() => {
            this.updateAppVersion(); // Reload app when "Update" is clicked
          });
        } else {
          this.snackBar.open('Your software is up to date.', 'OK', {
            duration: 2000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          });
        }
      },
      (error) => {
        console.error('Error fetching latest version:', error);
      }
    );
  }

  updateAppVersion() {
    console.log('Butotn CLICK');
    if (this.updates.isEnabled) {
      this.updates.activateUpdate().then(() => {
        console.log('Service worker activated, performing hard reload');
        window.location.reload();
      }).catch(error => {
        console.error('Error activating update:', error);
        this.snackBar.open('Update failed. Please try again.', 'OK', {
          duration: 4000
        });
      });
    } else {
      this.snackBar.open('Service worker not enabled. Reloading...', 'OK', {
        duration: 4000
      });
      window.location.reload();
    }
    window.location.reload();
  }

  loadCurrentVersionAndLatest() {
    this.http.get<{ version: string }>(`assets/version.json?v=${Date.now()}`).subscribe(
      (data) => {
        this.currentVersion = data.version;
        console.log(`Version check - Current: ${this.currentVersion}`);
        this.loadLatestAppVersion();
      },
      (error) => {
        console.error('Error loading local version:', error);
      }
    );
  }


  onOrdersClick(url: string): void {
    // Get the div inside mat-sidenav-content
    const sidenavContent = document.querySelector('mat-sidenav-content .basic-container.smooth-scroll');

    // Check if the div exists
    if (sidenavContent) {
      // If the URL is /home/orders, add the 'color-scheme-dark' class
      if (url === '/home/orders' && this.store.feature_flag['dark_mode']) {
        sidenavContent.classList.add('color-scheme-dark');
        console.log('color-scheme-dark applied to the div.');
      }
      // Else, remove the 'color-scheme-dark' class if it exists
      else {
        if (sidenavContent.classList.contains('color-scheme-dark')) {
          sidenavContent.classList.remove('color-scheme-dark');
          console.log('color-scheme-dark removed from the div.');
        }
      }
    } else {
      console.log('mat-sidenav-content div not found.');
    }
  }

}
