import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DigitalDisplayService} from 'src/app/_services/digital-display.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss']
})
export class CategoryDialogComponent implements OnInit {
  editCategoryForm: UntypedFormGroup;
  getId: any;
  submitted: boolean;
  subcategory_list_data: any;
  storeId: any;
  showSelete: any = [];
  newScreen: boolean;
  hideCategory: boolean;
  hideProductWeight: boolean;
  emojiLists: any;
  searchControl = new FormControl('');
  allSelected = false;
  subcategorys = new UntypedFormControl('');
  originalSubcategoryList: any[] = [];
  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<CategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("dsatdata", data);
  }
  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.subcategoryList();
    this.cat();
    this.newScreen = this.data.new_screen;
    this.hideCategory = this.data.hide_category;
    this.hideProductWeight = this.data.hide_product_weight;
    this.getId = this.data.id,
      this.editCategoryForm = this.fb.group({
        store_id: [this.storeId],
        name: [this.data.name],
        priority: [this.data.priority],
        hide_category: [this.data.hide_category],
        new_screen: [this.data.new_screen],
        subcategorys: [this.subcategorys],
        font_color: [this.data.font_color],
        hide_product_weight: [this.data.hide_product_weight],
        emoji: [this.data.emoji]
      })
    this.emojiList();
    
    // Store original list after loading data
    this.subcategoryList();
    
    // Setup search functionality
    this.searchControl.valueChanges.subscribe(value => {
      this.filterSubcategories(value);
    });
  }
  onNoClick() {
    this.dialogRef.close();
  }
  cat() {
    var cat_data = []
    for (let i = 0; i < this.data.subcategorys.length; i++) {
      const element = this.data.subcategorys[i];
      for (let j = 0; j < element.length; j++) {
        const element1 = element[j].id;
        this.showSelete.push(element1)
        cat_data.push(element1)
      }
      this.subcategorys.setValue(cat_data);
    }

  }

  toggleAllSelection1() {
    if (this.allSelected) {
      this.searchControl.setValue('');
      this.subcategorys.setValue([]);
    } else {
      const filteredIds = this.subcategory_list_data
        .map(topping => topping.id);
      this.subcategorys.setValue(filteredIds);
    }
    this.allSelected = !this.allSelected;
  }

  toggleSelection1(itemId: any) {
    const selectedIds = this.subcategorys.value || [];

    if (selectedIds.includes(itemId)) {
      this.subcategorys.setValue(selectedIds.filter(id => id !== itemId));
    } else {
      this.subcategorys.setValue([...selectedIds, itemId]);
    }
  }

  editCategoryFormSubmit() {
    this.submitted = true;
    if (this.editCategoryForm.invalid) {
      return;
    }
    this.editCategoryForm.value.subcategorys = this.subcategorys.value
    this.digitalDisplayService.editCategory(this.getId, this.editCategoryForm.value).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
      this.dialogRef.close(resData);
    })
  }
  subcategoryList() {
    this.digitalDisplayService
      .SubCategoriesOtionList(this.storeId)
      .subscribe((data) => {
        this.subcategory_list_data = data.data;
        this.originalSubcategoryList = [...data.data]; // Store original list
      });
  }
  newScreenCheck(a) {
    this.editCategoryForm.value.new_screen = a.checked;
  }
  hideCategoryCheck(a) {
    this.editCategoryForm.value.hide_category = a.checked;
  }
  hideProductWeighttCheck(a) {
    this.editCategoryForm.value.hide_product_weight = a.checked;
  }
  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emojiLists = data.data;

    });
  }
  filterSubcategories(searchValue: string) {
    if (!searchValue) {
      this.subcategory_list_data = [...this.originalSubcategoryList];
      return;
    }
    
    const searchTerm = searchValue.toString().trim().toLowerCase();
    
    this.subcategory_list_data = this.originalSubcategoryList.filter(category => {
      const displayName = (category.display_name || '').toString().toLowerCase();
      // Split search term by spaces to search for multiple words
      const searchTerms = searchTerm.split(' ').filter(term => term.length > 0);
      
      // Check if all search terms are included in the category name
      return searchTerms.every(term => displayName.includes(term));
    });
  }
}
