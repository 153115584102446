import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TipDialogComponent } from '../../tip-dialog/tip-dialog.component';
import { OrdersWithPayment } from 'src/app/_models/order';
import { OrderService } from 'src/app/_services/order.service';
import { FrontFacingMqttService } from 'src/app/_services/front-facing-mqtt.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-tip',
  templateUrl: './custom-tip.component.html',
  styleUrls: ['./custom-tip.component.scss']
})
export class CustomTipComponent implements OnInit, OnDestroy {
  @Output() closeMultiComponentOverlay = new EventEmitter<any>();
  orderHash: string;
  orderWithPayment: OrdersWithPayment;
  @Input() refresh = new EventEmitter<any>();
  private destroySubject: Subject<void> = new Subject();

  constructor(
    public dialog: MatDialog,
    private orderService: OrderService,
    @Inject('OVERLAY_DATA') public data: any,
    private ffmq: FrontFacingMqttService) {
    this.orderWithPayment = data.orderWithPayment;
    this.orderHash = this.orderWithPayment.bot_order.order_hash;
    // dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  onSubmit(customTip: number) {
    // this.dialogRef.close(customTip);
    this.closeMultiComponentOverlay.emit(customTip);

    // this.openTipDialog(customTip); //Comment out line because the custom tip is not added to the total order value.
  }

  openTipDialog(customTip: number) {
    console.log(customTip);
    this.data.tipAmount = customTip;
    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(TipDialogComponent, {
      width: isMobile ? '100vw' : '100%',
      height: isMobile ? 'auto' : '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: this.data,
      panelClass: 'trend-dialog',
      disableClose: true, autoFocus: false
    });
  }

  goBack() {
    this.closeMultiComponentOverlay.emit(false);
    // this.orderWithPayment.payment.due_total = this.orderWithPayment.payment.due_total - this.orderWithPayment.payment.tip
    // this.orderWithPayment.updateTipCustom(0)
    // this.openTipDialog(0);
  }
}
