<div class="flex flex-col w-screen h-screen bg-white p-5">
  <div class="nav">
    <button (click)="back()"
    class="border border-blue-500 text-blue-500 font-medium text-lg rounded-lg px-4 py-2 hover:bg-blue-500 hover:text-white transition duration-300">
    Go Back
  </button>
  </div>
  <mat-horizontal-stepper #stepper>
    <mat-step>
      <p class="text-center w-full lab-text-blue text-4xl xs:!text-xl">Select Payment Type</p>

      <div class="grid grid-cols-7 xs:!block xs:my-2.5 gap-3 items-stretch mt-10 h-full">
        <div class="cash col-span-3 border border-gray-300 rounded-md shadow-sm flex flex-col justify-between">
          <app-payment-options-breakdown [orderWithPayment]="orderWithPayment"
            [optBox]="cash"></app-payment-options-breakdown>
          <div class="cash-card-btn">
            <button class="optionButton" tabindex="-1" (click)="openCheckoutDialog(1)">
              <span class="btnTxt">Cash</span><br><br>
              <span class="btnTxt">{{orderWithPayment?.payment.cash_due_total | currency}}</span>
            </button>
          </div>

        </div>

        <div class="or w-full flex flex-col items-center justify-center">
          <p class="text-xl xs:p-2.5 text-center">OR</p>
        </div>

        <div class="credit col-span-3 border border-gray-300 rounded-md shadow-sm flex flex-col justify-between">
          <app-payment-options-breakdown [orderWithPayment]="orderWithPayment"
            [optBox]="card"></app-payment-options-breakdown>
          <div class="cash-card-btn">
            <button class="optionButton" tabindex="-1" (click)="selectPayment('card')"
              [ngClass]="{'optionButtonSelected': selectedPayment === 'card'}" [disabled]="isProcessingPayment">
              <span class="btnTxt">Card</span><br><br>
              <span class="btnTxt">{{orderWithPayment?.payment.due_total | currency}}</span>
            </button>
          </div>
        </div>
      </div>

    </mat-step>
    <mat-step *ngIf="store.enable_consumer_choice_tip">
      <div class="tip-box">
        <p class="paymentType">
          Would You Like To Leave A Tip?
        </p>

        <p class="amount">
          {{orderWithPayment?.getCardChargeWithTip2(selectedTip?.amount, tipcondition || 0) | currency}}
        </p>

        <p class="tip-plus-amount">
          {{orderWithPayment?.getCardChargeWithTip2(amountWithoutTip, 0) | currency}} <span
            *ngIf="selectedTip?.amount > 0">+ {{selectedTip?.amount | currency}} Tip</span>
        </p>

        <div class="tipBox">
          <mat-chip-listbox selectable="true">
            <mat-chip-option class='calculatetipchange' *ngFor="let tip of tips; let index = index"
              (click)="selectTip(tip)" color="primary" [selected]="tipPercent === tip.value">
              <p *ngIf="tip.name == 'No Tip' || tip.name == 'Custom'"><span class="notip-custom">{{tip.name}}</span></p>
              <p *ngIf="tip.name != 'No Tip' && tip.name != 'Custom'"><span>{{tip.name}}</span></p>
              <p *ngIf="tip.amount > 0" class="tip-amount">{{tip.amount | currency}}</p>
            </mat-chip-option>
            <input type="text" class='tip-text' placeholder="Custom" type="number"
              (keyup)="selectTip({amount: custom.value, value: null },0)" #custom />
          </mat-chip-listbox>

        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>


<!--<mat-dialog-actions align="end" *ngIf="store.enable_consumer_choice_tip">-->
<!--  <button mat-flat-button type="button" class="pay" (click)="onPay()" color="primary" [disabled]="!tipSelected">-->
<!--    Pay-->
<!--  </button>-->
<!--</mat-dialog-actions>-->