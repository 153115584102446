import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Injector, ComponentRef } from '@angular/core';
import { OrderService } from 'src/app/_services/order.service';
import { OrdersWithPayment } from 'src/app/_models/order';
import { CustomTipComponent } from './custom-tip/custom-tip.component';
import { OrderHelpers } from 'src/app/_helpers/helpers';
import { CardPaymentConsumerChoiceComponent } from '../card-payment-consumer-choice/card-payment-consumer-choice.component';
import { Store } from '../../_models/store';
import { CardPaymentMqttComponent } from '../card-payment-mqtt/card-payment-mqtt.component';
import { FrontFacingMqttService } from 'src/app/_services/front-facing-mqtt.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-tip-dialog',
  templateUrl: './tip-dialog.component.html',
  styleUrls: ['./tip-dialog.component.scss']
})
export class TipDialogComponent implements OnInit, OnDestroy {
  @Output() closeMultiOverlay = new EventEmitter<boolean>();
  @Output() closeCheckoutOverlay = new EventEmitter<boolean>();
  orderHash: string;
  order: OrdersWithPayment;

  @Input() refresh = new EventEmitter<any>();
  @Input() store: Store;

  tipPercent = 0;
  tipAmount = 0;
  terminalId = this.data.terminalId;
  goBackSub: Subscription;
  tipSub: Subscription;
  private destroySubject: Subject<void> = new Subject();
  taxDueTotal: number;
  tipTenPercent: number;
  tipFifteenPercent: number;
  tipTwentyPercent: number;
  tips: any[];
  paymentStartSub: Subscription;
  isMobile: boolean;
  private overlayRef!: OverlayRef;

  constructor(
    public dialog: MatDialog,
    private orderService: OrderService,
    private ffmq: FrontFacingMqttService,
    @Inject('OVERLAY_DATA') public data: any,
    private chRef: ChangeDetectorRef,
    private overlay: Overlay,
    private injector: Injector
  ) {
    this.order = data.orderWithPayment;
    this.taxDueTotal = this.data.orderWithPayment.getDueTotal();
    this.store = this.order.store;
    this.orderHash = this.order.bot_order.order_hash;

    this.tipTenPercent = this.round((this.taxDueTotal * 18) / 100);
    this.tipFifteenPercent = this.round((this.taxDueTotal * 20) / 100);
    this.tipTwentyPercent = this.round((this.taxDueTotal * 25) / 100);

    if (this.store.getFeatureFlag('dynamic_tip_enabled') && (this.taxDueTotal <= this.store.store_data.tip_value_min_order)) {
      this.tips = [
        { name: 'Custom', value: this.store.store_data.tip_values[0], amount: this.store.store_data.tip_values[0] },
        { name: 'Custom', value: this.store.store_data.tip_values[1], amount: this.store.store_data.tip_values[1] },
        { name: 'Custom', value: this.store.store_data.tip_values[2], amount: this.store.store_data.tip_values[2] },
        { name: 'Custom', value: 'custom' },
        { name: 'No Tip', value: 0, amount: 0 }
      ];
    } else {
      const storeTipOptions = this.order.getTipPercentOptions();
      if (storeTipOptions.length > 0) {
        this.tips = storeTipOptions.map(t => {
          return { name: `${t.percent}%`, value: +t.percent, amount: this.round((this.taxDueTotal * t.percent) / 100) };
        });
        this.tips.push({ name: 'Custom', value: 'custom' }, { name: 'No Tip', value: 0, amount: 0 });
      } else {
        this.tips = [
          { name: '18%', value: 18, amount: this.tipTenPercent },
          { name: '20%', value: 20, amount: this.tipFifteenPercent },
          { name: '25%', value: 25, amount: this.tipTwentyPercent },
          { name: 'Custom', value: 'custom' },
          { name: 'No Tip', value: 0, amount: 0 }
        ];
      }
    }
  }

  round(num) {
    const p = Math.pow(10, 2);
    return Math.round(num * p) / p;
  }


  listenMQTT() {
    this.tipSub = this.ffmq.listenFromFrontFacing().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      console.log(data);
      if (data.select === 'TIP') {
        console.log(data);
        if (data.tip.value === 'custom') {
          const y: number = +data.tip.amount;
          this.tipPercent = data.tip.value;
          this.tipAmount = y;
        } else {
          this.tipPercent = data.tip.value;
          this.tipAmount = data.tip.amount;
        }
        this.chRef.detectChanges();
        this.refreshOrder();
      }
    });

    this.goBackSub = this.ffmq.goBackMqttSub().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (JSON.parse(data.payload.toString()).goBack === true) {
        this.goBack(0);
      }
    });

    this.paymentStartSub = this.ffmq.getPaymentStart().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      const parsedData = this.ffmq.decodeAndParse(data.payload);
      console.log('DDD********************************')
      if (parsedData.onpay == true) {
        this.onPay(1);
      }
    });
  }

  ngOnInit() {
    // this.tipAmount = (this.data.tipAmount ? this.data.tipAmount : 0);
    this.isMobile = window.innerWidth <= 767;
    this.tipAmount = this.data.tipAmount;
    this.tipPercent = this.data.tipPercent;
    this.taxDueTotal = this.data.orderWithPayment.payment.due_total;
    this.store = this.data.orderWithPayment.store;
    this.listenMQTT();
  }

  calculateTip(change: any) {
    console.log(change);
    if (change.name === 'Custom') {
      this.calculateCustomTip(change);
    } else {
      this.calculatePercentTip(change);
    }
    this.chRef.detectChanges();
  }

  calculatePercentTip(change) {
    console.log(change);
    const keyData = { select: 'TIP', tip: change, };
    this.ffmq.publishToFrontFacing(keyData);
    if (change.name === 'No Tip') {
      this.tipAmount = 0
    }
    this.tipPercent = change.value;
    this.tipAmount = change.amount;
    this.updateTipPercentInBackend(change.value);
  }

  calculateCustomTip(selectedTip) {
    if (selectedTip.value == 'custom') {
      this.tipPercent = selectedTip.value;
      const isMobile = window.innerWidth <= 470;
      this.openOverlay(CustomTipComponent, this.data);
    } else {
      this.tipAmount = selectedTip.amount;
      this.tipPercent = selectedTip.value;
      const keyData = { select: 'TIP', tip: selectedTip, };
      this.ffmq.publishToFrontFacing(keyData);
      this.updateCustomTip(this.tipAmount);
      // this.refreshOrder();
    }
  }

  updateTipPercentInBackend(tipPercent: number) {
    this.orderService.updateTip(this.orderHash, tipPercent, 'tip-dialog-updatetippercent').pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.refresh.emit(true);
      this.tipPercent = data.tip_percent;
      this.refreshOrder();
      this.chRef.detectChanges();
    });
  }

  updateCustomTip(tipAmount: number) {
    console.log(tipAmount);
    this.orderService.updateCustomTip(this.orderHash, tipAmount, 'tip-dialog-updatetip-custom').pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.refresh.emit(true);
      this.tipAmount = tipAmount;
      this.refreshOrder();
      this.chRef.detectChanges();
    });
  }


  getTipAmount() {
    return (+this.tipAmount || 0) + (+this.order.getTotal() || 0);
  }

  goBack(e) {
    this.order.payment.due_total = this.order.payment.due_total - this.order.payment.tip;
    this.order.updateTipCustom(0);

    this.updateTip();
    if (e == 1) {
      this.ffmq.goBackMqttPub();
    }
    this.ffmq.resetTip();
    console.log('DDDDDDDDD', this.data);
    
    // if (this.data.eventsource === 'checkout') {
    this.closeCheckoutOverlay.emit(false);
    // } else {
    this.closeMultiOverlay.emit(false);
    // }
  }

  onPay(e) {
    // this.closeAllLay();
    // this.closeLay();
    // this.closeMultiOverlay.emit(true);
    const isMobile = window.innerWidth <= 470;
    setTimeout(() => {
      if (e == 0) {
        this.ffmq.publishStartPayment();
      }
    }, 700);
    this.tipAmount = null;
    this.tipPercent = null;
    if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
      this.data.amount = this.order.payment.due_total;
      this.data.action = 'TIP';
      this.openOverlay(CardPaymentMqttComponent, this.data);
    } else {
      this.orderService.getOrder(this.orderHash).pipe(takeUntil(this.destroySubject))
        .subscribe((order) => {
          this.data.toCharge = order.payment.due_total;
          this.openOverlay(CardPaymentConsumerChoiceComponent, this.data);

        });
    }
  }

  refreshOrder() {
    this.orderService.getOrder(this.orderHash).pipe(takeUntil(this.destroySubject))
      .subscribe((result) => {
        this.order = OrderHelpers.parseOrderwithPayment(result);
        this.store = this.order.store;
      });
  }

  updateTip() {
    this.orderService.updateTip(this.data.orderWithPayment.bot_order.order_hash, 0, 'tip-dialog-go-back-updatetip').pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.refreshOrder();

    });
  }

  /* Overlay Open */
  openOverlay(component, data) {
    if (this.overlayRef) {
      this.closeLay();
    }
    // if (this.data.eventsource === 'checkout') {
    //   this.closeCheckoutOverlay.emit(false);
    // } else {
      // if (component !== CustomTipComponent) {
      //   this.closeMultiOverlay.emit(false);
      // }
    // }

    /* FIX ISSUE TO CLOSE PAYMENT OPTIONS AND TIP DIALOG WHEN USER CLICK ONPAY BUTTON */
    // if (component !== CustomTipComponent) {
    //   this.orderService.closePreviousOverlays.next(true);
    // }

    let overlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),

    };

    if (component === CustomTipComponent) {
      overlayConfig = { ...overlayConfig, positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(), };
    }

    // data.eventsource = 'tip-component'
    this.overlayRef = this.overlay.create(overlayConfig);
    const injector = this.createInjector(data);

    const portal = new ComponentPortal(component, null, injector);
    const componentRef: ComponentRef<any> = this.overlayRef.attach(portal);

    componentRef.instance.closeMultiComponentOverlay?.pipe(takeUntil(this.destroySubject)).subscribe((isComplete: any) => {
      console.log('Tip Dialog closeMultiComponentOverlay',isComplete);
      if (isComplete) {
        if (component === CustomTipComponent) {
          this.tipAmount = isComplete
          const a = { name: 'custom', value: 'custom', amount: isComplete }
          const keyData = { select: 'TIP', tip: a };
          this.ffmq.publishToFrontFacing(keyData);
          this.updateCustomTip(this.tipAmount);
        } else if (component === CardPaymentMqttComponent || component === CardPaymentConsumerChoiceComponent) {
          this.refreshOrder();
          this.chRef.detectChanges();
          this.closeLay();
          this.closeMultiOverlay.emit(true);
          this.closeCheckoutOverlay.emit(true);
        }
      } else {
        if (component === CustomTipComponent) {
          this.tipPercent = null;
          if (this.tipAmount) {
            this.updateTipPercentInBackend(0);
            this.tipAmount = 0;
          }
        }
        this.closeMultiOverlay.emit(false);
      }
      this.closeLay();
    });
  }

  closeLay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  closeAllLay(): void {
    console.log('Closing overlay:', this.overlayRef);

    if (this.overlayRef?.hasAttached()) {
      console.log('Detaching overlay...');
      this.overlayRef.detach(); // ✅ Ensure component is detached
    }

    if (this.overlayRef) {
      console.log('Disposing overlay...');
      this.overlayRef.dispose();
      this.overlayRef = null; // ✅ Remove reference to prevent re-use issues
      this.chRef.detectChanges(); // ✅ Ensure UI updates
      console.log('Overlay closed.');
    }

    // ✅ Manually clear overlay container if necessary
    const overlayContainers = document.querySelectorAll('.cdk-overlay-container');
    overlayContainers.forEach(container => {
      container.innerHTML = ''; // ✅ Clear any leftover overlay elements
    });
  }

  private createInjector(data: any): Injector {
    return Injector.create({
      providers: [{ provide: 'OVERLAY_DATA', useValue: data }, { provide: OverlayRef, useValue: this.overlayRef },],
      parent: this.injector,
    });
  }

  ngOnDestroy() {
    console.log('TIP DIALOG NG ON DESTROY');
    this.destroySubject.unsubscribe();
    if (this.goBackSub) {
      this.goBackSub.unsubscribe();
    }
    if (this.paymentStartSub) {
      this.paymentStartSub.unsubscribe();
    }
    if (this.tipSub) {
      this.tipSub.unsubscribe();
    }
    this.tipPercent = null;
    this.tipAmount = null;
  }
}

