<mat-progress-bar *ngIf="isGroupLoading" mode="indeterminate"></mat-progress-bar>
<table mat-table multiTemplateDataRows [id]="modifierGroup.id" [dataSource]="tempDs" class="border-1p-grey no-select"
  cdkDropList [cdkDropListData]="tempDs.data" (cdkDropListDropped)="drop($event)">
  <ng-container matColumnDef="name">
    <td mat-cell *matCellDef="let element">
      <div [ngSwitch]="element.type">
        <div *ngSwitchCase="'mod_group'" class="heading">
          {{element.data.name}}
        </div>
        <div *ngSwitchCase="'modifier'" class="sub-heading">
          <span class="cursor-move"><mat-icon (mouseenter)="onMouseEnter()"
              (mouseleave)="onMouseLeave()">drag_indicator</mat-icon></span>
          <span class="relative bottom-[6px]">{{element.data.id}} - {{element.data.name}} ( Assigned ID :
            {{element.data.joined_with}} )</span><span class="relative bottom-[6px] text-xs"
            *ngIf="!element.data.is_available">&nbsp;(Currently
            unavailable)</span>
          <span class="relative top-[5px] float-right">{{element.data.upcharge | currency:'USD'}}</span>
        </div>
        <div *ngSwitchCase="'add'" class="add-heading">
          {{element.data.name}}
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="star">
    <td mat-cell *matCellDef="let element" class="clean-icon">
      <mat-menu #operations="matMenu">
        <button mat-menu-item type="button" *ngIf="element.type ==='modifier'"
          (click)="moveModifierOptions('top', element.data); false">Move to top</button>
        <button mat-menu-item type="button" *ngIf="element.type ==='modifier'"
          (click)="moveModifierOptions('up', element.data); false">Move up</button>
        <button mat-menu-item type="button" *ngIf="element.type ==='modifier'"
          (click)="moveModifierOptions('down', element.data); false">Move down</button>
        <button mat-menu-item type="button" *ngIf="element.type ==='modifier'"
          (click)="moveModifierOptions('bottom', element.data); false">Move to bottom</button>

        <button mat-menu-item type="button" *ngIf="element.type ==='mod_group'"
          (click)="createModifierCopy(element.data.id); false">Create copy</button>
        <button mat-menu-item type="button" *ngIf="element.type ==='modifier'"
          (click)="createModCopy(element.data.id); false">Create copy</button>

        <button mat-menu-item type="button" *ngIf="element.type ==='mod_group'" (click)="modSorting()">Sort (A-Z /
          Z-A)</button>
        <mat-divider></mat-divider>
        <button *ngIf="element.type ==='mod_group'" mat-menu-item (click)="deleteModGroup(element.data)">Delete</button>
        <button *ngIf="element.type ==='modifier'" mat-menu-item (click)="deleteModifier(element.data)">Delete</button>
      </mat-menu>

      <button *ngIf="element.type != 'add'" mat-icon-button [matMenuTriggerFor]="operations">
        <mat-icon>more_vert</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="example-element-detail" *ngIf="element" [@detailExpand]="isExpanded(modifierGroup.id, element)">
        <div [ngSwitch]="element.type" class="full-width">
          <div *ngSwitchCase="'mod_group'">
            <form class="form" [formGroup]="element.form" (ngSubmit)="updateModGroup(element.form.value)">
              <mat-form-field class="width-40 xs:!w-full">
                <input matInput placeholder="Modifier Group Name" formControlName="name">
                <!-- <mat-hint>Examples: Lunch, Dinner, Appetizers, Beverages </mat-hint> -->
              </mat-form-field>

              <mat-form-field class="width-25 xs:!w-5/12">
                <mat-select (selectionChange)="changeMaxMods($event)" formControlName="max_mods"
                  placeholder="Maximum # of Mods">
                  <mat-option *ngFor="let mod of mods" [value]="mod.value">
                    {{mod.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <mat-form-field class="width-25 xs:!w-5/12">
                            <input matInput placeholder="Food Cost" formControlName="food_cost" type="number">
                          </mat-form-field> -->
              <mat-form-field class="width-25 xs:!w-5/12">
                <mat-select formControlName="is_required" placeholder="Required?">
                  <mat-option *ngFor="let presenseOption of presenseOptions" [value]="presenseOption.value">
                    {{presenseOption.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div formGroupName="availability" class="full-width">
                <table class="table">
                  <tr>
                    <th class="xs:!w-1/5">Modifier</th>
                    <th class="xs:!w-1/4">Availability</th>
                    <th class="xs:!w-1/4">Reset</th>
                    <th class="xs:!w-1/4">Half Mod</th>
                    <th class="xs:!w-1/5">Quantity</th>
                    <th class="xs:!w-1/5">POS Only</th>
                    <th class="xs:!w-1/5">Max Mod Units</th>
                    <th class="xs:!w-1/5">Assign ID</th>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <mat-checkbox class=" mod-froup-checkbox full-width" #selectAllAvl
                        [checked]="isAllSelected(element.form.controls.availability.controls, 'is_available')"
                        (change)="toggleAll(element.form.controls.availability.controls,'is_available', $event)">{{selectAllAvl.checked
                        ? 'Deselect' : 'Select' }} all</mat-checkbox>
                    </td>
                    <td></td>
                    <td>
                      <mat-checkbox class=" mod-froup-checkbox full-width" #selectAllHalfMod
                        [checked]="isAllSelected(element.form.controls.availability.controls, 'has_half_mod')"
                        (change)="toggleAll(element.form.controls.availability.controls,'has_half_mod', $event)">{{selectAllHalfMod.checked
                        ? 'Deselect' : 'Select' }} all</mat-checkbox>
                    </td>
                    <td>
                      <mat-checkbox class=" mod-froup-checkbox full-width" #selectAllQty
                        [checked]="isAllSelected(element.form.controls.availability.controls, 'has_quantity')"
                        (change)="toggleAll(element.form.controls.availability.controls,'has_quantity', $event)">{{selectAllQty.checked
                        ? 'Deselect' : 'Select' }} all</mat-checkbox>
                    </td>
                    <td>
                      <mat-checkbox class=" mod-froup-checkbox full-width" #selectAllQty
                        [checked]="isAllSelected(element.form.controls.availability.controls, 'is_pos_only')"
                        (change)="toggleAll(element.form.controls.availability.controls,'is_pos_only', $event)">{{selectAllQty.checked
                        ? 'Deselect' : 'Select' }} all</mat-checkbox>
                    </td>
                    <td>
                      <!-- <mat-select placeholder="Required?" (selectedValueChange)="fillAll(element.form.controls.availability.controls,'max_mod_units', $event)">
                                    <mat-option *ngFor="let mod of mods" [value]="mod.value">
                                      {{mod.label}}
                                    </mat-option>
                                  </mat-select> -->
                    </td>
                  </tr>
                  <tr [formGroup]="control" *ngFor="let control of element.form.controls.availability.controls ">
                    <td>
                      {{control.value.name}}
                    </td>
                    <td>
                      <mat-checkbox class="full-width" formControlName="is_available"></mat-checkbox>
                    </td>
                    <td>
                      <mat-select formControlName="reset" placeholder="Reset" class="!w-3/4"
                        (selectionChange)="doSomething($event, element.form)">
                        <mat-option *ngFor="let opt of resetOptions" [value]="opt.key">{{opt.value}}</mat-option>
                      </mat-select>
                    </td>
                    <td>
                      <mat-checkbox class="full-width" formControlName="has_half_mod"></mat-checkbox>
                    </td>
                    <td>
                      <mat-checkbox class="full-width" formControlName="has_quantity"
                        (change)="enableMaxMod($event, control)"></mat-checkbox>
                    </td>
                    <td>
                      <mat-checkbox class="full-width" formControlName="is_pos_only"
                        (change)="enableMaxMod($event, control)"></mat-checkbox>
                    </td>
                    <td>
                      <mat-select (selectionChange)="doSomething($event, element.form)" formControlName="max_mod_units"
                        placeholder="Max Mod Units" #allSelected>
                        <mat-option *ngFor="let mod of mods" [value]="mod.value">
                          {{mod.label}}
                        </mat-option>
                      </mat-select>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="action">
                <button mat-stroked-button color="primary" class="cancel-btn"
                  (click)="expandedElement.set(modifierGroup.id, null); false;">Cancel</button>
                <button mat-stroked-button color="primary" class="save-btn"
                  [disabled]="!element.form.valid">Save</button>
              </div>
            </form>
          </div>
          <div *ngSwitchCase="'modifier'">
            <form class="form" [formGroup]="element.form" (ngSubmit)="updateModifier(element.form.value)">
              <div class="flex flex-col">
                <div>
                  <mat-form-field class="w-[13%] max-w-[50%] md:w-[20%] mr-[1.5%] xs:!w-3/5">
                    <input matInput placeholder="Modifer Name" formControlName="name">
                  </mat-form-field>

                  <mat-form-field class="w-[9%] max-w-[50%] md:w-[14%] mr-[1%] xs:!w-2/6">
                    <input matInput placeholder="Upcharge (USD)" formControlName="upcharge" type="number">
                  </mat-form-field>

                  <mat-checkbox disableRipple class="!w-auto checkbox xs:max-w-full" formControlName="has_quantity"
                    (change)="enableMaxMod($event, element.form)">Quantity</mat-checkbox>

                  <mat-checkbox disableRipple class="!w-auto mr-[1.5%] checkbox" formControlName="fixed_price">Fixed
                    Price</mat-checkbox>

                  <mat-form-field class="w-[9%] max-w-[50%] md:w-[14%] mr-[1%] xs:!w-2/6">
                    <input matInput placeholder="Food Cost" formControlName="food_cost" type="number">
                  </mat-form-field>

                  <mat-form-field class="w-[9%] max-w-[50%] md:w-[14%] mr-[1%] xs:!w-3/5	">
                    <mat-select formControlName="max_mod_units" placeholder="Max mod units">
                      <mat-option *ngFor="let mod of mods" [value]="mod.value">
                        {{mod.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-checkbox disableRipple class="!w-auto full-width checkbox"
                    formControlName="is_available">Available</mat-checkbox>

                  <mat-form-field class="w-[9%] max-w-[50%] md:w-[14%] mr-[1%] xs:!w-3/5">
                    <mat-select formControlName="reset" placeholder="Reset">
                      <mat-option *ngFor="let opt of resetOptions" [value]="opt.key">{{opt.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-checkbox disableRipple class="!w-auto full-width checkbox" formControlName="has_half_mod">Half
                    Mod</mat-checkbox>
                  <!-- For Half Mode -->
                  <span *ngIf="element.form.value.has_half_mod">
                    <mat-form-field class="w-[9%] max-w-[50%] md:w-[14%] xs:!w-[95%]">
                      <input matInput placeholder="Half Mod Upcharge (USD)" formControlName="half_mod_upcharge"
                        type="number">
                    </mat-form-field>
                  </span>

                  <mat-checkbox disableRipple class="!w-auto full-width checkbox" formControlName="is_pos_only">POS
                    Only</mat-checkbox>

                  <mat-form-field class="w-[9%] max-w-[50%] md:w-[14%] mr-[1%] xs:!w-2/6">
                    <input matInput placeholder="No Charge" formControlName="no_charge" type="number">
                  </mat-form-field>
                  <mat-form-field class="w-[9%] max-w-[50%] md:w-[14%] mr-[1%] xs:!w-2/6">
                    <input matInput placeholder="Light Charge" formControlName="light_charge" type="number">
                  </mat-form-field>
                  <mat-form-field class="w-[9%] max-w-[50%] md:w-[14%] mr-[1%] xs:!w-2/6">
                    <input matInput placeholder="Extra Charge" formControlName="extra_charge" type="number">
                  </mat-form-field>
                  <mat-form-field class="w-[9%] max-w-[50%] md:w-[14%] mr-[1%] xs:!w-2/6">
                    <input matInput placeholder="On Side Charge" formControlName="onside_charge" type="number">
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="mr-[1%]">
                    <!-- <mat-label>Assign ID</mat-label> -->
                    <mat-select formControlName="joined_with" (selectionChange)="onOptionSelected($event, element)"
                      #select panelClass="custom-select-panel">
                      <mat-select-trigger>
                        {{ getSelectedOptionLabel(element.form.get('joined_with')?.value || element.data.joined_with) }}
                      </mat-select-trigger>

                      <div class="sticky top-0 z-10 bg-white p-2">
                        <mat-form-field class="w-full p-0 cursor-default search-option">
                          <input matInput placeholder="Search..." (keyup)="filterOptions($event.target.value)"
                            (click)="$event.stopPropagation()">
                        </mat-form-field>
                      </div>

                      <div>
                        <mat-option *ngFor="let option of filteredOptions" [value]="option.id">
                          {{ option.id }}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                  <span class="relative left-[1.5rem] bottom-[7px]" *ngIf="element.form.get('reset_time')?.value">Reset
                    Time: {{ element.form.get('reset_time')?.value }}</span>
                </div>
                <div class="action">
                  <button mat-stroked-button class="cancel-btn" color="primary"
                    (click)="expandedElement.set(modifierGroup.id, null); false;">Cancel</button>
                  <button mat-stroked-button class="save-btn" color="primary"
                    [disabled]="!element.form.valid">Save</button>
                </div>
              </div>
            </form>
          </div>
          <div *ngSwitchCase="'add'">
            <form class="form" [formGroup]="element.form" (ngSubmit)="addModifier(element.form.value)">
              <div class="flex flex-col">
                <div>
                  <mat-form-field class="w-[16%] md:w-[20%] mr-[1.5%] xs:!w-3/5">
                    <input matInput placeholder="Modifer Name" formControlName="name">
                  </mat-form-field>

                  <mat-form-field class="w-[12%] md:w-[14%] mr-[1%] xs:!w-2/6">
                    <input matInput placeholder="Upcharge (USD)" formControlName="upcharge" type="number">
                  </mat-form-field>

                  <mat-checkbox disableRipple class="!w-auto checkbox max-w-[14%] xs:max-w-full"
                    formControlName="has_quantity" (change)="enableMaxMod($event, element.form)">Quantity</mat-checkbox>

                  <mat-checkbox disableRipple class="!w-auto mr-[1.5%] checkbox" formControlName="fixed_price">Fixed
                    Price</mat-checkbox>

                  <mat-form-field class="width-25 xs:!w-2/6">
                    <input matInput placeholder="Food Cost" formControlName="food_cost" type="number">
                  </mat-form-field>

                  <mat-form-field class="w-[12%] md:w-[14%] mr-[1%] xs:!w-3/5	">
                    <mat-select formControlName="max_mod_units" placeholder="Max mod units">
                      <mat-option *ngFor="let mod of mods" [value]="mod.value">
                        {{mod.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-checkbox disableRipple class="!w-auto full-width checkbox"
                    formControlName="is_available">Available</mat-checkbox>

                  <mat-form-field class="w-[12%] md:w-[14%] mr-[1%] xs:!w-3/5">
                    <mat-select formControlName="reset" placeholder="Reset">
                      <mat-option *ngFor="let opt of resetOptions" [value]="opt.key">{{opt.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-checkbox disableRipple class="!w-auto full-width checkbox" formControlName="has_half_mod">Half
                    Mod</mat-checkbox>

                  <span *ngIf="element.form.value.has_half_mod">
                    <mat-form-field class="w-[12%] md:w-[14%] xs:!w-[95%]">
                      <input matInput placeholder="Half Mod Upcharge (USD)" formControlName="half_mod_upcharge"
                        type="number">
                    </mat-form-field>
                  </span>

                  <mat-checkbox disableRipple class="!w-auto full-width checkbox" formControlName="is_pos_only">POS
                    Only</mat-checkbox>
                </div>
                <div class="action">
                  <button mat-stroked-button class="cancel-btn" color="primary"
                    (click)="expandedElement.set(modifierGroup.id, null); false;">Cancel</button>
                  <button mat-stroked-button class="save-btn" color="primary"
                    [disabled]="!element.form.valid">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </td>
  </ng-container>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
    [class.example-expanded-row]="expandedElement.get(modifierGroup.id) === element"
    (click)="expandRow(modifierGroup.id, element)" cdkDrag [cdkDragData]="element"
    [cdkDragStartDelay]="isTouchDevice ? 100 : 0" [cdkDragDisabled]="!isIconHovered">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>