import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewChildren, OnChanges, SimpleChanges, Injector, ComponentRef
} from '@angular/core';
import { StoreService } from '../_services/store.service';
import { Store } from '../_models/store';
import { BotOrderDataSource } from '../_datasources/bot-order-datasource';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ReceiptService } from '../_services/receipt.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { ComponentCanDeactivate } from '../_guards/changes.guard';
import { PermissionService } from '../_services/permission.service';
import { Permission } from '../_models/permission';
import { MessageTableComponent } from './message-table/message-table.component';
import { OrderService } from '../_services/order.service';
import { POSOrderTabChangeService } from '../_services/pos-order-tab-change.service';
import { POSSummaryToggleService } from '../_services/pos-summary-toggle.service';
import { OpenOrderToggleService } from '../_services/open-order-toggle.service';
import { OrderTypeTabChangeService } from '../_services/order-type-tab-change.service';
import { OpenTabComponent } from './open-tab/open-tab.component';
import { DialogPosComponent } from './dialog-pos/dialog-pos.component';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { UserService } from '../_services/user.service';
import { POSService } from '../_services/pos-service';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { RefreshService } from 'src/app/_services/refresh.service';
import { BusinessService } from 'src/app/business.service';
import { GiftCardOrderComponent } from '../dashboard/gift-card-order/gift-card-order.component';
import { webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { TerminalStatusService } from '../_services/terminal-status.service';
import { OrdersV2DataSource } from '../_datasources/order-v2-datasource';
import { BotOrder } from '../_models/order';
import { OrderWebsocketService } from '../_services/websocket.service';
import { MqttService } from 'ngx-mqtt';
import { CurrentOrderService } from '../_services/current-order.service';
import { FrontFacingMqttService } from '../_services/front-facing-mqtt.service';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { catchError, finalize, } from 'rxjs/operators';
import { CallerIdService } from '../_services/caller-id.service';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PosFullPageComponent } from './pos-full-page/pos-full-page.component';
import { jwtDecode } from 'jwt-decode';
import * as LogRocket from 'logrocket';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate, AfterViewInit, OnChanges {
  browser: string;
  fullScreenViaIcon: boolean = false;
  openCloseMenu: boolean;
  sideShowHide: boolean = true;
  fullScreenSub: Subscription;
  private overlayRef: OverlayRef;
  constructor(
    private storeService: StoreService,
    private userService: UserService,
    private receiptService: ReceiptService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private permissionService: PermissionService,
    private orderService: OrderService,
    private tabChangeService: POSOrderTabChangeService,
    private posSummaryToggleService: POSSummaryToggleService,
    private posOrderTabChangeService: POSOrderTabChangeService,
    private openOrderSummaryToggleService: OpenOrderToggleService,
    public dialog: MatDialog,
    private idle: Idle,
    private posService: POSService,
    private orderTypeTabChangeService: OrderTypeTabChangeService,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    // private router: Router,
    private refreshService: RefreshService,
    private businessService: BusinessService,
    private terminalStatusService: TerminalStatusService,
    private orderWebsocketService: OrderWebsocketService,
    private snackBar: MatSnackBar,
    private mqttService: MqttService,
    private currentOrderService: CurrentOrderService,
    private ffmq: FrontFacingMqttService,
    private callerIdService: CallerIdService,
    private overlay: Overlay,
    private injector: Injector
  ) {

    this.orderSetGet = this.businessService.orderSet.pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      if (result.length != 0) {
        setTimeout(() => {
          this.mqttOrderGet(result);
        }, 3000);
      }
    });
    this.posService.validUserPin = false;
    this.disableInput = false;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.WindowResized();

    this.openFromOpenTab = this.orderService.openPosLoginFromOpenOrder.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (data) {
        if (this.store.hasModule('POS')) {
          // if (!this.dialog.openDialogs.length) {
          this.openFullScreenPOS();
            // this.openDialog();
          // }
        }
      }
    });

    this.fullScreenSub = this.storeService.fullScreenUpdate.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (data) {
        this.fullScreenViaIcon = data;
        this.sideShowHide = false;
        this.isFullScreen = data;
      } else {
        this.fullScreenViaIcon = data;
        this.isFullScreen = data;


      }

    });
  }
  orderTabs: MatTabGroup;

  @ViewChild("orderTabGroupOld", { static: false }) orderTabsOld: MatTabGroup;
  @ViewChild("orderTabGroupNew", { static: true }) orderTabsNew: MatTabGroup;

  @ViewChild("orderSummaryDrawer", { static: false }) orderSummaryDrawer: MatDrawer;
  @ViewChild("openSummaryDrawer", { static: false }) openSummaryDrawer: MatDrawer;
  @ViewChild("closedOrders", { static: false }) closedOrders: MessageTableComponent;
  @ViewChild("giftCard", { static: false }) giftCard: GiftCardOrderComponent;
  @ViewChild("sidenav", { static: false }) sidenav: MatSidenav;
  @ViewChild('scrollableElement') scrollableElement: ElementRef<HTMLElement>;
  @ViewChild("openOrders", { static: false }) openOrders: OpenTabComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  stores = [];
  store: Store;
  dataSource: BotOrderDataSource;
  terminalId;
  opened = false;
  showSummaryDrawer = true;
  selectedStoreName;
  storeSub: Subscription;
  tabChangeSub: Subscription;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  tabIndex;
  permission: Permission;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  disableInput: boolean;
  validUserData: any;
  closeDialog: any;
  isUserSessionActive = false;
  isOrdersActive = false;
  private unsubscribe: Subject<null> = new Subject();
  parserMenuItems: any;
  heightWindowResize: any;
  heightWindowResize2: any;
  paysub: Subscription;

  colorDataSource: OrdersV2DataSource;

  summaryDrawerSub: Subscription;
  openDrawerSub: Subscription;
  idleSubs: Subscription;

  posAutosize = false;
  tabs = ['CLOSED ORDERS', 'DELIVERIES', 'REPORTING', 'STATEMENTS'];
  selected = new UntypedFormControl(0);
  isFullScreen = false;
  elem;

  statusSocket;
  isOnline;
  statusSocketSubject;
  refresh;

  filters: any;
  posLoggedUser = localStorage.getItem('posLoggedUser') ? localStorage.getItem('posLoggedUser') : '';
  private botOrderSubject = new BehaviorSubject<BotOrder[]>([]);
  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  storeID: any;
  addIndex: string;
  addOpenIndex: any;
  elementBlinkOpen: any;
  aaa: boolean;
  aaaa: boolean;
  allTrueFalse: any = [];
  TimeDataFor: any;
  private subscription: Subscription;
  private orderAccept: Subscription;
  isOrdersTab: boolean;
  orderSetGet: any;
  openFromOpenTab: Subscription;
  subSubsc: Subscription;
  private touchStartY: number = 0;
  private onTouchStartBound: any;
  private onTouchMoveBound: any;
  private callSubs: Subscription;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    this.setUserInactive();
    localStorage.removeItem('tab');
    return this.opened;
  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  ngOnInit() {

    this.browser = localStorage.getItem('browser');
    this.elem = document.documentElement;
    if (localStorage.getItem('setOccasionDetail') != null) {
      localStorage.removeItem('setOccasionDetail');
    }

    this.onTouchStartBound = this.onTouchStart.bind(this);
    this.onTouchMoveBound = this.onTouchMove.bind(this);

    window.addEventListener('touchstart', this.onTouchStartBound, { passive: false });
    window.addEventListener('touchmove', this.onTouchMoveBound, { passive: false });

    this.orderTypeTabChangeService.current.pipe(takeUntil(this.unsubscribe)).subscribe(currentTab => {
      console.log(currentTab);
      console.log(this.orderTabs);
      if (this.store) {
        if (this.store.getFeatureFlag('NEW_ORDER_TAB')) {
          this.orderTabsNew.selectedIndex = currentTab || 0;
        } else {
          this.orderTabsOld.selectedIndex = currentTab || 0;
        }
      }
    });

    const currentStoreId = localStorage.getItem('currentStore');
    this.storeSub = this.storeService.current.pipe(takeUntil(this.unsubscribe)).subscribe((store) => {
      if (store) {
        this.logoutCurrentUser();
        this.store = Object.assign(new Store(), store);
        // if (store.pin_timeout == null || store.pin_timeout < 0) {
        //   this.idle.setIdle(3600);
        // } else {
        //   this.idle.setIdle(store.pin_timeout);
        // }
      }
      if (this.store && this.store.hasModule('POS')) {
        const findPos = this.tabs.find((item) => {
          return item === 'POS';
        });
        if (!findPos) {
          this.tabs.unshift('POS');
        }

        // setTimeout(() => {
        //   if(this.orderTabs){
        //     this.orderTabs.selectedIndex = 0;
        //   }
        // });

        if (!this.isOrdersActive) {
          this.openFullScreenPOS();
          // this.openDialog();

          this.isOrdersActive = true;
        }
      } else {
        this.tabs = this.tabs.filter((obj) => obj !== 'POS');
      }

      if (store && store.id != currentStoreId) {
        this.router.navigate(['home', 'info']);
        if (this.storeSub) {
          this.storeSub.unsubscribe();
        }
      }

      if ((this.store && this.store.has_order_occasion_tableside || this.store && this.store.hasModule('POS')) && !this.tabs.includes('OPEN ORDERS')) {
        console.log('has open orders');
        const coIndex = this.tabs.indexOf('CLOSED ORDERS');
        this.tabs.splice(coIndex + 1, 0, 'OPEN ORDERS');
      }

      if (this.store && this.store.feature_flag['cash_drawer_management']) {
        const coIndex = this.tabs.indexOf('REPORTING');
        this.tabs.splice(coIndex + 1, 0, 'CASH LOGS');
      }
    });

    this.permissionService.current.pipe(takeUntil(this.unsubscribe)).subscribe((permission) => {
      this.permission = permission;
    });
    this.summaryDrawerSub = this.posSummaryToggleService.current.pipe(takeUntil(this.unsubscribe)).subscribe(
      (state) => {
        setTimeout(() => {
          if (this.orderSummaryDrawer) {
            if (state === 'OPEN') {
              this.orderSummaryDrawer.open();
              this.scrollToTop();
            } else {
              this.orderSummaryDrawer.close();
            }
          }
        }, 10);
      }
    );

    this.openDrawerSub = this.openOrderSummaryToggleService.current.pipe(takeUntil(this.unsubscribe)).subscribe(
      (state) => {
        if (this.openSummaryDrawer) {
          if (state === 'OPEN') {
            this.openSummaryDrawer.open();
          } else {
            this.openSummaryDrawer.close();
          }
        }
      }
    );

    this.dataSource = new BotOrderDataSource(this.businessService);
    this.detectIdleChange();

    this.storeID = localStorage.getItem('currentStore');
    this.currentOrderService.current.pipe(takeUntil(this.unsubscribe)).subscribe(data => this.ffmq.setCurrentOrder(data));

    this.subSubsc = this.posService.drawerEndLogOutuser.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (data) {
        this.logoutCurrentUser();
      }
    });

    this.callSubs = this.callerIdService.acceptIgnreCall.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (data) {
        const currentPin = localStorage.getItem('currentPIN');
        const isOpen = this.dialog.openDialogs.some(dialog => dialog.id === 'myUniqueDialog');
        if (!currentPin && !isOpen) {
          this.openFullScreenPOS();
          // this.openDialog();
        } else if (!currentPin) {
          this.snackBar.open('Please log in to the POS.', 'OK', { duration: 2000 });
        }
        if (localStorage.getItem('tab') === 'POS') {
          this.posSummaryToggleService.toggle('OPEN');
          this.refreshService.refreshModule('POS_ORDER_SUMMARY');
        } else {
          this.orderTypeTabChangeService.changeTab(0);
          this.posSummaryToggleService.toggle('OPEN');
        }
        this.callerIdService.acceptIgnreCall.next(false);
      }
    });

  }
  @HostListener('window:resize', ['$event'])
  WindowResized(event?) {
    // this.heightWindowResize = window.innerHeight - 200;
    // this.heightWindowResize2 = window.innerHeight - 130;
    this.heightWindowResize = window.innerHeight - 140;
    this.heightWindowResize2 = window.innerHeight - 70;
  }

  idleSet() {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    if (this.store.pin_timeout == null || this.store.pin_timeout < 0) {
      this.idle.setIdle(3600);
    } else {
      this.idle.setIdle(this.store.pin_timeout);
    }

    this.idleSubs = this.idle.onIdleStart.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.setUserInactive();
      if (this.orderSummaryDrawer) {
        this.orderSummaryDrawer.close();
      }

      this.posService.validUserPin = false;
      if (this.store.hasModule('POS')) {
        // if (!this.dialog.openDialogs.length) {
        this.openFullScreenPOS();
          // this.openDialog();

        // }
      }

      if (this.orderTabs != undefined) {
        if (this.orderTabs.selectedIndex === 0) {
          this.posService.validUserPin = false;
          this.orderService.disableInputButton = true;
          // if (this.store.hasModule("POS")) {
          //   if (!this.dialog.openDialogs.length) {
          //     this.openDialog();
          //   }
          // }
        }
      }
    });
  }

  scrollToTop() {
    if (this.scrollableElement && this.scrollableElement.nativeElement) {
      this.scrollableElement.nativeElement.scrollTop = 0;
    }
  }
  setUserInactive() {
    if (this.validUserData && this.isUserSessionActive) {
      this.orderService
        .updateTerminalStatus(
          this.validUserData.terminalId,
          this.validUserData.user,
          this.validUserData.sessionId,
          'inactive'
        ).pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.isUserSessionActive = false;
          this.validUserData = null;
          localStorage.removeItem('selectedTerminal');
          localStorage.removeItem('selectedTerminalName');
          localStorage.removeItem('posLoggedUser');
          localStorage.removeItem('pinUser');
        });
    }

    if (this.orderSummaryDrawer) {
      this.orderSummaryDrawer.close();
    }
  }

  logoutCurrentUser() {
    this.setUserInactive();
    this.posService.validUserPin = false;
    localStorage.removeItem('selectedTerminal');
    localStorage.removeItem('selectedTerminalName');
    localStorage.removeItem('posLoggedUser');
    localStorage.removeItem('currentPIN');
    localStorage.removeItem('pinUser');
    if (this.store?.feature_flag['multiple_default_load']) {
      this.orderService.updateDefaultOccasion.next(false);
    }
  }

  detectIdleChange() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
  }

  openDialog() {
    if (this.posService.validUserPin == false) {
      const isMobile = window.innerWidth <= 470;
      const dr = this.dialog.open(DialogPosComponent, {
        id: 'myUniqueDialog',
        width: '100vw',
        maxWidth: '100vw',
        height: '100vh',
        maxHeight: '100vh',
        disableClose: true,
      });
      this.closeDialog = dr.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
        if (data) {

          this.validUserData = data;
          this.isUserSessionActive = true;
          this.orderService.openPosLoginFromOpenOrder.next(false);
          // this.ffmq.destroyPaymentModeSubscription();
          // this.ffmq.getPaymentMode();
          // this.terminalStatus();
          this.idleSet();
          setTimeout(() => {

            if (this.store.feature_flag['multiple_default_load']) {
              this.orderService.updateDefaultOccasion.next(true);
            }
          }, 200);
          this.createLogRocketSession();
        }
        this.detectIdleChange();
      });
    }
  }

  openFullScreenPOS(): void {
    if (this.overlayRef) {
      return; // Exit if an overlay is already open
    }
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position()
        .global()
        .top('0')
        .left('0')
        .width('100vw')
        .height('100vh'),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      panelClass: 'full-screen-overlay'
    });

    // Pass the overlayRef to FullPagePosComponent
    const portal = new ComponentPortal(PosFullPageComponent, null, this.injector);
    const componentRef: ComponentRef<PosFullPageComponent> = this.overlayRef.attach(portal);

    componentRef.instance.closeOverlay.subscribe((data) => {
      console.log('Overlay closed with data:', data);
      if (data?.reason !== 'cancel') {
        this.validUserData = data;
        this.isUserSessionActive = true;
        this.orderService.openPosLoginFromOpenOrder.next(false);
        this.ffmq.getPaymentMode();
        this.idleSet();
        setTimeout(() => {

          if (this.store.feature_flag['multiple_default_load']) {
            this.orderService.updateDefaultOccasion.next(true);
          }
        }, 200);
        this.detectIdleChange();
      }
      this.closeOverlay() // Cleanup overlay
    });


    // Close on outside click
    this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
  }

  closeOverlay(): void {
    if (this.overlayRef) {
      console.log('Disposing overlay');
      this.overlayRef.dispose();
      this.overlayRef = null; // Reset overlay reference
    }

    const overlayContainers = document.querySelectorAll('.cdk-overlay-container');
    overlayContainers.forEach(container => {
      container.innerHTML = ''; // ✅ Clear any leftover overlay elements
    });
  }

  createLogRocketSession() {
    const productionHost = 'business.labrador.ai';
    if (window.location.hostname === productionHost) {
        const userData = localStorage.getItem('currentUser');

        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                if (parsedData.token) {
                    const decodedToken: any = jwtDecode(parsedData.token);

                    console.log("🔹 Decoded Token Data:", decodedToken);

                    // ✅ Directly initialize LogRocket (no session check)
                    console.log("⚠️ Initializing LogRocket in Production");
                    LogRocket.init('ujzehv/labrador-ai');

                    console.log("🔄 Updating LogRocket User Identity");
                    LogRocket.identify(decodedToken.id, {
                        username: decodedToken.username || "Unknown User",
                        email: decodedToken.email || "No Email",
                        first_name: decodedToken.first_name || "No First Name",
                        last_name: decodedToken.last_name || "No Last Name",
                        last_login: decodedToken.last_login || "Unknown",
                    });

                    // ✅ Log session replay link
                    LogRocket.getSessionURL(sessionURL => {
                        console.log("🔁 Updated LogRocket Session Replay:", sessionURL);
                        LogRocket.log(`🔁 Updated LogRocket Session Replay: ${sessionURL}`);
                    });

                }
            } catch (error) {
                console.error("🚨 Error parsing user token:", error);
            }
        } else {
            console.log("⚠️ No user data found in localStorage");
        }
    } else {
        console.log("⚠️ LogRocket is disabled in non-production environments.");
    }
  }

  terminalStatus() {

    this.terminalId = localStorage.getItem('selectedTerminal');
    this.statusSocket = webSocket(`${environment.websocketHost}/ws/link/${this.terminalId}/`);
    this.statusSocketSubject = this.statusSocket.pipe(takeUntil(this.unsubscribe)).subscribe((msg) => {
      console.log(msg);
      switch (msg.command) {
        case 'ON':
          this.terminalStatusService.updateStatus({ status: 'ON' });
          break;
        case 'OFF':
          this.terminalStatusService.updateStatus({ status: 'OFF' });
          break;
        case 'BT_DEVICE_STATUS':
          console.log(msg);
          switch (msg.status) {
            case 'CONNECTED_UNCONFIGURED':
              this.snackBar.open('Payment terminal is being updated');
              break;
            case 'DISCONNECTED':
              this.snackBar.open('Payment terminal got disconnected, Please check the device.');
              break;
            case 'CONNECTED':
              this.snackBar.open('Connected to Payment terminal via Bluetooth.', 'OK', { duration: 1000 });
              break;
          }
          break;
        default:
          console.log(msg.command);
          if (msg.command) {
            this.terminalStatusService.updateStatus({ status: 'PING' });
          }
          break;
      }
    });
  }

  ngOnDestroy() {

    window.removeEventListener('touchstart', this.onTouchStartBound);
    window.removeEventListener('touchmove', this.onTouchMoveBound);
    if (this.closeDialog) {
      this.closeDialog.unsubscribe();
    }
    if (this.idleSubs) {
      this.idleSubs.unsubscribe();
    }
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.summaryDrawerSub) {
      this.summaryDrawerSub.unsubscribe();
    }
    if (this.openDrawerSub) {
      this.openDrawerSub.unsubscribe();
    }
    if (this.mobileQuery) {
      this.mobileQuery.removeListener(this._mobileQueryListener);
    }
    // this.subscription.unsubscribe();
    // this.orderAccept.unsubscribe();

    if (this.paysub) {
      this.paysub.unsubscribe();
    }

    if (this.fullScreenSub) {
      this.fullScreenSub.unsubscribe();
    }

    if (this.subSubsc) {
      this.subSubsc.unsubscribe();
    }

    if (this.callSubs) {
      this.callSubs.unsubscribe();
    }

    this.setUserInactive();
    this.idle.ngOnDestroy();
    this.posSummaryToggleService.toggle('CLOSE');
    this.tabChangeService.changeTab(0);
  }

  clearSelection() {
    this.receiptService.changeBotOrder(null);
  }

  openPOSDrawer() {
    this.validUserData && this.orderSummaryDrawer ? this.orderSummaryDrawer.toggle() : '';
  }
  
  handleNewOrderClick() {
    this.store.hasModule('POS') && !this.validUserData ? this.openFullScreenPOS() : '';
  }

  changeTabOfDoneButton() {
    this.orderSummaryDrawer.toggle();
    this.tabIndex = 0;
  }

  openOpenSummaryDrawer() {
    this.openSummaryDrawer.toggle();
  }

  resetOrder() {
    localStorage.removeItem('order_hash');
    this.orderService.changeOrderSubject.next(null);
  }

  onTabChange(event) {
    // let orderHash = localStorage.getItem("order_hash");
    // if (orderHash != null) {
    //   this.orderService.getParserMenuItems(orderHash).then(ret => {
    //     for (var i in ret["parser_menu_items"]) {
    //       let item = ret["parser_menu_items"][i];
    //       if (item["ts_submitted"] == false) {
    //         let retV = this.orderService.removeItemId(orderHash, item["id"], {});
    //         this.refreshService.refreshModule("POS_ORDER_SUMMARY");
    //         this.refreshService.refreshModule('OPEN_TAB');
    //         this.dataSource.loadOpenOrders(+this.store.id);
    //         this.refreshService.refreshModule('OPEN_TAB');
    //       }
    //     }
    //   });
    // }

    switch (event.index) {
      case 0:
        console.log('POS Tab Selected');
        this.posOrderTabChangeService.changeTab(2); // go last tab
        // this.posOrderTabChangeService.changeTab(0); // go on first tab
        if (this.orderSummaryDrawer) {
          this.orderSummaryDrawer.close();
        }
        if (this.tabs.includes('POS')) {
          localStorage.setItem('tab', 'POS');
        }
        break;
      case 1:
        console.log('Closed Orders');
        localStorage.removeItem('setOccasionDetail');
        localStorage.removeItem('tab');
        this.orderService.tableUpdate.next(true);
        break;
      case 2:
        console.log('Open Orders');
        localStorage.removeItem('setOccasionDetail');
        localStorage.removeItem('tab');
        // this.openSummaryDrawer.close();
        // this.openOrders.collapseAll();
        this.orderService.tableUpdate.next(true);
        break;
      case 3:
        localStorage.removeItem('setOccasionDetail');
        localStorage.removeItem('tab');
        console.log('Reporting');
        this.orderService.tableUpdate.next(true);
        break;
      case 4:
        localStorage.removeItem('setOccasionDetail');
        localStorage.removeItem('tab');
        console.log('Statements');
        this.orderService.tableUpdate.next(true);
        break;
      case 5:
        localStorage.removeItem('setOccasionDetail');
        localStorage.removeItem('tab');
        console.log('Gift card orders');
        this.orderService.tableUpdate.next(true);
        break;
      case 6:
        localStorage.removeItem('setOccasionDetail');
        localStorage.removeItem('tab');
        console.log("Delivery");
        this.orderService.tableUpdate.next(true);
        break;
      default:
        localStorage.removeItem('setOccasionDetail');
        localStorage.removeItem('tab');
        console.log('Default case');
        this.orderService.tableUpdate.next(true);
        break;
    }
  }

  toggleFullscreen(e) {
    if (this.isFullScreen) {
      this.sideShowHide = true;
      this.closeFullscreen(e);
    } else {
      this.openFullscreen(0, e);
    }
    const sidenav = document.querySelector('.mat-sidenav') as HTMLElement;
    if (sidenav) {
      sidenav.classList.toggle('fullscreen-mode', this.isFullScreen);
    }
    const sidenavContent = document.querySelector('.mat-drawer-content') as HTMLElement;
    if (sidenavContent) {
      sidenavContent.classList.toggle('fullscreen-mode', this.isFullScreen);
    }
  }

  openFullscreen(e, f) {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.elem.msRequestFullscreen();
    }
    this.storeService.fillScreenUpdateFromPOS.next(true);
    this.fullScreenViaIcon = true;

    const topHeader = document.querySelector('.mat-toolbar') as HTMLElement;
    if (topHeader) {
      topHeader.style.visibility = 'hidden';
    }
    const appContainer = document.querySelector('.app-container') as HTMLElement;
    if (appContainer) {
      appContainer.style.top = '0px';
    }

    this.isFullScreen = true;
    if (e != 0) {
      this.sideShowHide = false;
    }
    if (e == 0 && f == 'height') {
      this.posService.posMenuHeight.next('full-screen');
    }
  }

  closeFullscreen(e) {
    this.fullScreenViaIcon = false;

    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      this.document.msExitFullscreen();
    }
    this.storeService.fillScreenUpdateFromPOS.next(false);
    document.body.removeEventListener('click', this.closeFullscreen);
    const topHeader = document.querySelector('.mat-toolbar') as HTMLElement;
    if (topHeader) {
      topHeader.style.visibility = 'hidden';
    }
    const appContainer = document.querySelector('.app-container') as HTMLElement;
    if (appContainer) {
      appContainer.style.top = '0px';
    }

    this.isFullScreen = false;
    setTimeout(() => {
      this.addMarginSideNavContent();
    }, 400)

    if (e == 'height') {
      this.posService.posMenuHeight.next('close-screen');
    }

  }


  openSideMenu() {
    this.sideShowHide = false;
    const open = localStorage.getItem('side-nav');
    if (open) {
      this.storeService.sideNavOpenClose.next(false);
    } else {
      this.storeService.sideNavOpenClose.next(true);
    }

  }

  closeSideMenu() {
    this.sideShowHide = true;
    const open = localStorage.getItem('side-nav');
    if (open) {
      this.storeService.sideNavOpenClose.next(true);
    } else {
      this.storeService.sideNavOpenClose.next(false);
    }
  }


  addMarginSideNavContent() {
    const sidenavContent = document.querySelector('.mat-drawer-content') as HTMLElement;
    if (sidenavContent) {
      sidenavContent.style.marginLeft = '257px';
    }
  }


  getParserMenuItems(items) {
    this.parserMenuItems = items;
  }

  hasBLEPOS() {
    this.terminalId = localStorage.getItem('selectedTerminal');
    if (this.terminalId) {
      return this.terminalId.startsWith('CHB');
    } else {
      return false;
    }

  }

  mqttOrderGet(orderResponse) {
    if (orderResponse.bot_order.is_tab_open == true) {
      console.log('Is tab open TRUE');
      const tabss = this.tabs.indexOf('OPEN ORDERS');
      this.addOpenIndex = (tabss + 1).toString();
      const ddddd = '[aria-posinset=' + '\'' + this.addOpenIndex + '\'' + ']';
      this.elementBlinkOpen = document.querySelector(ddddd);
      if (orderResponse.bot_order.view_notified == false && orderResponse.bot_order.is_pos == false) {
        this.isOrdersTab = true;
        localStorage.setItem('Tab', 'OPEN');
        this.storeService.isOrderTab.next(true);
        if (this.elementBlinkOpen != null) {
          this.elementBlinkOpen.classList.add('clases');
        }
      } else {
        this.isOrdersTab = false;
        this.storeService.isOrderTab.next(false);
        if (this.elementBlinkOpen != null) {
          this.elementBlinkOpen.classList.remove('clases');
        }
      }
    } else if (orderResponse.bot_order.is_tab_open == false) {
      console.log('Is tab open FALSE');
      const tabss = this.tabs.indexOf('CLOSED ORDERS');
      this.addOpenIndex = (tabss + 1).toString();
      const ddddd = '[aria-posinset=' + '\'' + this.addOpenIndex + '\'' + ']';
      this.elementBlinkOpen = document.querySelector(ddddd);
      if (orderResponse.bot_order.view_notified == false && orderResponse.bot_order.is_pos == false) {
        localStorage.setItem('Tab', 'CLOSE');
        if (this.elementBlinkOpen != null) {
          this.elementBlinkOpen.classList.add('clases');
        }
      } else {
        this.isOrdersTab = false;
        this.storeService.isOrderTab.next(false);
        if (this.elementBlinkOpen != null) {
          this.elementBlinkOpen.classList.remove('clases');
        }
      }
    }
  }

  onTouchStart(event: TouchEvent): void {
    // Record the initial Y position of the touch
    this.touchStartY = event.touches[0].clientY;
  }

  onTouchMove(event: TouchEvent): void {
    const touchMoveY = event.touches[0].clientY;
    // Check if scroll position is at the top and user is swiping down
    if (window.scrollY === 0 && touchMoveY > this.touchStartY) {
      event.preventDefault(); // Prevent pull-to-refresh
    }
  }

}
