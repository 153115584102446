<div class="flex flex-col bg-white p-5 w-[630px] h-[290px] xs:w-full xs:h-auto">
  <div class="nav">
    <button mat-button type="button" (click)="goBack()" class="go-back" tabindex="-1">Go Back</button>
  </div>
  <form class="pt-2.5">
    <p class="paymentType xs:!text-2xl xs:!mb-3">
      Enter Custom Tip
    </p>
    <div class="input-box !mt-5">
      <input class="tip-input" placeholder="Custom Tip" type="number" name="customtip" id="customtip" #customtip>
    </div>
    <button mat-button type="button" class="pay xs:!h-[65px] !mt-[30px]" tabindex="-1"
      (click)="onSubmit(+customtip.value)">
      <span class="add-size" fxFlexAlign="center">Submit</span>
    </button>
  </form>
</div>