import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, StoreMerchant } from '../../_models/store';
import { EditDialogComponent } from '../edit-dialog/edit-dialog.component';
import { StoreService } from '../../_services/store.service';
import { debounceTime, first, takeUntil } from 'rxjs/internal/operators';
import { VerifyPhoneComponent } from '../verify-phone/verify-phone.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { PrinterSettingsService } from 'src/app/_services/printer-settings.service';
import { environment } from 'src/environments/environment';
import { DefaultPosSettingComponent } from '../default-pos-setting/default-pos-setting.component';
import { Subject, Subscription } from 'rxjs';
import { SurchargeDetailPopupComponent } from './surcharge-detail-popup/surcharge-detail-popup.component';
import { CountDownService } from 'src/app/_services/count-down.service';
import { DatePipe } from '@angular/common';
import { HeaderSettingsComponent } from './header-settings/header-settings.component';
import { GiftCardDefaulImageComponent } from './gift-card-defaul-image/gift-card-defaul-image.component';
import { OrderReadySettingsComponent } from './order-ready-settings/order-ready-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderService } from 'src/app/_services/order.service';


@Component({
  selector: 'app-store-settings',
  templateUrl: './store-settings.component.html',
  styleUrls: ['./store-settings.component.scss']
})
export class StoreSettingsComponent implements OnInit, OnDestroy {

  panelOpenState = true;
  defaultPanelOpenState = false;
  error = '';
  disableRipple = true;
  qr_text;
  fileUrl: any;
  imageUrl: any;
  display = false;
  qrDisplay = false;
  defaultOrderOccasion: any;
  defaultTime: any;
  defaultLoad: boolean;
  closedOrders: boolean;
  openOrders: boolean;
  @Input() checked;

  @Input() store: Store;
  @Input() storeMerchant: StoreMerchant;

  qrType = "sms"

  printerSettings;
  printerInterval
  menuTabChangeSub: Subscription;
  idStore: any;
  numericValue: number;
  minutPause: boolean;
  wholeDay: boolean;
  public timerInterval: any;
  dissplayed: any;
  countdownValue;
  countdownFormatted = '';
  private countdownSubscription: Subscription;
  dateObject: Date;
  isLoading: boolean = false;
  updateStoreForCountDown: Subscription;
  giftDefaultImage: any;
  is_giftCardImage: boolean = false;
  selectedAutoManual: any
  clockOutMin: number;
  private forceCLockOut: Subject<any> = new Subject<any>();
  forceSubject: Subscription
  selectedVerticalHorizontal: any;
  disclaimerMessage: SafeHtml;
  selectedDoordashDrive: string;
  private destroySubject: Subject<void> = new Subject();
  terminalList = [];


  constructor(
    public dialog: MatDialog,
    private storeService: StoreService,
    private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private printerService: PrinterSettingsService,
    private _snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private countdownService: CountDownService,
    private datePipe: DatePipe, 
    private orderService: OrderService
  ) {
    if (this.store) {
      this.store.pos_setting['default_order_occasion'] = null;
      this.store.pos_setting['default_time'] = null;
      this.store.pos_setting['default_load'] = false;
      this.store.pos_setting['closed_orders'] = false;
      this.store.pos_setting['open_orders'] = false;
    }

    setTimeout(() => {
      this.updateStoreForCountDown = this.storeService.isStoreChange.pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        if (data) {
          this.store = data.number ? data.store_data : data;
          if (this.store) {
            this.numericValue = this.store.store_pause_time;
            this.clockOutMin = this.store.store_clock_out_time;
            this.minutPause = this.store.pause_store;
            this.wholeDay = this.store.pause_store_whole_day;
            const aa = localStorage.getItem('dayPause');
            const countLocalValue = localStorage.getItem('countdownValue');
            if ((this.store.pause_store == false && this.store.pause_store_whole_day == true) || (this.store.pause_store == true && this.store.pause_store_whole_day == false)) {
              if (countLocalValue) {
                if (aa == 'day_pause' || aa == 'whole_day_pause') {
                  this.stopCountdown();
                  this.countDownTimer();
                }
              } else {
                this.countDownTimer();
              }
            } else {
              if (countLocalValue) {
                this.stopCountdown();
              }
            }
          }

        }
      });
    }, 1000);
  }

  ngOnInit() {
    console.log('Store Setting ts', this.store);
    this.getTerminalList();
    this.idStore = localStorage.getItem('currentStore')
    if (this.store && this.store.pos_setting && this.store.pos_setting["default_time"] === "LATER") {
      this.store.pos_setting["default_time"] = 'Schedule for Later'
    }
    if (this.store && this.store.pos_setting && this.store.pos_setting["pos_setting_id"]) {
      this.getDefaultPosSetting();
    }
    this.getPrinterHeartbeat();
    if (!this.printerInterval) {
      this.printerInterval = setInterval(() => {
        this.getPrinterHeartbeat();
      }, 30000)
    }

    if (this.store) {
      this.numericValue = this.store.store_pause_time;
      this.clockOutMin = this.store.store_clock_out_time;
      this.minutPause = this.store.pause_store;
      this.wholeDay = this.store.pause_store_whole_day;
      this.countDownTimer();
    }
    if (this.store.feature_flag['auto_accept_orders'] == false) {
      this.selectedAutoManual = 'manual';
    } else {
      this.selectedAutoManual = 'auto';
    }

    if (this.store.feature_flag['modifiers_display_hv'] == false) {
      this.selectedVerticalHorizontal = 'vertical';
    } else {
      this.selectedVerticalHorizontal = 'horizontal';
    }

    // DoorDash Drive
    if (this.store.feature_flag['doordash_delivery_drive'] == false) {
      this.selectedDoordashDrive = 'off';
    } else {
      this.selectedDoordashDrive = 'on';
    }
    
    if (this.store.gift_card_default_image) {
      this.giftDefaultImage = this.store.gift_card_default_image;
      this.is_giftCardImage = true;
    } else {
      this.giftDefaultImage = this.store.gift_card_default_image;
      this.is_giftCardImage = false;
    }

    this.forceSubject = this.forceCLockOut.pipe(debounceTime(500)).subscribe((data) => {
      this.forceClockOutTimeUpdate(this.clockOutMin);
    });

    if (this.store) {
      this.disclaimerMessage = this.sanitizer.bypassSecurityTrustHtml(this.store.disclaimer_message);
    }
  }

  ngOnDestroy() {
    if (this.printerInterval) {
      clearInterval(this.printerInterval);
    }

    if (this.updateStoreForCountDown) {
      this.updateStoreForCountDown.unsubscribe();
    }

    this.destroySubject.next();
    this.destroySubject.complete();
  }

  openDialog(setting_data) {
    if (!setting_data.default_load && !setting_data.closed_orders && !setting_data.open_orders && setting_data.default_order_occasion && setting_data.default_time) {
      this._snackBar.open("Please turn on Default Load!", "OK", {
        duration: 3000,
      });
      return
    }
    let dialogRef = this.dialog.open(DefaultPosSettingComponent, {
      'height': 'auto',
      'width': '500px',
      disableClose: true,
      data: {
        'store': this.store,
        'data': setting_data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.pos_setting_id) {
        if (this.store.feature_flag['multiple_default_load']) {
          const index = this.store.pos_setting.findIndex(setting => setting.pos_setting_id === result.pos_setting_id);
          if (index !== -1) {
            // Update the object at the found index with the values from `result`
            this.store.pos_setting[index] = {
              pos_setting_id: result.pos_setting_id,
              default_order_occasion: result.default_order_occasion,
              terminal_id: result.terminal_id,
              default_time: result.default_time,
              default_load: result.default_load,
              closed_orders: result.closed_orders,
              open_orders: result.open_orders
            };
          }
        } else {
          this.store.pos_setting = {
            pos_setting_id: result.pos_setting_id,
            default_order_occasion: result.default_order_occasion,
            terminal_id: result.terminal_id,
            default_time: result.default_time,
            default_load: result.default_load,
            closed_orders: result.closed_orders,
            open_orders: result.open_orders
          }
        }
      }
      else if (result?.closePOP) {

      }
      else {
        this._snackBar.open("Please select default order occasion and default time!", "OK", {
          duration: 3000,
        });
      }

    });
  }

  openDialogSurcharge() {
    let dialogRef = this.dialog.open(SurchargeDetailPopupComponent, {
      'height': '400px',
      'width': '500px',
      disableClose: false,
      data: {
        'store': this.store
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != 0) {
        this.store.surcharge_label = result.surcharge_label,
          this.store.surcharge_percent = result.surcharge_percent,
          this.store.surcharge_text = result.surcharge_text;
      } else { }
    })
  }

  openDialogHeaderMessage() {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(HeaderSettingsComponent, {
      panelClass: 'dial-size',
      width: isMobile ? '100vw' : '500px',
      height: isMobile ? '100vh' : 'auto',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        'store': this.store
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 0 && result != undefined) {
        this.store.disclaimer_message = result.disclaimer_message
        this.store.disclaimer_from_time = result.disclaimer_from_time,
          this.store.disclaimer_to_time = result.disclaimer_to_time
        if (this.store) {
          this.disclaimerMessage = this.sanitizer.bypassSecurityTrustHtml(this.store.disclaimer_message);
        }
      }
    })

  }


  getDefaultPosSetting() {
    if (this.store && this.store.pos_setting && this.store.pos_setting["pos_setting_id"]) {
      this.storeService.getDefaultPosSetting(this.store && this.store.pos_setting && this.store.pos_setting["pos_setting_id"])
        .subscribe(
          data => {

            if (data.default_time === "LATER") {
              data.default_time = 'Schedule for Later'
            }
            this.defaultOrderOccasion = data.default_order_occasion;
            this.defaultTime = data.default_time;
            this.defaultLoad = data.default_load;
            this.closedOrders = data.closed_orders;
            this.openOrders = data.open_orders;

            this.store.pos_setting['closed_orders'] = this.closedOrders;
            this.store.pos_setting['open_orders'] = this.openOrders;
          })
    }
    else {
      this.store.pos_setting['default_order_occasion'] = null;
      this.store.pos_setting['default_time'] = null;
      this.store.pos_setting['default_load'] = false;
      this.store.pos_setting['closed_orders'] = false;
      this.store.pos_setting['open_orders'] = false;
    }

  }

  edit(attributes) {
    const dialogRef = this.dialog.open(EditDialogComponent, { data: { 'attributes': attributes, 'store': this.store }, 'height': '400px', 'width': '500px' } as any);
    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    });
  }

  onToggleChange(event, prop) {
    if (!this.store.pos_setting) {
      this.store.pos_setting = [];
    }

    if (prop == 'default_load') {
      this.store.pos_setting[prop] = event.checked;
    }
    else {
      this.store[prop] = event.checked;
    }
    if (prop == 'closed_orders') {

      this.store.pos_setting[prop] = event.checked;
    }
    else {
      this.store[prop] = event.checked;
    }
    if (prop == 'open_orders') {
      this.store.pos_setting[prop] = event.checked;
    }
    else {
      this.store[prop] = event.checked;
    }

    const newStore: Store = new Store();
    newStore[prop] = this.store[prop];


    if (prop === 'has_card_payment' && !newStore[prop]) {
      newStore['enable_cash_payment'] = true
      this.store['enable_cash_payment'] = true
    }
    if (this.store.pos_setting["default_time"] === "Schedule for Later") {
      this.store.pos_setting["default_time"] = 'LATER'
    }
    const formObject = {
      default_order_occasion: this.store.pos_setting["default_order_occasion"],
      default_time: this.store.pos_setting["default_time"]
    }
    // if(prop === 'default_load'){
    if (prop == 'service_toggle') {
      if (this.store && this.store.pos_setting && this.store?.pos_setting.pos_setting_id) {
        this.storeService.createPosSetting(this.store.pos_setting.pos_setting_id, this.store.id, formObject, this.store.pos_setting.default_load, this.store.pos_setting.closed_orders, this.store.pos_setting.open_orders)
          .subscribe(
            data => {
              this.alertStatusChange(event, prop);
              if (data.default_time === "LATER") {
                this.store.pos_setting.default_time = 'Schedule for Later'
              }
              else {
                this.store.pos_setting.default_time = data.default_time;
              }
              this.store.pos_setting.pos_setting_id = data.id;
            }, error => {
              this.alertStatusChange(event, prop);
              this.store.pos_setting.default_load = this.store[prop];
            })
      } else {
        this.storeService.setDefaultPosSetting(this.store.id, formObject, this.store.pos_setting.default_load, this.store.pos_setting.closed_orders, this.store.pos_setting.open_orders)
          .subscribe(
            data => {
              this.alertStatusChange(event, prop);
              this.store.pos_setting.pos_setting_id = data.id;
              this.store.pos_setting.default_order_occasion = data.default_order_occasion;
              this.store.pos_setting.default_time = data.default_time;
              this.store.pos_setting.default_load = this.store[prop];
            },
            error => {
              this.alertStatusChange(event, prop);
              this.store.pos_setting[prop] = false;
              this._snackBar.open("Please select default order occasion and default time!", "OK", {
                duration: 5000,
              });
            })
      }
    }
    // }

    this.storeService.updateStore(this.store, JSON.stringify(newStore))
      .pipe(first())
      .subscribe(
        data => {
        },
        error => {
          this.error = error;
        }
      );
  }

  onPosSettingToggleChange(event, prop, posSetting_id) {
    if (!this.store.pos_setting) {
      this.store.pos_setting = [];
    }

    let posSettingIndex = this.store?.pos_setting?.findIndex(item => item.pos_setting_id === posSetting_id);
    if (posSettingIndex !== -1) {
      // Update the default_load property of the found object
      this.store.pos_setting[posSettingIndex][prop] = event.checked;
    }
    
    console.log('this.storeffffffff', this.store.pos_setting);
    
  }

  onToggleChangeSecond(event) {
    this.store.disclaimer_show = event.checked;
    const keySend = {
      "disclaimer_show": this.store.disclaimer_show,
      "disclaimer_message": this.store.disclaimer_message,
      "disclaimer_from_time": this.store.disclaimer_from_time,
      "disclaimer_to_time": this.store.disclaimer_to_time
    }
    this.storeService.updateHeaderDisclaimer(this.idStore, keySend).subscribe((data: any) => {
    })
  }

  updateFeatureFlag(property, value) {
    if (property == 'auto_accept_orders') {
      if (value == 'manual') {
        value = false;
      } else {
        value = true;
      }
    }
    if (property == 'modifiers_display_hv') {
      if (value == 'vertical') {
        value = false;
      } else {
        value = true;
      }
    }

    // DoorDash Drive
    if (property == 'doordash_delivery_drive') {
      if (value == 'off') {
        value = false;
      } else {
        value = true;
      }
    }
    
    let payload = {}
    payload[property] = value
    this.storeService.updateStoreFeatureFlag(this.store.id, JSON.stringify(payload))
      .subscribe(data => {
        this.storeService.refresh(this.store);
      })
  }

  startPhoneVerification() {
    const dialogRef = this.dialog.open(VerifyPhoneComponent, { data: { 'store': this.store }, 'height': '400px', 'width': '533px' } as any);
    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    });
  }

  getQRText() {
    if (this.qr_text != '') {
      this.qrDisplay = true;
      this.generateQRImage(`SMSTO:${this.store!.sms_number}:` + this.qr_text)
    }
  }

  getQRValue() {
    if (this.store) {
      if (this.qrType === "sms") {
        this.display = true;
        this.qrDisplay = false;
      } else if (this.qrType === 'url') {
        this.display = false;
        this.generateQRImage(this.store.gmb_menu_url)
        this.qrDisplay = true;
      } else if (this.qrType === 'gift') {
        this.display = false;
        this.generateQRImage(`${environment.orderAppUrl}/store/${this.store.url_slug}/offers`)
        this.qrDisplay = true;
      }
    }
  }

  generateQRImage(value) {
    this.imageUrl = `https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=${encodeURI(value)}`;

    this.toDataURL(`https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=${encodeURI(value)}`, function (dataUrl) {
      this.fileUrl = dataUrl
    }.bind(this)
    )
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  downloadDataurl(dataurl, filename) {
    var a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute("download", filename);
    a.click();
  }

  download(event) {
    this.downloadDataurl(event.target.src, "labrador-qrcode.png")
  }

  getPrinterHeartbeat() {
    this.printerService.getPrinterSettings(this.store.id).subscribe(data => {
      this.printerSettings = data
      console.log('Printer Heart Beat', this.printerSettings);

    })
  }

  parentFunction() {
    console.log('ParentFFuntion');
    this.getPrinterHeartbeat();
  }

  alertStatusChange(event, prop) {
    this.isLoading = true;
    if (prop == 'closed_orders') { } else if (prop == 'open_orders') { } else {
      let val;
      if (prop == 'pause-store') {
        val = !event.checked;
      } else {
        val = event.checked;
      }
      this.storeService.alertStatusChange(this.store.id, val).subscribe(data => {
        this.storeService.refresh(this.store);
        this.isLoading = false;
      });
    }

  }

  onInputChange(event: Event, for_which: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const numericInput = inputValue.replace(/[^0-9]/g, '');
    if (for_which == 'pause') {
      input.value = numericInput;
      this.numericValue = +numericInput;
    } else if (for_which == 'force') {
      this.clockOutMin = +numericInput;
      this.forceCLockOut.next(this.clockOutMin);
    }
  }

  pauseStoreService(e, f) {
    this.isLoading = true;
    if (e == 1) {
      this.wholeDay = f.checked;
      this.minutPause = false;
      this.numericValue = null;
    } else if (e == 2) {
      this.minutPause = f.checked;
      if (!this.minutPause) {
        this.numericValue = null;
      }
      this.wholeDay = false;
    }
    const payload = {
      "pause_store": this.minutPause,
      "pause_store_whole_day": this.wholeDay,
      "store_pause_time": this.numericValue
    }
    this.storeService.pauseStoreService(this.store.id, payload).subscribe((data: any) => {
      this.alertStatusChange(f,'pause-store');
      this.storeService.refreshStorTimer(this.store);
      this.isLoading = false;
    });
  }

  forceClockOutTimeUpdate(e) {
    const payload = {
      "force_clock_out_time": e
    }
    this.storeService.forceClockOutTime(this.store.id, payload).subscribe((data: any) => {
      this.storeService.refreshStorTimer(this.store);
      this.isLoading = false;
    });
  }


  /* COUNT DOWN BEGINS */
  countDownTimer() {
    this.countdownValue = parseInt(localStorage.getItem('countdownValue'), 10);
    if (this.store.pause_store) {
      if (this.countdownValue > 0) {
        const countDownValue = (this.countdownValue / 60);
        this.startCountdown(countDownValue);
        this.subscribeToCountdownChanges();
        this.updateCountdownFormatted();
      } else {
        localStorage.setItem('dayPause', 'day_pause')
        const targetDate = new Date(this.store.store_pause_date_time);
        const currentDate = new Date();
        if (currentDate < targetDate) {
          const timeDifference = Math.abs(currentDate.getTime() - targetDate.getTime());
          const minutesDifference = (timeDifference / (1000 * 60));
          this.countdownService.setInitialCountdownValue(minutesDifference);
          this.subscribeToCountdownChanges();
          this.updateCountdownFormatted();
        }
      }
    } else if (this.store.pause_store_whole_day) {
      if (this.countdownValue > 0) {
        const countDownValue = (this.countdownValue / 60);
        this.startCountdown(countDownValue);
        this.subscribeToCountdownChanges();
        this.updateCountdownFormatted();
      } else {
        localStorage.setItem('dayPause', 'whole_day_pause');
        this.dateObject = new Date(this.store.store_whole_day_pause_date_time.replace('Z', ''));
        const currentDateInStoreTimeZone = new Date().toLocaleString("en-US", { timeZone: this.store.time_zone });
        const currentDate = new Date(currentDateInStoreTimeZone);
        if (currentDate < this.dateObject) {
          const timeDifference = Math.abs(currentDate.getTime() - this.dateObject.getTime());
          const minutesDifference = (timeDifference / (1000 * 60));
          console.log('Minutes DIfference', minutesDifference);
          this.countdownService.setInitialCountdownValue(minutesDifference);
          this.subscribeToCountdownChanges();
          this.updateCountdownFormatted();
        }
      }
    } else {
      localStorage.removeItem('countdownValue');
      this.stopCountdown();
    }
  }

  startCountdown(minutes: number): void {
    this.countdownService.startCountdown(minutes);
    this.countdownValue = this.countdownService.getCountdownValue();
    localStorage.setItem('countdownValue', this.countdownValue.toString());
    this.updateCountdownFormatted();
    this.changeDetectorRef.detectChanges();
  }

  stopCountdown(): void {
    this.countdownService.stopCountdown();
    this.countdownValue = this.countdownService.getCountdownValue();
    localStorage.removeItem('countdownValue');
    this.updateCountdownFormatted();
    this.changeDetectorRef.detectChanges();
  }

  private subscribeToCountdownChanges(): void {
    this.countdownSubscription = this.countdownService.subscribeToChanges().subscribe(() => {
      this.countdownValue = this.countdownService.getCountdownValue();
      this.updateCountdownFormatted();
      this.changeDetectorRef.detectChanges();
      if (this.countdownValue == 3) {
        localStorage.removeItem('countdownValue');
        const payload = {
          "pause_store": false,
          "pause_store_whole_day": false,
          "store_pause_time": null
        }
        this.storeService.pauseStoreService(this.store.id, payload).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
          this.storeService.refresh(this.store);
          this.storeService.refreshStorTimer(this.store);
        });
      }
    });
  }

  private updateCountdownFormatted(): void {
    this.countdownFormatted = this.countdownService.convertToHHMMSS(this.countdownValue);
  }

  convertToSeconds(time: string): number {
    const parts = time.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);
    return hours * 3600 + minutes * 60 + seconds;
  }

  isCountdownGreaterThanZero(): boolean {
    return this.convertToSeconds(this.countdownFormatted) > 0;
  }

  /* COUNT DOWN STOP */
  giftCardDefaultImage() {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(GiftCardDefaulImageComponent, {
      panelClass: 'dial-size',
      width: isMobile ? '100vw' : '500px',
      height: isMobile ? 'auto' : 'auto',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        category: 'gift-image'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        this.storeService.refresh(this.store);
        setTimeout(() => {
          console.log('Store Get Update Store Data', this.store);
          if (this.store.gift_card_default_image) {
            this.giftDefaultImage = this.store.gift_card_default_image;
            this.is_giftCardImage = true;
          } else {
            this.giftDefaultImage = this.store.gift_card_default_image;
            this.is_giftCardImage = false;
          }
        }, 2000)
      }
    })

  }

  /* Order Ready Settings Dialog */
  openOrderReadyDialog() {
    const isMobile = window.innerWidth <= 599;
    let dialogRef = this.dialog.open(OrderReadySettingsComponent, {
      width: isMobile ? '100vw' : '500px',
      height: isMobile ? '100vh' : 'auto',
      maxWidth: isMobile ? '100vw' : '80vw',
      data: {
        store: this.store
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.storeService.refresh(this.store);
    });
  }

  getTerminalList() {
    this.orderService.getTerminalId(this.store.id).subscribe((response) => {
      if (response.length > 0) {
        this.terminalList = response;
      }
    });
  }

  getTerminalName(terminalId: number): string {
    const terminal = this.terminalList.find(t => t.id == terminalId);
    return terminal ? terminal.name : '';
  }

}
