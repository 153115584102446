import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as LogRocket from 'logrocket';

@Injectable()
export class LogRocketInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // Log successful API response
            LogRocket.log(`✅ API Success: ${req.method} ${req.url}`, {
              requestBody: req.body,
              responseBody: event.body,
              status: event.status
            });
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            LogRocket.captureException(error);

            // Log API error in LogRocket
            LogRocket.log(`❌ API Error: ${req.method} ${req.url}`, {
              requestBody: req.body,
              errorMessage: error.message,
              status: error.status
            });

            // Retrieve and log session replay link
            LogRocket.getSessionURL(sessionURL => {
              console.error(`🔁 API Error - Session Replay: ${sessionURL}`);
              LogRocket.log(`🔁 API Error - Session Replay: ${sessionURL}`);
            });
          }
        }
      )
    );
  }
}