import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {NmiSettlementAction, NmiSettlementPaged} from '../_models/nmi-settlement';
import * as moment from 'moment/moment';

@Injectable()
export class NMISettlementService {
  endpoint = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getSettlements(storeId:string, params:any={}) {
    let hparams = new HttpParams();
    console.log(params);
    if (params.start_date) {
      hparams = hparams.set('start_date', moment(params.start_date).format('YYYY-MM-DD'));
    }

    if (params.end_date) {
      hparams = hparams.set('end_date', moment(params.end_date).format('YYYY-MM-DD'));
    }

    if(params.filter){
      hparams = hparams.set('filter', params.filter)
    }
    return this.http.get<NmiSettlementPaged>(
      this.endpoint + `/backend/v2/nmi-settlements/${storeId}/get_settlement_status/`, { params: hparams })
  }

  getPayrocCloudSettlements(storeId: string, params: any = {}) {
    let hparams = new HttpParams();

    console.log("🔍 API Request Params before setting:", params);

    // ✅ Ensure store_id is always passed
    if (storeId) {
      hparams = hparams.set('store_id', storeId);
    }

    // ✅ Ensure page and page_size are always passed
    if (params.page) {
      hparams = hparams.set('page', params.page);
    } else {
      hparams = hparams.set('page', '1'); // Default page
    }

    if (params.page_size) {
      hparams = hparams.set('page_size', params.page_size);
    } else {
      hparams = hparams.set('page_size', '20'); // Default page size
    }

    // ✅ Ensure date filters are correctly formatted
    if (params.start_date) {
      hparams = hparams.set('start_date', moment(params.start_date).format('YYYY-MM-DD'));
    }

    if (params.end_date) {
      hparams = hparams.set('end_date', moment(params.end_date).format('YYYY-MM-DD'));
    }

    if (params.filter) {
      hparams = hparams.set('filter', params.filter);
    }

    console.log("🔍 Final API Params:", hparams.toString());

    return this.http.get(
      this.endpoint + `/backend/pos_and_onlinetransaction_statement_list/`, { params: hparams });
}

  getNextPage(url: string){
    return this.http.get<NmiSettlementPaged>(url)
  }

  getSettlementsAggregate(storeId:string, params:any={}) {
    let hparams = new HttpParams();
    console.log(params);
    if (params.start_date) {
      hparams = hparams.set('start_date', moment(params.start_date).format('YYYY-MM-DD'));
    }

    if (params.end_date) {
      hparams = hparams.set('end_date', moment(params.end_date).format('YYYY-MM-DD'));
    }
    return this.http.get<NmiSettlementPaged>(
      this.endpoint + `/backend/v2/nmi-settlements/${storeId}/get_settlement_aggregate/`, { params: hparams })
  }
}
