import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core';
import { MenuGroupItem, MenuItemGroup } from '../../_models/order';
import { EditItemDialogComponent } from '../edit-item-dialog/edit-item-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Permission } from 'src/app/_models/permission';
import { takeUntil } from 'rxjs/operators';
import { PinConfirmComponent } from '../pin-confirm/pin-confirm.component';
import { Subject } from 'rxjs';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { PermissionService } from 'src/app/_services/permission.service';
import { StoreService } from 'src/app/_services/store.service';
import { Store } from 'src/app/_models/store';

@Component({
  selector: "app-order-item-grid",
  templateUrl: "./order-item-grid.component.html",
  styleUrls: ["./order-item-grid.component.scss"],
})
export class OrderItemGridComponent implements OnInit {
  @Input() validUserData = "";
  @Input() items: MenuGroupItem[] | MenuItemGroup[];
  @Input() selected: string;
  @Input() storeDynamivHeightFeatureFlag: boolean;
  @Input() isSearch: boolean;

  @Output() onSelectHandler: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isStoreUndef();
  }
  breakpoint: number;
  heightBox: any;
  orders: any;
  divHeight: string;
  innerHeightAA: string;
  pressTimer: any;
  pressHoldDuration = 1000;
  permission: Permission;
  private destroySubject: Subject<void> = new Subject();

  maxHeightItem: number = 0;
  buttonHeight: number = 0;
  maxRowsPerPage: number = 0;
  itemsPerPage: number = 0;
  currentPage: number = 0;
  totalPages: number = 0;
  displayedCategories: any[] = [];
  totalPagesArray: number[] = [];
  touchStartX: number = 0;
  touchEndX: number = 0;
  mouseStartX: number = 0;
  mouseEndX: number = 0;
  isMouseDown: boolean = false;
  SWIPE_THRESHOLD: number = 50;
  @ViewChild('swipeContainer', { static: false }) swipeContainer!: ElementRef;
  store: Store;
  constructor(private dialog: MatDialog, private permissionService: PermissionService, private storeService: StoreService) { }

  ngOnInit() {
    this.permissionService.pinCurrentOperator.pipe(takeUntil
      (this.destroySubject)).subscribe((permission) => {
        this.permission = permission;
      });


    this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe((store) => {
      if (store) {
        this.store = Object.assign(new Store(), store);
      }
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['items'] && changes['items'].currentValue) {
      this.isStoreUndef();
    }
  }

  onMouseDon(item: any) {
    this.pressTimer = setTimeout(() => {
      this.openEditItemDialog(item);
    }, this.pressHoldDuration);
  }

  onMoseUp() {
    clearTimeout(this.pressTimer);
  }

  onMouseLeave() {
    clearTimeout(this.pressTimer);
  }

  ngAfterViewInit() {
    if (!this.isSearch && this.storeDynamivHeightFeatureFlag) {
      const container = this.swipeContainer?.nativeElement;
      container.addEventListener('touchstart', this.onTouchStart.bind(this), false);
      container.addEventListener('touchend', this.onTouchEnd.bind(this), false);
      /* SWIPE FOR NON TOUCH DEVICE */
      // container.addEventListener('mousedown', this.onMouseDown.bind(this), false);
      // container.addEventListener('mousemove', this.onMouseMove.bind(this), false);
      // container.addEventListener('mouseup', this.onMouseUp.bind(this), false);
    }

  }

  openEditItemDialog(item: any) {
    if (this.permission.hasPermission('ACCESS', 'MENU')) {
      this.editItem(item);
    } else {
      const isMobile = window.innerWidth <= 599;
      const dialogRef = this.dialog.open(PinConfirmComponent, {
        width: isMobile ? '100vw' : '500px',
        height: isMobile ? '100vh' : '410px',
        maxWidth: isMobile ? '100vw' : '80vw',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(resultPer => {
        if (resultPer && resultPer.permission) {
          this.permission = resultPer['permission'];
          if (this.permission.hasPermission('ACCESS', 'MENU')) {
            this.editItem(item);
          } else {
            this.alertPopup('', 'This User/Manager does not have the required permission to perform this operation!');
          }
        }
      });
    }
  }

  editItem(item) {
    const dialogRef = this.dialog.open(EditItemDialogComponent, {
      data: item,
      maxWidth: '100vw',
      width: '100vw',
      height: '100vh',
      panelClass: 'full-screen-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
    });
  }

  alertPopup(name, msg) {
    let dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      minHeight: '20vh',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      // if (result) {
      //   if (name == 'process_payment') {
      //     this.ngOnInit();
      //   }
      // }
    });
  }

  isStoreUndef() {
    if (!this.store) {
      this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe((store) => {
        if (store) {
          this.store = Object.assign(new Store(), store);
          this.updateMaxHeight();
        }
      });
    } else {
      this.updateMaxHeight();
    }

  }

  updateMaxHeight() {
    if (this.isSearch) {
      this.maxHeightItem = window.innerHeight - 280;
    } else {
      if (this.storeDynamivHeightFeatureFlag) {
        this.maxHeightItem = window.innerHeight - 300;
        this.buttonHeight = this.getButtonHeight();
        this.calculatePagination();
      } else {
        this.maxHeightItem = window.innerHeight - 280;
      }
    }
  }

  getColumnsCount(): number {
    const width = window.innerWidth;
    if (width >= 1536) return 6;
    else if (width >= 1280) return 5;
    else if (width >= 1024) return 5;
    else if (width >= 768) return 5;
    else return 3;
  }

  getButtonHeight(): number {
    const containerHeight = this.maxHeightItem;
    const rows = Math.max(2, Math.floor(containerHeight / 120));
    return Math.floor(containerHeight / rows);
  }

  updateDisplayedCategories() {
    const start = this.currentPage * this.itemsPerPage;
    this.displayedCategories = this.items.slice(start, start + this.itemsPerPage);
  }

  getPaginatedCategories() {
    if (!this.items || this.items.length === 0) {
      return [];
    }

    const start = this.currentPage * this.itemsPerPage;
    return this.items.slice(start, start + this.itemsPerPage);
  }

  getHeightRange() {
    const width = window.innerWidth;
    if (width >= 1536) return { minHeight: 150, maxHeight: 240 };
    if (width >= 1280) return { minHeight: 150, maxHeight: 220 };
    if (width >= 1024) return { minHeight: 120, maxHeight: 190 };
    if (width >= 768) return { minHeight: 100, maxHeight: 180 };
    return { minHeight: 90, maxHeight: 150 };
  }

  calculatePagination() {
    if (!this.items || this.items.length === 0) {
      console.warn("items not loaded yet, skipping pagination calculation.");
      return;
    }

    const containerHeight = this.maxHeightItem - 20;
    const columns = this.getColumnsCount();
    let { minHeight, maxHeight } = this.getHeightRange();
    let buttonHeight = this.getButtonHeight();
    let bestHeight = buttonHeight;
    let fitsBetter = false;
    while (buttonHeight >= minHeight) {
      let testRows = Math.floor(containerHeight / buttonHeight);
      let testTotalItemsPerPage = testRows * columns;

      if (testTotalItemsPerPage >= this.items.length) {
        bestHeight = buttonHeight;
        fitsBetter = true;
        break;
      }

      buttonHeight -= 2;
    }

    this.buttonHeight = fitsBetter ? bestHeight : Math.min(maxHeight, containerHeight / 3);

    let maxRowsThatFit = Math.floor(containerHeight / this.buttonHeight);
    if (maxRowsThatFit < 2) {
      maxRowsThatFit = 2;
      this.buttonHeight = containerHeight / maxRowsThatFit;
    }

    if (this.buttonHeight < 80) {
      this.buttonHeight = 80;
    }

    this.itemsPerPage = Math.max(1, maxRowsThatFit * columns);
    this.totalPages = Math.max(1, Math.ceil(this.items.length / this.itemsPerPage));
    this.totalPagesArray = Array.from({ length: this.totalPages }, (_, index) => index);
    this.currentPage = Math.min(this.currentPage, this.totalPages - 1);

    this.updateDisplayedCategories();
  }



  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].clientX;
  }

  onTouchEnd(event: TouchEvent) {
    this.touchEndX = event.changedTouches[0].clientX;
    this.detectSwipe(this.touchStartX, this.touchEndX);
  }

  /* SWIPE FOR NON TOUCH DEVICE */
  // onMouseDown(event: MouseEvent) {
  //   this.isMouseDown = true;
  //   this.mouseStartX = event.clientX;
  // }

  // onMouseMove(event: MouseEvent) {
  //   if (this.isMouseDown) {
  //     this.mouseEndX = event.clientX;
  //   }
  // }

  // onMouseUp(event: MouseEvent) {
  //   this.isMouseDown = false;
  //   if (this.mouseEndX) {
  //     this.detectSwipe(this.mouseStartX, this.mouseEndX);
  //   }
  // }

  detectSwipe(startX: number, endX: number) {
    console.log('DETECT SWIPE', startX, endX);

    const swipeDistance = startX - endX;
    if (swipeDistance > this.SWIPE_THRESHOLD) {
      this.goToNextPage();
    } else if (swipeDistance < -this.SWIPE_THRESHOLD) {
      this.goToPrevPage();
    }
  }

  goToPage(pageIndex: number) {
    if (pageIndex >= 0 && pageIndex < this.totalPages) {
      this.currentPage = pageIndex;
      this.updateDisplayedCategories();
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.updateDisplayedCategories();
    }
  }

  goToPrevPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.updateDisplayedCategories();
    }
  }


}
