import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, Inject, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { OrdersWithPayment } from 'src/app/_models/order';
import { OrderService } from '../../_services/order.service';
import { UntypedFormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { CheckoutDialogComponent } from '../checkout-dialog/checkout-dialog.component';
import { Store } from 'src/app/_models/store';
import { TipDialogComponent } from '../tip-dialog/tip-dialog.component';
import { CardPaymentConsumerChoiceComponent } from '../card-payment-consumer-choice/card-payment-consumer-choice.component';
import { OrderHelpers } from 'src/app/_helpers/helpers';
import { CustomTipComponent } from '../tip-dialog/custom-tip/custom-tip.component';
import { CardPaymentMqttComponent } from '../card-payment-mqtt/card-payment-mqtt.component';
import { FrontFacingMqttService } from 'src/app/_services/front-facing-mqtt.service';
import { MatStepper } from '@angular/material/stepper';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-payment-options-dialog',
  templateUrl: './payment-options-dialog.component.html',
  styleUrls: ['./payment-options-dialog.component.scss']
})
export class PaymentOptionsDialogComponent implements OnInit, OnDestroy {
  @Output() closeOverlay = new EventEmitter<boolean>();
  @Output() closeOpenOrderOverlay = new EventEmitter<boolean>();
  @Output() closeDeliveryTabOverlay = new EventEmitter<boolean>();
  orderWithPayment: OrdersWithPayment;
  card = 'card';
  cash = 'cash';
  orderInstruction: string;
  public form: UntypedFormGroup;
  private unsubscribe: Subject<null> = new Subject();
  orderName: string;
  terminalId = this.data.terminalId;
  store: Store;
  isProcessingPayment: boolean = false;
  subTotal = this.data.subTotal;
  taxDueTotal = this.data.orderWithPayment.payment.tax + this.subTotal;
  tipTenPercent = Math.round((this.subTotal * 18) / 100);
  tipFifteenPercent = Math.round((this.subTotal * 20) / 100);
  tipTwentyPercent = Math.round((this.subTotal * 25) / 100);
  tips = [
    { name: 'No Tip', value: 0, amount: 0 },
    { name: '18%', value: 18, amount: this.tipTenPercent },
    { name: '20%', value: 20, amount: this.tipFifteenPercent },
    { name: '25%', value: 25, amount: this.tipTwentyPercent },
  ];
  tipPercent;
  tipAmount;
  orderHash = this.data.orderWithPayment.bot_order.order_hash;

  selectedPayment;
  selectedTip;
  selectedTipPercent;
  tipSelected = false;
  paymentModeSub: Subscription;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  amountWithoutTip: number = 0;
  tipcondition: any;

  private destroySubject: Subject<void> = new Subject();
  private overlayRef!: OverlayRef;
  closeOverlsaySub: Subscription;
  storeid: any;


  constructor(
    public dialog: MatDialog,
    private orderService: OrderService,
    private mq: FrontFacingMqttService,
    private chRef: ChangeDetectorRef,
    @Inject('OVERLAY_DATA') public data: any,
    private overlay: Overlay,
    private injector: Injector) {
    console.log('Payment options dialog', this.data);

    this.orderWithPayment = data.orderWithPayment;
    this.store = this.orderWithPayment.store;
    // dialogRef.disableClose = true;

    if (this.orderWithPayment.payment.tip != null) {
      this.amountWithoutTip = this.orderWithPayment.payment.tip;
      this.tipcondition = 0;
    } else {
      this.amountWithoutTip = 0;
    }

  }

  ngOnInit() {
    this.tipPercent = this.orderWithPayment.getTipPercent();
    this.storeid = localStorage.getItem('currentStore');

    if (this.orderWithPayment.payment.tip != 0) {
      if (Number(this.orderWithPayment.bot_order.tip_percent) == 18) {
        this.selectTip(this.tips[1], 0);
      } else if (Number(this.orderWithPayment.bot_order.tip_percent) == 20) {
        this.selectTip(this.tips[2], 0);
      } else if (Number(this.orderWithPayment.bot_order.tip_percent) == 25) {
        this.selectTip(this.tips[3], 0);
      } else if (Number(this.orderWithPayment.bot_order.tip_percent) == 1) {
        const a = { name: 'Custom', value: 'custom', amount: this.orderWithPayment.payment.tip };
        this.selectTip(a, 0);
      }
    }

    this.paymentModeSub = this.mq.paymentMode.pipe(takeUntil(this.destroySubject)).subscribe(data => {
      console.log('IDHAR AYA KI NHI', data);
      this.openOnCondition(data);
    });

    this.closeOverlsaySub = this.orderService.closePreviousOverlays.pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (data) {
        this.orderService.closePreviousOverlays.next(false);
        if (this.data.eventsource === 'open_orders') {
          this.closeOpenOrderOverlay.emit(false);
        } else if (this.data.eventsource === 'delivery-tab') {
          this.closeDeliveryTabOverlay.emit(false);
        } else {
          this.closeOverlay.emit(false);
        }
      }
    })
  }

  openOnCondition(data) {
    if (data.type === 'paymentTypeCash') {
      this.openCheckoutDialog(0);
    } else if (data.type === 'paymentTypeCard') {
      const isLdIn = localStorage.getItem('isIn') === 'true';
      console.log('isLoggedIn', isLdIn);
      if (!isLdIn) {
        localStorage.setItem('isIn', 'true');
        this.openCardPaymentDialog();
      }
    }
  }


  selectTip(tip, e) {
    console.log(tip);
    this.selectedTip = tip;
    this.tipSelected = true;
    this.tipPercent = tip.value;
    this.tipcondition = 1;
    this.chRef.detectChanges();
  }

  back() {
    if (this.data.openOrder) {
      this.mq.refreshOpenOrder();
    }
    console.log('kkkkkkkkkkkkkkkkk', this.data);
    
    if (this.data.eventsource === 'open_orders') {
      this.closeOpenOrderOverlay.emit(false);
    } else if (this.data.eventsource === 'delivery-tab') {
      this.closeDeliveryTabOverlay.emit(false);
    } else {
      this.closeOverlay.emit(false);
    }
  }

  calculateTip(change: any) {
    if (change.name === 'Custom') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialog.closeAll();
      const isMobile = window.innerWidth <= 470;
      const dialogRef = this.dialog.open(CustomTipComponent, {
        // width: '630px', height: '290px',
        width: isMobile ? '100vw' : '630px',
        height: isMobile ? 'auto' : '290px',
        maxWidth: isMobile ? '100vw' : '',
        data: this.data, panelClass: 'trend-dialog'
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        this.refreshOrder();
        this.chRef.detectChanges();
      });
    } else {
      this.orderWithPayment.updateTip(change.value);
      this.tipAmount = this.orderWithPayment.payment.tip;
      this.orderService.updateTip(this.orderHash, change.value, 'payment-Options-calculate').pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        // this.refresh.emit(true);
        this.tipPercent = data.tip_percent;
      });
    }
  }


  cancelOrder() {
    this.orderWithPayment = null;
    this.orderName = null;
    if (this.form) {
      this.form.controls.orderName.setValue(null);
      this.form.controls.phoneNumber.setValue(null);
      this.form.reset();
    }
    this.orderService.orderDoneSubject.next();
  }

  selectPayment(paymentType: string) {
    if (this.isProcessingPayment) return;
    this.isProcessingPayment = true;
    this.mq.setPaymentMode({ paymentMode: paymentType });
    this.selectedPayment = paymentType;
    // this.stepper.next();
    this.openCardPaymentDialog();


    if (!this.store.enable_consumer_choice_tip) {
      console.log('goto payment');
      this.onPay();
    }
    setTimeout(() => {
      this.isProcessingPayment = false;
    }, 5000);
  }

  openCheckoutDialog(e) {
    const orderHash = localStorage.getItem('order_hash');
    if (e) {
      this.mq.setPaymentMode({
        paymentMode: 'cash'
      });
    }
    const isMobile = window.innerWidth <= 470;
    this.openOverlay(CheckoutDialogComponent, 'onpay');
  }

  goBack() {
    this.orderWithPayment.payment.due_total = this.orderWithPayment.payment.due_total - this.orderWithPayment.payment.tip;
    this.orderWithPayment.updateTipCustom(0);
    this.dialog.closeAll();
  }

  onPay() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let dialogRef = null;
    let tipObs = null;

    if (this.selectedTip?.value) {

      tipObs = this.orderService.updateTip(this.orderHash, this.selectedTip.value, 'payment-Options-onPay');
    } else {
      tipObs = this.orderService.updateCustomTip(this.orderHash, this.selectedTip?.amount, 'payment-Options-onPay-custom');
    }

    tipObs.pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
        this.data.amount = this.orderWithPayment.getCardChargeWithTip(this.selectedTip.amount || 0);
        this.openOverlay(CardPaymentMqttComponent, 'onpay');
      } else {
        this.data.toCharge = this.orderWithPayment.getCardChargeWithTip(this.selectedTip.amount || 0);
        this.openOverlay(CardPaymentConsumerChoiceComponent, 'onpay');
      }
    });
  }


  openCardPaymentDialog() {
    if (this.store.enable_consumer_choice_tip) {
      this.openOverlay(TipDialogComponent, '');
    } else {
  
      if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
        this.openOverlay(CardPaymentMqttComponent, '');
      } else {
        this.openOverlay(CardPaymentConsumerChoiceComponent, '');
      }
    }
  }

  refreshOrder() {
    this.orderService.getOrder(this.orderHash).pipe(takeUntil(this.destroySubject))
      .subscribe((result) => {
        this.orderWithPayment = OrderHelpers.parseOrderwithPayment(result);
        this.store = this.orderWithPayment.store;
      });
  }

  /* Overlay Open */
  openOverlay(component, e) {
    if (this.overlayRef) {
      this.closeLay();
    }
    if (!this.store.enable_consumer_choice_tip && component != CheckoutDialogComponent) {
      // if (this.data.eventsource === 'open_orders') {
      //   this.closeOpenOrderOverlay.emit(false);
      // } else if (this.data.eventsource === 'delivery-tab') {
      //   this.closeDeliveryTabOverlay.emit(false);
      // } else {
      //   this.closeOverlay.emit(false);
      // }
    }
    let overlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
    };
    if (component === CheckoutDialogComponent) {
      overlayConfig = { ...overlayConfig, positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(), };
    }

    this.overlayRef = this.overlay.create(overlayConfig);

    const injector = this.createInjector(component === CheckoutDialogComponent ? {
      orderWithPayment: this.orderWithPayment,
      terminalId: this.terminalId,
      total: this.orderWithPayment.payment.total,
      cashTotal: this.orderWithPayment.payment.cash_due_total,
      subTotal: this.orderWithPayment.payment.sub_total,
    } : this.data);

    const portal = new ComponentPortal(component, null, injector);
    const componentRef: ComponentRef<any> = this.overlayRef.attach(portal);

    componentRef.instance.closeMultiOverlay?.pipe(takeUntil(this.destroySubject)).subscribe((isComplete: any) => {
      console.log('PAYMENT OPTION DIALOG closeMultiOverlay',isComplete);
      localStorage.removeItem('isIn');
      if (isComplete) {
        if (e === 'onpay') {
          this.cancelOrder();
          this.chRef.detectChanges();
          if (this.data.eventsource === 'open_orders') {
            this.closeOpenOrderOverlay.emit(false);
          } else if (this.data.eventsource === 'delivery-tab') {
            this.closeDeliveryTabOverlay.emit(false);
          } else {
            this.closeOverlay.emit(false);
          }
        } else {
          this.cancelOrder();
        }
        this.closeLay();
        this.closeOverlay.emit(false);

      } else {
        this.closeLay();
      }
    });
  }

  closeLay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  closeAllLay(): void {
    console.log('Closing overlay:', this.overlayRef);

    if (this.overlayRef?.hasAttached()) {
      console.log('Detaching overlay...');
      this.overlayRef.detach(); // ✅ Ensure component is detached
    }

    if (this.overlayRef) {
      console.log('Disposing overlay...');
      this.overlayRef.dispose();
      this.overlayRef = null; // ✅ Remove reference to prevent re-use issues
      this.chRef.detectChanges(); // ✅ Ensure UI updates
      console.log('Overlay closed.');
    }

    // ✅ Manually clear overlay container if necessary
    const overlayContainers = document.querySelectorAll('.cdk-overlay-container');
    overlayContainers.forEach(container => {
      container.innerHTML = ''; // ✅ Clear any leftover overlay elements
    });
  }

  private createInjector(data: any): Injector {
    return Injector.create({
      providers: [{ provide: 'OVERLAY_DATA', useValue: data }, { provide: OverlayRef, useValue: this.overlayRef },],
      parent: this.injector,
    });
  }

  ngOnDestroy() {
    
    this.destroySubject.next();
    if (this.paymentModeSub) {
      this.paymentModeSub.unsubscribe();
    }
    if (this.closeOverlsaySub) {
      this.closeOverlsaySub.unsubscribe();
    }
    this.stepper.reset();
    this.orderWithPayment = null;
    this.mq.resetPaymentMode();
    this.mq.resetTip();
    console.log('NG ON DESTROY PaymentOptionsDialogComponent');
  }
}
