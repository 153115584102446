import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { OpenOrderToggleService } from 'src/app/_services/open-order-toggle.service';
import { POSService } from 'src/app/_services/pos-service';
import { RefreshService } from 'src/app/_services/refresh.service';
import { OrderService } from '../../_services/order.service';
import { Store } from 'src/app/_models/store';
import { FrontFacingMqttService } from 'src/app/_services/front-facing-mqtt.service';
import { POSSummaryToggleService } from 'src/app/_services/pos-summary-toggle.service';
import { POSMenuTabChangeService } from 'src/app/_services/pos-menu-tab-change.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { FloorPlanService } from 'src/app/_services/floor-plan.service';
import { CallerIdService } from 'src/app/_services/caller-id.service';
import { OverlayRef } from '@angular/cdk/overlay';


@Component({
  selector: 'app-cash-payment',
  templateUrl: './cash-payment.component.html',
  styleUrls: ['./cash-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CashPaymentComponent implements OnInit, OnDestroy {
  @Output() closeCheckoutOverlay = new EventEmitter<any>();
  @Output() closeSplitMultiOverlay = new EventEmitter<any>();
  @Output() closeGiftOverlay = new EventEmitter<any>();
  public calculatedTotal = this.data.result - this.data.cashDueTotal;
  public absTotal = Math.abs(this.calculatedTotal);
  public showContent = false;
  public unsubscribe: Subject<null> = new Subject<null>();
  result;
  isLoading = true;
  terminalId;
  posLoggedUser;
  terminalName;
  pinUser: string;
  totalDueAmount: any;
  store: Store;
  manualData: any;
  private overlayRef!: OverlayRef;
  constructor(
    public orderService: OrderService,
    private posService: POSService,
    private refreshService: RefreshService,
    private openOrderSummaryToggleService: OpenOrderToggleService,
    public dialog: MatDialog,
    private mq: FrontFacingMqttService,
    private posSummaryToggleService: POSSummaryToggleService,
    private tabChangeService: POSMenuTabChangeService,
    private floorPlanService: FloorPlanService,
    private callerIdService: CallerIdService,
    @Inject('OVERLAY_DATA') public data: any,
  ) { }

  ngOnInit() {

    console.log('ddddddddddddddddddddddd', this.data);


    const orderHash = localStorage.getItem('order_hash');
    const orderWithPayment = this.data.orderWithPayment;
    this.terminalId = localStorage.getItem('selectedTerminal');
    this.posLoggedUser = localStorage.getItem('posLoggedUser');
    this.terminalName = localStorage.getItem('selectedTerminalName');
    if (this.data.split_type == 'GIFT' || this.data.split_type == 'KEYED') {
      this.totalDueAmount = Number(this.data.totalDueAmount) - Number(this.data.cashTotal);
    }
    this.pinUser = localStorage.getItem('pinUser');
    const payload = {
      cash_total: this.data.cashDueTotal.toString(),
      terminal_id: this.terminalName,
      terminal_name: this.terminalName,
      cashier_id: this.posLoggedUser,
      change_due: this.absTotal,
      payment_type: this.data.split_type,
      split_id: this.data.split_id,
      split_amount: this.data.cashDueTotal,
      pin_user: this.pinUser,
      is_gift_split: this.data.from === 'gift-partial-payment' ? true : false
    };

    if (this.data.is_split) {
      this.orderService.processSplit(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
        this.result = result;
        this.isLoading = false;
        if (this.data?.payment_type == "CASH") {
          this.openDrawer();
        }

      });
    } else if (this.data.is_other) {
      this.orderService.isCashCLose.next(false);
      this.orderService.closeSplitPayment(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
        this.result = result;
        this.isLoading = false;
        this.orderService.isCashCLose.next(true);
        this.openDrawer();

      });
    } else {
      this.orderService.isCashCLose.next(false);
      if (!orderWithPayment.bot_order.driver_id) {
        this.openDrawer();
      }
      this.orderService.closeCashOrder(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.unsubscribe))
        .subscribe((result) => {
          if (this.data.from === 'gift-partial-payment') {
            this.giftPartialCash(result);
          } else {
            this.closeOrder(result);
          }

        });
    }

    this.store = this.data.orderWithPayment.store;

    this.manualData = {
      value: 1,
      order_hash: this.data.orderWithPayment.bot_order.order_hash,
      payment_cash: (this.data.is_split || this.data.is_other) ? false : true
    }
    setTimeout(() => this.showContent = true, 1000);
  }

  closeOrder(r) {
    this.result = r;
    this.isLoading = false;
    this.orderService.isCashCLose.next(true);
    localStorage.removeItem('order_hash');
    this.openOrderSummaryToggleService.toggle('CLOSE');
  }

  giftPartialCash(r) {
    const orderWithPayment = this.data.orderWithPayment;
    const payload = {
      terminal_id: localStorage.getItem('selectedTerminalName'),
      terminal_name: localStorage.getItem('selectedTerminalName'),
      cashier_id: localStorage.getItem('posLoggedUser'),
      amount: this.data.card_amnt,
      pin_user: localStorage.getItem('pinUser'),
      card_id: this.data.card_id
    };
    this.orderService.closeGiftCardOrder(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        if (result) {
          this.closeOrder(r);
          // this.dialogRef.close(true);
          // if (this.store.feature_flag['manual_print_standard_checkout']) {
          //   // this.manualReceiptOptions();
          // }
        }
      });
  }

  doneClick() {
    const send = {
      key: 'Done',
      value: true
    }
    if (!this.data.is_split) {
      localStorage.removeItem('setOccasionDetail');
      const orderWithPayment = this.data.orderWithPayment;
      orderWithPayment.bot_order.is_closed = true;
      // this.dialogRef.close(true);
      this.orderService.orderDoneSubject.next();
      this.refreshService.refreshModule('OPEN_TAB');
      this.mq.publishPaymentDone();
      this.posSummaryToggleService.toggle('CLOSE');
      this.tabChangeService.changeTab(0);
      this.orderService.keyboardValueSet.next(false);
      this.floorPlanService.floorCreatedUpdate.next(false);
      this.callerIdService.setPhoneNumbertoOrderAndDelivery.next(null);
      this.closeLay(send);

    } else {
      // this.dialogRef.close(true);
      this.closeLay(send);
    }

  }

  openDrawer() {
    console.log('Open drawer cash checkout');
    const terminal_id = localStorage.getItem('selectedTerminal');
    this.posService.openCashDrawer(terminal_id).pipe(takeUntil(this.unsubscribe)).subscribe(
      (data) => {
        console.log('drawer opened');
        this.orderService.errorMessage('Cash drawer opened successfully', 'OK', 1000);
      },
      (error) => {
        this.orderService.errorMessage('Unable to open cash drawer', 'OK', 2000);
      }
    );
  }

  closeLay(e): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    if (this.data.eventsource === 'gift-cd') {
      this.closeGiftOverlay.emit(e);
    } else if (this.data.eventsource === 'split-cash') {
      this.closeSplitMultiOverlay.emit(e)
    } else {
      this.closeCheckoutOverlay.emit(e);
    }

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    console.log('NGON DESTROY CASH PAYMENT COMPONENT');
  }
}
