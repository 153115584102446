<span *ngIf="categoryContent.is_value == 0">
  <form name="addCategoryForm" [formGroup]="addCategoryForm" (ngSubmit)="addMasterCategorySubmit()" class="fixActionRow">
    <h1 mat-dialog-title>Add Master Category</h1>
    <div mat-dialog-content style="overflow: hidden;">
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Display Name</mat-label>
            <input matInput placeholder="Name" formControlName="name">
          </mat-form-field>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Priority</mat-label>
            <input matInput placeholder="" type="number" formControlName="priority">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <!-- <div class="input-gap" style="width: 50%;">
          <mat-form-field style="width: 100%;">
            <mat-label>Category</mat-label>
            <mat-select formControlName="category" multiple>
              <mat-option *ngFor="let topping of category_list_data" [value]="topping.id">
                {{topping.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->


        <div class="input-gap" style="width: 50%;">
          <mat-form-field style="width: 100%;">
            <mat-label>Master Categories</mat-label>

            <mat-select formControlName="category" multiple>
              <div class="search-filter-input" style="font-size: 15px;">
                <mat-form-field fxFlex="100" style="padding: 0px 10px 10px 10px;">
                  <mat-label>Search For Master Categories</mat-label>
                  <input matInput [formControl]="searchControl" placeholder="Search">
                  <mat-icon style="padding: 2px !important;" class="admin-icons" matPrefix>search</mat-icon>
                </mat-form-field>
              </div>
              <mat-option (click)="toggleAllSelection1()" [value]="null">
                {{ allSelected ? 'Deselect All' : 'Select All' }}
              </mat-option>
              <mat-option *ngFor="let topping of category_list_data | filter: searchControl.value" [value]="topping.id">
                <!-- {{topping.display_name}} -->
                <mat-checkbox
                  [checked]="searchControl.value.includes(topping.id) || category.value.includes(topping.id)"
                  (change)="toggleSelection1(topping.id)">{{ topping.name }}</mat-checkbox>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>




        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Font-Color</mat-label>
            <input matInput placeholder="" type="text" formControlName="font_color">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4 xs:!block" style="width: 100%;">
        <div class="input-gap" style="width: 100%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Emoji</mat-label>
            <mat-select formControlName="emoji">
              <div class="search-filter-input" style="font-size: 15px;">
                <mat-form-field fxFlex="100" style="padding: 0px 10px 10px 10px;">
                  <mat-label>Search For Emoji</mat-label>
                  <input matInput [formControl]="searchControl" placeholder="Search">
                  <mat-icon class="admin-icons" matPrefix>search</mat-icon>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let state of emoji | filter: searchControl.value" [value]="state.value">
                <img style="float: left; margin-right: 10px; width: 30px; height: 30px; max-width: 30px;" class="example-option-img" aria-hidden [src]="state.value" height="15">
                <span style="line-height: 35px;">{{state.name}}</span>
              </mat-option>
            </mat-select>
            <!-- <input matInput aria-label="Emoji" [matAutocomplete]="auto" formControlName="emoji"> -->
            <!-- <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let state of emoji" [value]="state.value">
                <img class="example-option-img" aria-hidden [src]="state.value" height="25">
                <span>{{state.name}}</span>
              </mat-option>
            </mat-autocomplete> -->
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-checkbox (change)="newScreenCheck($event)" [checked]="screen">New screen</mat-checkbox>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-checkbox (change)="hideCategoryCheck($event)" [checked]="hideCategory">Hide category</mat-checkbox>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
    </mat-dialog-actions>
  </form>
</span>



<span *ngIf="categoryContent.is_value == 1">
  <form name="addCategoryForm" [formGroup]="addCategoryForm" (ngSubmit)="addCategorySubmit()" class="fixActionRow">
    <h1 mat-dialog-title>Add Category</h1>
    <div mat-dialog-content style="overflow: hidden;">
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="name">
          </mat-form-field>
        </div>
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Priority</mat-label>
            <input matInput placeholder="" type="number" formControlName="priority">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>SubCategory</mat-label>
            <mat-select formControlName="subcategory" multiple>
              <div class="search-filter-input" style="font-size: 15px;">
                <mat-form-field fxFlex="100" style="padding: 0px 10px 10px 10px;">
                  <mat-label style="font-size: 14px;">Search For Sub Category</mat-label>
                  <input matInput [formControl]="searchControl" placeholder="Search">
                  <mat-icon style="padding: 2px !important;" class="admin-icons" matPrefix>search</mat-icon>
                </mat-form-field>
              </div>
              <mat-option (click)="toggleAllSelection()" [value]="null">
                {{ allSelected ? 'Deselect All' : 'Select All' }}
              </mat-option>
              <mat-option *ngFor="let topping of subcategory_list_data | filter: searchControl.value"
                [value]="topping.id">
                <!-- {{topping.display_name}} -->
                <mat-checkbox
                  [checked]="searchControl.value.includes(topping.id) || subcategory.value.includes(topping.id)"
                  (change)="toggleSelection(topping.id)">{{ topping.display_name }}</mat-checkbox>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>




        <div class="input-gap" style="width: 50%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Font-color</mat-label>
            <input matInput placeholder="" type="text" formControlName="font_color">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4 xs:!block" style="width: 100%;">
        <div class="input-gap" style="width: 100%;">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Emoji</mat-label>
            <mat-select formControlName="emoji">
              <div class="search-filter-input" style="font-size: 15px;">
                <mat-form-field fxFlex="100" style="padding: 0px 10px 10px 10px;">
                  <mat-label>Search For Emoji</mat-label>
                  <input matInput [formControl]="searchControl" placeholder="Search">
                  <mat-icon class="admin-icons" matPrefix>search</mat-icon>
                </mat-form-field>
              </div>
              <mat-option *ngFor="let state of emoji | filter: searchControl.value" [value]="state.value">
                <img style="float: left; margin-right: 10px; width: 30px; height: 30px; max-width: 30px;" class="example-option-img" aria-hidden [src]="state.value" height="15">
                <span style="line-height: 35px;">{{state.name}}</span>
              </mat-option>
            </mat-select>
            <!-- <input matInput aria-label="Emoji" [matAutocomplete]="auto" formControlName="emoji"> -->
            <!-- <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let state of emoji" [value]="state.value">
                <img class="example-option-img" aria-hidden [src]="state.value" height="25">
                <span>{{state.name}}</span>
              </mat-option>
            </mat-autocomplete> -->
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" class="!mt-4" style="width: 100%;">
        <div class="input-gap" style="width: 30%;">
          <mat-checkbox (change)="newScreenCheck($event)" [checked]="newScreen">New screen</mat-checkbox>
        </div>
        <div class="input-gap" style="width: 30%;">
          <mat-checkbox (change)="hideCategoryCheck($event)" [checked]="hideCategory">Hide category</mat-checkbox>
        </div>
        <div class="input-gap" style="width: 40%;">
          <mat-checkbox (change)="hideProductWeightCheck($event)" [checked]="hideProductWeight">Hide product weight
          </mat-checkbox>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" class="button-box-right form-row">
      <button mat-button mat-dialog-close mat-stroked-button>Cancel</button>
      <button mat-button mat-flat-button color="primary" class="apply-btn-colr">Submit</button>
    </mat-dialog-actions>
  </form>
</span>