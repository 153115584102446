import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, Inject, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { IClientSubscribeOptions } from 'mqtt/types/lib/client-options';
import { IMqttMessage, IPublishOptions, MqttService } from 'ngx-mqtt';
import { Subject, Subscription } from 'rxjs';
import { POSStreamService } from 'src/app/_services/pos-stream.service';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import { CancelCardPaymentComponent } from '../cancel-card-payment/cancel-card-payment.component';
import { FrontFacingMqttService } from 'src/app/_services/front-facing-mqtt.service';
import { OrderService } from 'src/app/_services/order.service';
import { POSSummaryToggleService } from 'src/app/_services/pos-summary-toggle.service';
import { POSMenuTabChangeService } from 'src/app/_services/pos-menu-tab-change.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ManualPrintControlComponent } from '../order-summary/manual-print-control/manual-print-control.component';
import { RefreshService } from 'src/app/_services/refresh.service';
import { CallerIdService } from 'src/app/_services/caller-id.service';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';


@Component({
  selector: 'app-card-payment-mqtt',
  templateUrl: './card-payment-mqtt.component.html',
  styleUrls: ['./card-payment-mqtt.component.scss']
})
export class CardPaymentMqttComponent implements OnInit, OnDestroy {
  @Output() closeMultiComponentOverlay = new EventEmitter<any>();
  @Output() closeSplitMultiOverlay = new EventEmitter<any>();
  @Output() closeCheckoutOverlay = new EventEmitter<boolean>();
  @Output() closeOverlay = new EventEmitter<boolean>();

  terminalId;
  progress = 0;
  logs = [];
  isError;
  isConfirmed;
  isDone: Subscription
  CancelSub: Subscription
  retrySub: Subscription
  signatureDoneSub: Subscription
  private subscription: Subscription;
  private signatureDialogRef: MatDialogRef<SignaturePadComponent>;
  private destroySubject: Subject<void> = new Subject();
  private overlayRef!: OverlayRef;


  constructor(
    private mqttService: MqttService,
    @Inject('OVERLAY_DATA') public data: any,
    public dialog: MatDialog,
    private ffmq: FrontFacingMqttService,
    private refreshService: RefreshService,
    private orderService: OrderService,
    private posStreamService: POSStreamService,
    private posSummaryToggleService: POSSummaryToggleService,
    private chref: ChangeDetectorRef,
    private tabChangeService: POSMenuTabChangeService,
    private callerIdService: CallerIdService,
    private overlay: Overlay,
    private injector: Injector
  ) { }

  cancel() {
    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(CancelCardPaymentComponent, {
      width: isMobile ? '80vw' : '300px',
      height: isMobile ? 'auto' : '',
      // width: '300px',
      // height: '134px'
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result == 1) {
        const payload = {
          "command": "CANCEL",
          "order_id": this.data.orderWithPayment.bot_order.order_hash,
          "amount": this.data.amount,
          "cashier_id": this.data.cashier_id,
          "payment_type": this.data.payment_type
        }
        this.publish(JSON.stringify(payload));
        this.updateTip();
        // this.dialogRef.close(false);
        if (this.data.eventsource === 'split-cash') {
          this.closeSplitMultiOverlay.emit(true);
        } else if (this.data.eventsource === 'checkout') {
          this.closeCheckoutOverlay.emit(true);
        } else if (this.data.eventsource === 'nmi') {
          this.closeOverlay.emit(false);
        } else {
          this.closeMultiComponentOverlay.emit(false);
        }
      }
    });
  }

  done() {
    const data = {
      value: 1,
      order_hash: this.data.orderWithPayment.bot_order.order_hash,
      key: 'manual_card'
    }
    this.openOverlay(ManualPrintControlComponent, data);
  }

  close() {
    console.log("close")
    // this.dialogRef.close()
    if (this.data.eventsource === 'split-cash') {
      this.closeSplitMultiOverlay.emit(true);
    } else if (this.data.eventsource === 'checkout') {
      this.closeCheckoutOverlay.emit(false);
    } else if (this.data.eventsource === 'nmi') {
      this.closeOverlay.emit(false);
    } else {
      this.closeMultiComponentOverlay.emit(false);
    }

  }

  ngOnInit() {
    localStorage.removeItem('isIn');
    this.ffmq.paymentModeSubject.next(0);
    this.progress = 10;
    const qos = 2;
    this.terminalId = localStorage.getItem('selectedTerminal');
    this.retrySub = this.ffmq.getRetryPayment().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (this.ffmq.decodeAndParse(data.payload).onretry == true) {
        this.sendPaymentRequest();
      }
    })
    this.CancelSub = this.ffmq.getPaymentCancel().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      console.log(data, "close")
      if (this.ffmq.decodeAndParse(data.payload).oncancel == true) {
        this.close()
      }
    })

    this.isDone = this.ffmq.getPaymentDoneStatus().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (JSON.parse(data.payload.toString()).type === 'paymentCompletedDone') {
        this.isConfirmed = true;
        if (this.data.from === 'gift-partial-payment') {
          this.giftPartialCash();
        } else {
          this.done();
        }
      }
    })

    this.signatureDoneSub = this.ffmq.getSignatureDoneStatus().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (this.ffmq.decodeAndParse(data.payload).type == "signatureDone") {
        this.signatureDialogRef.close(true);
      }
    })

    this.subscription = this.mqttService.observe(`/link/${this.terminalId}/pub`,
      { qos } as IClientSubscribeOptions).pipe(takeUntil(this.destroySubject)).subscribe((message: IMqttMessage) => {
        const response = JSON.parse(message.payload.toString());
        console.log(response);
        switch (response.command) {
          case 'CARD_READ':
            this.progress = 50;
            this.logs.push('Processing Card: ', response.masked_num);
            this.logs.push('Confirming transaction with the gateway');
            break;
          case 'PIN_REQUIRED':
          case 'SIGN_REQUIRED':
            this.progress = 70;
            this.logs.push('PIN required, please sign.');
            this.startSignatureVerification(response);
            break;
          case 'CONFIRM':
            this.progress = 100;
            this.isConfirmed = true;
            this.posStreamService.closeOrder(this.terminalId, response).pipe(takeUntil(this.destroySubject)).subscribe(data => {
              this.isConfirmed = true;
              this.logs.push('Order successfully closed.');
              this.ffmq.publishPaymentDone()
              this.done()
              this.orderService.openOrderCompleteSubject.next({ order: this.data.orderWithPayment.bot_order.order_hash, complete: true });
            });
            break;
          case 'ERROR':
            this.progress = 0;
            this.logs.push('Unable to process transaction, retry again');
            this.isError = true;
            break;
          case 'PIN_VERIFICATION':
            if (response.signed == true && this.signatureDialogRef != null) {
              console.log("inside pin verification");
              this.signatureDialogRef.close(response.signed);
            }
            break;
          default:
            console.log(JSON.stringify(response));
        }
      });

    this.mqttService.onError.pipe(takeUntil(this.destroySubject)).subscribe(error => {
      console.log(error);
    });

    this.sendPaymentRequest();

    // Add to not show preview tip issue
    const keyData = {
      select: 'TIP',
      tip: { "name": "", "value": '', "amount": '' },
    };
    this.ffmq.publishTip(keyData);
  }

  giftPartialCash() {
    const orderWithPayment = this.data.orderWithPayment;
    const payload = {
      terminal_id: localStorage.getItem('selectedTerminalName'),
      terminal_name: localStorage.getItem('selectedTerminalName'),
      cashier_id: localStorage.getItem('posLoggedUser'),
      amount: this.data.card_amnt,
      pin_user: localStorage.getItem('pinUser'),
      card_id: this.data.card_id
    };
    this.orderService.closeGiftCardOrder(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.destroySubject))
      .subscribe((result) => {
        if (result) {
          this.done();
          // this.closeOrder(r);
          // this.dialogRef.close(true);
          // if (this.store.feature_flag['manual_print_standard_checkout']) {
          //   // this.manualReceiptOptions();
          // }
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.CancelSub.unsubscribe();
    this.isDone.unsubscribe();
    this.destroySubject.next();
  }

  sendPaymentRequest() {
    console.log(this.data);
    const cashier_id = localStorage.getItem('posLoggedUser')
    const paymemtRequest = {
      command: 'SALE',
      order_id: this.data.orderWithPayment.bot_order.order_hash,
      amount: this.data.total,
      cashier_id: cashier_id,
      payment_type: this.data.payment_type ? this.data.payment_type : 'CREDIT',
    }
    if (this.data.payment_type == 'SPLIT') {
      paymemtRequest['p_type'] = 'CREDIT'
    }
    this.publish(JSON.stringify(paymemtRequest));
    this.logs.push('Payment request sent to server');
  }

  publish(payload: string) {
    const qos = 2;
    this.mqttService.unsafePublish(`/link/${this.terminalId}/sub`, payload, { qos } as IPublishOptions);
  }

  startSignatureVerification(msg: any) {
    console.log(msg);
    this.signatureDialogRef = this.dialog.open(SignaturePadComponent, {
      data: msg, disableClose: true
    });
    this.signatureDialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      result.order_id = this.data.orderWithPayment.bot_order.order_hash;
      const payload = {
        "command": "PIN_VERIFICATION",
        "order_id": this.data.orderWithPayment.bot_order.order_hash,
        "signed": true,
        "pin": result.pin ? result.pin : ""
      }
      this.publish(JSON.stringify(payload));
      console.log(result);
    });
  }

  updateTip() {
    this.orderService.updateTip(this.data.orderWithPayment.bot_order.order_hash, 0, 'card-payment-mqtt-updatetip').pipe(takeUntil(this.destroySubject)).subscribe((data: any) => { });
  }

  openOverlay(component, data) {
    if (this.overlayRef) {
      this.closelay();
    }
    let overlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),

    };

    this.overlayRef = this.overlay.create(overlayConfig);
    const injector = this.createInjector(data);

    const portal = new ComponentPortal(component, null, injector);
    const componentRef: ComponentRef<any> = this.overlayRef.attach(portal);

    componentRef.instance.closeOverlay.subscribe((isComplete: any) => {
      console.log('Card Payment MQTT', isComplete);
      if (isComplete) {
        this.posSummaryToggleService.toggle('CLOSE');
        this.tabChangeService.changeTab(0);
        this.refreshService.refreshModule('OPEN_TAB');
        // this.dialogRef.close(this.isConfirmed);
        this.orderService.orderDoneSubject.next();
        this.callerIdService.setPhoneNumbertoOrderAndDelivery.next(null);
        if (this.data.eventsource === 'split-cash') {
          this.closeSplitMultiOverlay.emit(true);
        } else if (this.data.eventsource === 'checkout') {
          this.closeCheckoutOverlay.emit(true);
        } else if (this.data.eventsource === 'nmi') {
          this.closeOverlay.emit(true);
        } else {
          this.closeMultiComponentOverlay.emit(this.isConfirmed);
        }
      }
      this.closelay();
    });
  }


  closelay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  private createInjector(data: any): Injector {
    return Injector.create({
      providers: [{ provide: 'OVERLAY_DATA', useValue: data }, { provide: OverlayRef, useValue: this.overlayRef },],
      parent: this.injector,
    });
  }

}
