<div class="flex hosted_form items-center justify-center flex-col">
  <div class="card-container payroc-form bg-white rounded shadow-none flex w-full gap-[5px] xs:flex xs:flex-col">
    <div class="card-holder-name"></div>
    <div class="card-number"></div>
    <div class="cols-2 flex gap-[5px] xs:flex xs:flex-col">
      <div class="col">
        <div class="card-expiry"></div>
      </div>

      <div class="col card-cvv-wrapper">
        <div class="card-cvv"></div>
      </div>
    </div>
    <div class="address-line1-wrapper mb-4">
      <input type="tel" id="zip-code" maxlength="10" placeholder="Zip Code"
       pattern="[0-9]*" inputmode="numeric"
       class="h-[55px] w-[100%] p-2 text-left text-[16px] text-black border border-black bg-white leading-[2] box-border" />
    </div>
    <div class="card-submit submit-button mt-[4px]"></div>
  </div>
  <div class="opt-in-checkbox">
    <mat-checkbox [(ngModel)]="optin">Opt-in to receive automated offers & messages</mat-checkbox>
  </div>
</div>

<!-- <div class="flex items-center justify-center flex-col"> -->
<!-- <div class="card-container payroc-form bg-white p-6 rounded shadow-none flex w-full"> -->
<!-- <label for="card-holder-name">Cardholder Name</label> -->
<!-- <div class="hosted_form">
  <div class="card-holder-name mr-[15px]"></div> -->
  <!-- <div class="card-holder-name-error error-message"></div>   -->

  <!-- <label for="card-number">Card Number</label> -->
  <!-- <div class="card-number mr-[15px]"></div> -->
  <!-- <div class="card-number-error error-message"></div> -->

  <!-- <div class="cols-2 gap-4">
    <div class="col"> -->
      <!-- <label for="card-expiry">Expires (MM/YY)</label> -->
      <!-- <div class="card-expiry"></div> -->
      <!-- <div class="card-expiry-error error-message"></div> -->
    <!-- </div> -->

    <!-- <div class="col card-cvv-wrapper mr-[15px]"> -->
      <!-- <label for="card-cvv">CVV</label> -->
      <!-- <div class="card-cvv"></div> -->
      <!-- <div class="card-cvv-error error-message"></div> -->
    <!-- </div>
  </div>
  <div class="address-line1-wrapper mb-4">
    <input type="text" id="zip-code" maxlength="100" placeholder="Zip Code"
      class="h-[55px] w-[150px] p-2 text-left text-[16px] text-black border border-black bg-white leading-[2] box-border" />
  </div>
  <div class="card-submit submit-button ml-[15px] mt-[4px]"></div>
</div> -->
<!-- </div> -->
<!-- <div class="opt-in-checkbox">
  <mat-checkbox [(ngModel)]="optin">Opt-in to receive automated offers & messages</mat-checkbox>
</div> -->
<!-- </div> -->