import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GiftBalanceInquiryComponent } from '../gift-balance-inquiry/gift-balance-inquiry.component';
import { OrderService } from 'src/app/_services/order.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GiftCardPartialPaymentComponent } from '../gift-card-partial-payment/gift-card-partial-payment.component';
import { BarCodeScannerComponent } from '../bar-code-scanner/bar-code-scanner.component';
import { AddGiftCardValueComponent } from '../add-gift-card-value/add-gift-card-value.component';
import { FrontFacingMqttService } from 'src/app/_services/front-facing-mqtt.service';
@Component({
  selector: 'app-activate-gift-card-dialog',
  templateUrl: './activate-gift-card-dialog.component.html',
})

export class ActivateGiftCardDialogComponent {
  amount: number = 0;
  cardNumber: string = '';
  forWhich: string
  keypadKeys: (string | number)[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'C', 0, '✕'];
  currentStore: string;
  scannedResult: string | null = null;
  private scannedData: string = '';
  beepAudio = new Audio('assets/audio/alert.mp3');
  @ViewChild('cardNumberInput') cardNumberInput!: ElementRef;
  constructor(
    public dialog: MatDialog,
    private orderService: OrderService,
    private dialogRef: MatDialogRef<ActivateGiftCardDialogComponent>,
    private _snackBar: MatSnackBar,
    private ffmq: FrontFacingMqttService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.amount = data.amount;
    this.forWhich = data.for
  }

  @HostListener('window:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log('ActivateGiftCardDialogComponent');
    const giftBalanceInquiry = this.dialog.openDialogs.some(dialog =>
      dialog.componentInstance instanceof GiftBalanceInquiryComponent
    );
    if (!giftBalanceInquiry) {
      event.preventDefault();
      console.log('GIFT CARD BARCODE READER', event);
      const char = event.key;
      if (char === 'Enter' && /^\d+$/.test(this.scannedData)) {
        this.cardNumber = this.scannedData;
        this.beepAudio.play();
        if (this.forWhich == 'balance-inquiry') {
          this.inquiry();
        } else {
          this.getCarddetails();
        }
        this.scannedData = '';
      } else if (char !== 'Enter' && /^\d$/.test(char)) {
        this.scannedData += char;
      }
    }

  }

  ngAfterViewInit(): void {
    this.cardNumberInput.nativeElement.focus();
  }

  onKeyPress(key: string | number) {
    if (key === '✕') {
      this.cardNumber = this.cardNumber.slice(0, -1);
    } else if (key === 'C') {
      this.cardNumber = '';
    } else {
      this.cardNumber += key;
    }
  }

  onScan() {
    const isMobile = window.innerWidth <= 470;
    const dialogWidth = isMobile ? '90vw' : '41vw';
    const dialogHeight = isMobile ? '40.8vh' : '61.1vh';
    const dialogRef = this.dialog.open(BarCodeScannerComponent, {
      width: dialogWidth,
      height: dialogHeight,
      maxHeight: '70vh',
      position: !isMobile ? { right: '80px' } : { bottom: '5px' },
      disableClose: true,
      data: {
        store: this.data.store,
        is_gift: true
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.data && data.cardNumber) {
        this.cardNumber = data.cardNumber
        if (this.forWhich == 'balance-inquiry') {
          this.inquiry();
        } else {
          this.getCarddetails();
        }
      }
    });
  }

  getCarddetails() {
    this.currentStore = localStorage.getItem('currentStore');
    this.orderService.getPhysicalGiftcard(this.cardNumber, this.currentStore).subscribe({
      next: (response) => {
        if ((this.forWhich == 'gift-card-purchase' && response.status == "inactive") || (this.forWhich == 'gift-card-addvalue' && response.status == "active")) {
          this.dialogRef.close({ data: true, cardDetails: response, cardAmount: this.amount });
        } else if (this.forWhich == 'gift-card-redeem' && response.status == "active") {
          if (response.balance == 0) {
            this._snackBar.open("Your gift card balance is $0", "OK", { duration: 2000, verticalPosition: 'top' });
          } else {
            if (this.amount > response.balance) {
              this.dialogRef.close({ data: true, cardDetails: response })
              // this._snackBar.open('Gift card balance is insufficient', 'OK', { duration: 2000, verticalPosition: 'top' });
            } else {
              this.ffmq.publishPaymentDone();
              this.dialogRef.close({ data: true, cardDetails: response })
            }
          }
        } else {
          const alert = this.forWhich == 'gift-card-purchase' ? "This is already a active or used gift card" : this.forWhich == 'gift-card-addvalue' ? "This gift card is currently inactive" : "This gift card is currently inactive";
          this._snackBar.open(alert, "OK", { duration: 2000, verticalPosition: 'top' });
        }
      },
      error: (err) => {
        this._snackBar.open("We did not found this gift card detail", "OK", {
          duration: 5000,
        });
      }
    });
  }

  onActivate() {
    console.log('ON ACTIVATE Button CLick');
    this.getCarddetails()
  }

  done() {
    this.getCarddetails();
  }

  donePayment() {
    this.getCarddetails();
  }

  inquiry() {
    const dialogRef = this.dialog.open(GiftBalanceInquiryComponent, {
      width: '500px',
      data: { data: this.cardNumber, orderId: this.data.orderId, store: this.data.store },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close(this.cardNumber);
      }
    });
  }

  onBack() {
    this.dialogRef.close({ action: 'back' });
  }

}