<div class="flex flex-col w-screen h-screen bg-white">
  <div class="xs:hidden">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  </div>
  <div class="h-full">
    <form *ngIf="form" [formGroup]="form" class="p-7 h-full">
      <div fxFlex fxLayout="row" fxFlex="100" fxLayout.xs="column">
        <div fxFlex="52" fxFlex.xs="100" fxLayout="column" class="pt-1.5 xs:pt-0">
          <div class="price_info">
            <table class="w-4/6 sm:w-full xs:w-full">
              <tr>
                <td class="xs:w-2/5">
                  <div class="heading text-start xs:text-center sm:m-0 sm:py-0 sm:px-5">
                    <h1 class="font-medium text-[22px]">Total:</h1>
                    <h2 class="font-medium my-5 text-[22px]">{{orderWithPayment.payment.total_split | currency: 'USD'}}
                    </h2>
                  </div>
                </td>
                <td class="xs:w-3/5">
                  <div class="heading text-start xs:text-center sm:m-0 sm:py-0 sm:px-5">
                    <h1 class="font-medium text-[22px]">Balance Due:</h1>
                    <h2 class="font-medium my-5 text-[22px]">{{due | currency: 'USD'}}</h2>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="justify-center mt-[50%] hidden xs:flex" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
          </div>
          <div fxLayout="row" fxLayout.xs="column" *ngIf="!isLoading"
            class="max-h-[72%] overflow-x-hidden overflow-y-auto sm:overflow-x-scroll sm:border border-gray-300">
            <div fxFlex="45" fxFlex.xs="100" class="split-item-breakup xs:hidden">
              <table mat-table [dataSource]="orderWithPayment?.split_items" cellspacing="10" cellpadding="10"
                class="split_show">
                <ng-container matColumnDef="quantity">
                  <td mat-cell *matCellDef="let item" class="menu-item-split">
                    {{item.quantity}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="item">
                  <td mat-cell *matCellDef="let item">
                    <ng-container *ngIf="item.menu_item; else miscBlock">
                      <b mat-line class="menu-item-splits"> <span
                          *ngIf="item.menu_item.portion != 'Standard' ">{{item.menu_item.portion}}&nbsp;</span>{{item.menu_item.display_name}}
                      </b>
                      <div class="modifiers">
                        <p *ngIf="item.special_request">"{{item.special_request}}"</p>
                        <div *ngFor="let mod of item.modifiers">
                          <div *ngIf="mod.modifier; then thenBlock else elseBlock"></div>
                          <ng-template #thenBlock>
                            <div *ngIf="mod.modifier">
                              <p>
                                <span *ngIf="mod.mod_type != 'ADD'">{{ orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) : pizzaOptions.get(mod.mod_type) }}
                                  {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod.modifier.name}}</span>
                                <span *ngIf="mod.mod_type === 'ADD'">
                                  {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod.modifier.name}}</span>
                                <span *ngIf="mod.mod_type.toLowerCase() !='no'">
                                  <span>({{mod.modifier.upcharge | currency:'USD'}})</span>
                                </span>
                              </p>
                            </div>
                          </ng-template>
                          <ng-template #applicablePrice>
                            <span>(N/A)</span>
                          </ng-template>
                          <ng-template #elseBlock>
                            <i class="non-match">
                              <p><span *ngIf="mod.mod_type != 'ADD'">{{ orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) : pizzaOptions.get(mod.mod_type) }}</span>
                                width {{mod.modifier_raw}}</p>
                            </i>
                          </ng-template>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container miscBlock>
                      <b mat-line class="menu-item-splits">
                        {{item.item_name}}
                      </b>
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container matColumnDef="itemTotal">
                  <td mat-cell *matCellDef="let item" class="align-right menu-item-split w-[26%]">
                    <span *ngIf="!item.sale_price">
                      <span>{{item?.price | currency:'USD' }}</span>
                    </span>

                    <span *ngIf="+item.sale_price >= 0">
                      <span>{{item.sale_price | currency:'USD'}}</span>
                    </span>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <div fxFlex="55" fxFlex.xs="100">
              <span *ngIf="showSplits">
                <span *ngFor="let items_count of split_by_items; index as j">
                  <table cellspacing="10" cellpadding="10" class="split_show hidden xs:contents" style="margin-left: -7px;">
                    <tr class="hidden">
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr>
                      <td style="color: dimgray;" class="text-lg font-medium align-baseline !pl-0">{{items_count.menu_item.quantity}}</td>
                      <td class="align-baseline !pl-[5px]">
                        <span *ngIf="!items_count.menu_item.misc">
                          <b style="color: dimgray;" class="text-lg font-medium">
                            <span>{{items_count.menu_item.menu_item.portion}}&nbsp;</span>{{items_count.menu_item.menu_item.display_name}}
                          </b>
                          <div class="modifiers">
                            <p *ngIf="items_count.menu_item.special_request">"{{items_count.menu_item.special_request}}"
                            </p>
                            <div *ngFor="let mod of items_count.menu_item.modifiers">
                              <div *ngIf="mod.modifier; then thenBlock else elseBlock"></div>
                              <ng-template #thenBlock>
                                <div *ngIf="mod.modifier">
                                  <p class="text-sm" style="color: grey;">
                                    <span *ngIf="mod.mod_type != 'ADD'">{{ orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) : pizzaOptions.get(mod.mod_type) }}
                                      {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod.modifier.name}}</span>
                                    <span *ngIf="mod.mod_type === 'ADD'">
                                      {{ mod.quantity > 1 ? mod.quantity + ' x ' : '' }} {{mod.modifier.name}}</span>
                                    <span *ngIf="mod.mod_type.toLowerCase() !='no'">
                                      <span>({{mod.modifier.upcharge | currency:'USD'}})</span>
                                    </span>
                                  </p>
                                </div>
                              </ng-template>
                              <ng-template #applicablePrice>
                                <span>(N/A)</span>
                              </ng-template>
                              <ng-template #elseBlock>
                                <i class="non-match">
                                  <p><span *ngIf="mod.mod_type != 'ADD'">{{ orderWithPayment.store.feature_flag.display_half_1_and_half_2 ? pizzaOptions$.get(mod.mod_type) : pizzaOptions.get(mod.mod_type) }}</span>
                                    width {{mod.modifier_raw}}</p>
                                </i>
                              </ng-template>
                            </div>
                          </div>
                        </span>
                      </td>
                      <td style="color: dimgray;" class="text-lg font-medium align-baseline w-[26%]">
                        <span *ngIf="!items_count.menu_item.sale_price">
                          <span>{{items_count.menu_item?.price | currency:'USD' }}</span>
                        </span>
    
                        <span *ngIf="+items_count.menu_item.sale_price >= 0">
                          <span>{{items_count.menu_item.sale_price | currency:'USD'}}</span>
                        </span>
                      </td>
                    </tr>
                  </table>
                  <div class="h-[100px] xs:h-[0px] xs:min-h-[57px] xs:max-h-[100px] xs:ml-1.5" [ngClass]="items_count.check_value ? 'disabled-row' : ''">
                    <span *ngFor="let splits_check of items_count.split_checks; index as i">
                      <button mat-flat-button color="primary" class="check_butn mb-1 mr-2 h-[40px]"
                        (click)="checkSelect(splits_check, items_count)"
                        [ngStyle]="{'background-color':showCheckSelected(splits_check, items_count) ? 'green' : '' }"
                        [disabled]="splits_check.possplitpayment_details.is_complete">{{splits_check.check_hash}}</button>
                    </span>
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div fxFlex="48" fxFlex.xs="100" *ngIf="!isLoading">
          <div class="text-center leading-[100px] h-[11%] mb-2.5">
            <mat-form-field appearance="fill">
              <mat-label>Number of Splits</mat-label>
              <input matInput type="number" formControlName="num_splits" [(ngModel)]="numSplits" min="0"
                oninput="validity.valid||(value='')">
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="getSplits(0)" *ngIf="!showSplits"
              class="bottom-2.5 left-2.5">Confirm</button>
            <button mat-raised-button color="primary" (click)="reset()" *ngIf="showSplits"
              class="bottom-2.5 left-2.5">Edit</button>
          </div>

          <div class="h-[72%] overflow-x-hidden overflow-y-auto sm:overflow-x-scroll sm:border border-gray-300">
            <table class="table mobile-hide splits-table" cellpadding=5 formArrayName="splits">
              <span *ngFor="let split of form.controls.splits.controls; index as i" [formGroupName]="i">
                <tr class=""
                  [ngClass]="split.controls.is_custom.value && !split.controls.is_complete.value ? 'split-number': 'undo-split-number'">
                  <td>
                    <button mat-icon-button *ngIf="split.controls.is_custom.value && !split.controls.is_complete.value"
                      (click)="resetSingleSplit(split)">
                      <mat-icon>undo</mat-icon>
                    </button>
                  </td>
                  <td class="split-field split-checks">
                    <mat-form-field appearance="fill" class="w-full">
                      <mat-label>Check #{{i+1}}</mat-label>
                      <input style="margin-top:9px" matInput formControlName="split_amount" step="0.01"
                      (blur)="calculateSplits(split)" (focus)="onInputFocus(split)">
                    </mat-form-field>
                  </td>
                  <td class="split-field">
                    <mat-form-field class="w-full pmnt-type">
                      <mat-label>Payment Type</mat-label>
                      <mat-select matNativeControl formControlName="payment_type" name="payment_type"
                        (selectionChange)="calculateSplits(split)">
                        <mat-option value="">Select</mat-option>
                        <mat-option value="CASH">Cash</mat-option>
                        <mat-option value="CREDIT">Credit Card</mat-option>
                        <mat-option value="GIFT">Gift Card</mat-option>
                        <mat-option value="KEYED">Keyed</mat-option>
                        <mat-option value="OTHER">Other</mat-option>
                        <mat-option value="MANUAL">Manual</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td>
                    <div *ngIf="split.controls.is_complete.value; then voidBlock else processBlock"></div>
                    <ng-template #voidBlock>
                      <button mat-flat-button color="warn"
                        *ngIf="(split.controls.payment_type.value === 'CASH' || split.controls.payment_type.value === 'GIFT' || split.controls.payment_type.value === 'OTHER' || split.controls.payment_type.value === 'KEYED')"
                        (click)="voidCash(split)" class="bottom-[11px]">Void</button>
                      <button mat-flat-button color="warn" [disabled]="isLoading" class="bottom-[11px]"
                        *ngIf="split.controls.payment_type.value === 'CREDIT' || split.controls.payment_type.value === 'MANUAL'" (click)="refund(split)">Void</button>
                    </ng-template>
                    <ng-template #processBlock>
                      <button mat-flat-button color="primary" (click)="process(split)"
                        [disabled]="split.controls.payment_type.value === '' || split.controls.split_amount.value === '' || split.controls.split_amount.value == null || split.controls.split_amount.value <= 0"
                        class="move-field bottom-[11px]">Process</button>
                    </ng-template>
                  </td>
                </tr>
              </span>
            </table>

            <div formArrayName="splits" class="tab-lap-hide">
              <span *ngFor="let split of form.controls.splits.controls; index as i; trackBy: trackByFn" [formGroupName]="i">
                <div
                  [ngClass]="split.controls.is_custom.value && !split.controls.is_complete.value ? 'split-number': 'undo-split-number'">
                  <div>
                    <button mat-icon-button *ngIf="split.controls.is_custom.value && !split.controls.is_complete.value"
                      (click)="resetSingleSplit(split)">
                      <mat-icon>undo</mat-icon>
                    </button>
                  </div>
                  <div>
                    <mat-form-field appearance="fill" class="matform-split-type">
                      <mat-label>Check #{{i+1}}</mat-label>
                      <!-- <mat-label>Split Amount</mat-label> -->
                      <input matInput formControlName="split_amount" step="0.01" (keydown)="toggleDirty(split)">
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field class="matform-payment-type">
                      <mat-label>Payment Type</mat-label>
                      <mat-select matNativeControl formControlName="payment_type" name="payment_type"
                        (selectionChange)="calculateSplits(split)">
                        <mat-option value="">Select</mat-option>
                        <mat-option value="CASH">Cash</mat-option>
                        <mat-option value="CREDIT">Credit Card</mat-option>
                        <mat-option value="GIFT">Gift Card</mat-option>
                        <mat-option value="KEYED">Keyed</mat-option>
                        <mat-option value="OTHER">Other</mat-option>
                        <mat-option value="MANUAL">Manual</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="button-proess">
                  <div *ngIf="split.controls.is_complete.value; then voidBlock else processBlock"></div>
                  <ng-template #voidBlock>
                    <button mat-flat-button color="warn" class="button-wdth xs:!h-[50px]"
                      *ngIf="(split.controls.payment_type.value === 'CASH' || split.controls.payment_type.value === 'GIFT' || split.controls.payment_type.value === 'OTHER' || split.controls.payment_type.value === 'KEYED')"
                      (click)="voidCash(split)">Void</button>
                    <button mat-flat-button color="warn" [disabled]="isLoading" class="button-wdth xs:!h-[50px]"
                      *ngIf="split.controls.payment_type.value === 'CREDIT' || split.controls.payment_type.value === 'MANUAL'" (click)="refund(split)">Void</button>
                  </ng-template>
                  <ng-template #processBlock>
                    <button mat-flat-button color="primary" (click)="process(split)" class="button-wdth xs:!h-[50px]"
                      [disabled]="split.controls.payment_type.value === ''">Process</button>
                  </ng-template>
                </div>
              </span>
            </div>
          </div>
          <div class="text-end">
            <button mat-stroked-button class="bottom-3.5 xs:!h-[45px] mr-3" (click)="cancel()">Cancel</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!isComplete" (click)="submit()"
              class="bottom-3.5 xs:!h-[45px]">Submit</button>
          </div>
        </div>
        
      </div>
    </form>
  </div>
</div>