<div class="content m-3 mb-{100px}">
  <form [formGroup]="settlementForm" (ngSubmit)="onSubmit()" class="mt-10">
    <div class="flex flex-row xs:flex-col xs:block xs:text-center gap-2 m-3 items-center">
      <div class="">
        <mat-form-field class="form-field filter xs:gap-4 xs:w-full" subscriptSizing="dynamic">
          <mat-label>Search Order</mat-label>
          <input matInput type="text" formControlName="filter" (keyup)="onSubmit()">
          <button *ngIf="orderCounterFilter" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="date xs:gap-4 xs:w-full" subscriptSizing="dynamic">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="start_picker" formControlName="start_date">
          <mat-datepicker-toggle matSuffix [for]="start_picker"></mat-datepicker-toggle>
          <mat-datepicker #start_picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="date xs:gap-4 xs:w-full" subscriptSizing="dynamic">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="end_picker" formControlName="end_date">
          <mat-datepicker-toggle matSuffix [for]="end_picker"></mat-datepicker-toggle>
          <mat-datepicker #end_picker></mat-datepicker>
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary" class="apply-btn-colr xs:gap-4 xs:mr-2.5"
        (click)="onSubmit()">Search</button>&nbsp;
      <button mat-flat-button color="primary" class="apply-btn-colr xs:gap-4"
        (click)="settlementForm.reset()">Reset</button>
    </div>
  </form>


  <div class="mt-8 flow-root" *ngIf="store.payment_gateway != 'WORLDNET'">
    <div class="">
      <div class="inline-block min-w-full py-2 align-middle h-{300px} xs:!overflow-x-scroll xs:!w-80">
        <table class="min-w-full border-separate border-spacing-0 xs:!table-auto">
          <thead>
            <tr>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75
            py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter ">Batch Date
              </th>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75
            py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter ">Count</th>
              <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter !table-cell">Total</th>
              <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter !table-cell">Refund</th>
              <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter !table-cell">Fee</th>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">Expected Deposit</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ag of getNonSetledBatches()">
              <td class="whitespace-nowrap border-b border-gray-200 text-sm font-medium text-gray-900 px-3.5 py-2">
                Pending settlements</td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm text-gray-900 ">{{ag.orders}}</td>
              <!-- <td class="whitespace-nowrap border-b border-gray-200 hidden p-2 text-sm text-gray-500 !table-cell">{{ag.sale | currency:'USD'}}</td> -->
              <td
                class="whitespace-nowrap border-b border-gray-200 hidden text-sm text-gray-500 !table-cell px-3.5 py-2">
                {{ag.settled | currency:'USD'}}</td>
              <td class="whitespace-nowrap border-b border-gray-200 hidden p-2 text-sm text-gray-500 !table-cell">
                {{ag.refund | currency:'USD'}}</td>
              <!-- <td class="whitespace-nowrap border-b border-gray-200 hidden text-sm text-gray-500 !table-cell px-3.5 py-2">{{getFee(ag) | currency:'USD'}}</td> -->
              <td
                class="whitespace-nowrap border-b border-gray-200 hidden text-sm text-gray-500 !table-cell px-3.5 py-2">
                {{ag.fee | currency:'USD'}}</td>
              <td class="whitespace-nowrap border-b border-gray-200 text-sm text-gray-500 px-3.5 py-2">
                {{ag.expected_settlement | currency:'USD'}}</td>
            </tr>

            <tr *ngFor="let ag of getSettledBatches()">
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm font-medium text-gray-900 ">
                {{getSettlementDate(ag.settlement_date) | date:'MMM dd EEE hh:mm a'}}</td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm text-gray-900 ">{{ag.orders}}</td>
              <!-- <td class="whitespace-nowrap border-b border-gray-200 hidden p-2 text-sm text-gray-500 !table-cell">{{ag.sale | currency:'USD'}}</td> -->
              <td
                class="whitespace-nowrap border-b border-gray-200 hidden text-sm text-gray-500 !table-cell px-3.5 py-2">
                {{ag.settled | currency:'USD'}}</td>
              <td class="whitespace-nowrap border-b border-gray-200 hidden p-2 text-sm text-gray-500 !table-cell">
                {{ag.refund | currency:'USD'}}</td>
              <!-- <td class="whitespace-nowrap border-b border-gray-200 hidden text-sm text-gray-500 !table-cell px-3.5 py-2">{{getFee(ag) | currency:'USD'}}</td> -->
              <td
                class="whitespace-nowrap border-b border-gray-200 hidden text-sm text-gray-500 !table-cell px-3.5 py-2">
                {{ag.fee | currency:'USD'}}</td>
              <td class="whitespace-nowrap border-b border-gray-200 text-sm text-gray-500 px-3.5 py-2">
                {{ag.expected_settlement | currency:'USD'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <div class="mt-8 flow-root">
    <div class="">
      <div class="inline-block min-w-full py-2 align-middle xs:!overflow-x-scroll xs:!w-80">
        <table class="min-w-full border-separate border-spacing-0 xs:!table-auto"
          *ngIf="store.payment_gateway != 'WORLDNET'">
          <thead>
            <tr>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75
            py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter ">Order
                Counter</th>
              <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter !table-cell">Action</th>
              <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter !table-cell">Amount</th>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">Transaction ID</th>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">Card Type</th>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">Card Number</th>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">Name on card</th>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">Submitted At</th>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">Settled Date</th>
              <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3
            py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">Final Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let st of settlements?.results">
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm font-medium text-gray-900">
                {{st.order_counter}}</td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm font-medium text-gray-900">
                <span [ngClass]="{
                  'text-amber-500': st.action_type.trim() === 'void', 'text-red-500': st.action_type.trim() === 'refund',
                   'text-green-500': st.action_type.trim() === 'sale'}">{{st.action_type}}</span>
              </td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm
             text-gray-900 ">{{st.amount | currency: 'USD'}}</td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm
             text-gray-900 ">{{st.transaction_id}}</td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm
             text-gray-900 ">{{st.card_type }}</td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm
             text-gray-900 ">&bull;&bull;&bull;&bull;{{st.last4.slice(-4) }}</td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm
             text-gray-900 ">{{st.first_name }} {{st.last_name}}</td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm
             text-gray-900 ">{{st.submitted_at | date:'MMM dd EEE hh:mm a' }}</td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm
             text-gray-900 ">{{getSettlementDate(st.date) | date:'MMM dd EEE hh:mm a'}}</td>
              <td class="whitespace-nowrap border-b border-gray-200 p-2 text-sm
            font-medium text-gray-900 ">
                <span [ngClass]="{
                  'text-amber-500': st.final_status.trim() === 'pendingsettlement',
                   'text-red-500': st.final_status.trim() === 'canceled',
                   'text-green-500': st.final_status.trim() === 'complete'}">
                  {{st.final_status}}
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="whitespace-nowrap border-b border-gray-200 p-2 text-sm
            font-medium text-gray-900 ">
                <button mat-flat-button (click)="loadMore()" class="w-full" [disabled]="!settlements?.next">Load
                  More</button>
              </td>
            </tr>
          </tbody>
        </table>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 min-w-full"
          *ngIf="store.payment_gateway == 'WORLDNET'">
          <ng-container matColumnDef="orderCounter">
            <th mat-header-cell *matHeaderCellDef> Order Counter </th>
            <td mat-cell *matCellDef="let st"> {{ st.order_counter }} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let st"> SALE </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let st"> {{ st.amount | currency:'USD' }} </td>
          </ng-container>

          <ng-container matColumnDef="transactionId">
            <th mat-header-cell *matHeaderCellDef> Transaction ID </th>
            <td mat-cell *matCellDef="let st"> {{ st.transaction_id }} </td>
          </ng-container>

          <ng-container matColumnDef="cardType">
            <th mat-header-cell *matHeaderCellDef> Card Type </th>
            <td mat-cell *matCellDef="let st"> {{ st.card_type }} </td>
          </ng-container>

          <ng-container matColumnDef="cardNumber">
            <th mat-header-cell *matHeaderCellDef> Card Number </th>
            <td mat-cell *matCellDef="let st">{{ st.last4 }} </td>
          </ng-container>

          <ng-container matColumnDef="nameOnCard">
            <th mat-header-cell *matHeaderCellDef> Name on Card </th>
            <td mat-cell *matCellDef="let st">{{ st.first_name }}</td>
          </ng-container>

          <ng-container matColumnDef="submittedAt">
            <th mat-header-cell *matHeaderCellDef> Submitted At </th>
            <td mat-cell *matCellDef="let st"> {{ getSettlementDate(st.submitted_at) | date:'MMM dd EEE hh:mm a' }} </td>
          </ng-container>

          <ng-container matColumnDef="finalStatus">
            <th mat-header-cell *matHeaderCellDef> Final Status </th>
            <td mat-cell *matCellDef="let st">{{ st.final_status }}</td>
          </ng-container>

          <thead>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          </thead>
          <tbody>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </tbody>
        </table>

        <!-- ✅ MatPaginator for dynamic pagination -->
        <mat-paginator [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[20, 50, 100]"
          showFirstLastButtons>
        </mat-paginator>

        <br />
        <br />
      </div>
    </div>
  </div>

</div>