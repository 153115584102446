<div class="w-full  overflow-hidden xs:!overflow-auto pos-menu-page">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>


  <mat-form-field class="w-full search-filter-pos dark-bg-color" *ngIf="stepper.selectedIndex != 2">
    <mat-label>Search</mat-label>
    <input matInput #search placeholder="Search" [(ngModel)]="searchTerm" (focus)="onInputFieldFocus()"
      (keyup)="searchItems(searchTerm)" (keydown.enter)="preventEnter($event, search)"
      [disabled]="!validUserData?.sessionId" />
    <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm='';searchItems('')">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div class="back-title flex flex-row items-center xs:!pl-2 dark-bg-color px-2.5 pt-2.5"
    *ngIf="stepper.selectedIndex > 0">
    <!-- <button mat-flat-button (click)="goBack(stepper)"
      class="back-btn xs:!ml-[-3px]  xs:!justify-start dark-bg-color back-color-fullscreen"
      [disabled]="!validUserData?.sessionId">
      <mat-icon class="xs:!ml-[-18px] xs:!text-base xs:!h-[23px] back-icon">arrow_back_ios_new</mat-icon>
      Back
    </button> -->

    <button mat-button type="button" (click)="goBack(stepper)" class="go-back w-[94px] !h-[40px]"
      tabindex="-1"><mat-icon class="xs:!ml-[-18px] xs:!text-base xs:!h-[23px] back-icon">arrow_back_ios_new</mat-icon>
      Back</button>

    <div *ngIf="store.getFeatureFlag('modifier_tag_enablement')"
      class="current-title w-8/12	text-blue-600 text-center text-lg xs:!text-base xs:!text-center xs:!mr-2 xs:w-[64%]">
      {{ currentMenuTitle }}
    </div>

    <div *ngIf="!store.getFeatureFlag('modifier_tag_enablement')"
      class="current-title w-11/12	text-blue-600 text-center text-lg xs:!text-base xs:!text-center xs:!mr-2 xs:w-[64%]">
      {{ currentMenuTitle }}
    </div>

    <span *ngIf="store.getFeatureFlag('modifier_tag_enablement') && stepper.selectedIndex == 2"
      class="flex flex-row gap-2">
      <button mat-button type="button" (click)="selectedTag('No')" class="go-back w-[94px] !h-[40px]" [ngClass]="{
          'go-back': lastSelectedTag !== 'No',
          'goback': lastSelectedTag === 'No'
        }" tabindex="-1">No
      </button>

      <button mat-button type="button" (click)="selectedTag('Light')" class="go-back w-[94px] !h-[40px]" [ngClass]="{
          'go-back': lastSelectedTag !== 'Light',
          'goback': lastSelectedTag === 'Light'
        }" tabindex="-1">Light
      </button>

      <button mat-button type="button" (click)="selectedTag('Extra')" class="go-back w-[94px] !h-[40px]" [ngClass]="{
          'go-back': lastSelectedTag !== 'Extra',
          'goback': lastSelectedTag === 'Extra'
        }" tabindex="-1">Extra
      </button>

      <button mat-button type="button" (click)="selectedTag('On Side')"
        class="group-out-of-range:back w-[94px] !h-[40px]" [ngClass]="{
          'go-back': lastSelectedTag !== 'On Side',
          'goback': lastSelectedTag === 'On Side'
        }" tabindex="-1">On Side
      </button>
    </span>

    <button *ngIf="!store.getFeatureFlag('modifier_tag_enablement')" mat-button type="button" (click)="goToHome()"
      class="go-back" tabindex="-1"><mat-icon
        class="xs:!ml-[-18px] xs:!text-base xs:!h-[23px] home-icon xs:top-[3px]">keyboard_arrow_up</mat-icon>
      Home</button>
  </div>

  <mat-horizontal-stepper #stepper (selectionChange)="stepChange($event)">
    <mat-step label="Category">
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      <!-- <div class="grid grid-cols-5 gap-1 my-3 xs:grid-cols-3 overflow-x-hidden overflow-y-scroll overscroll-y-none xs:!m-1 h-[400px] 2xl:h-[500px] place-content-start"> -->
      <div *ngIf="store.getFeatureFlag('pos_menu_item_with_image'); then newPosButton else oldPosButton"></div>
      <ng-template #newPosButton>
        <div [ngStyle]="{'max-height': maxHeightMenuItem + 'px'}" class="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 xs:grid-cols-3  
                gap-1 my-3 overflow-x-hidden xs:!m-1 place-content-start" #swipeContainer>

          <div *ngIf="store.getFeatureFlag('menu_button_dynamic_height'); then dynamicHeight else notDynamicHeight">
          </div>
          <ng-template #dynamicHeight>
            <button class="relative border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md  
                        vxs:!text-xs vxs:rounded-md hover:bg-gray-100 text-ellipsis drop-shadow-md 
                        flex items-center justify-center" *ngFor="let menuGroup of getPaginatedCategories()"
              (click)="onMenuGroupSelect(menuGroup, menuGroup.name)" [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
              [style.height.px]="buttonHeight" [style.backgroundColor]="menuGroup.color">

              <div *ngIf="menuGroup.image; then imageShow else noImage"></div>
              <ng-template #imageShow>
                <img src="{{ menuGroup.image }}" class="absolute inset-0 w-full h-full object-cover" />
                <span class="absolute bottom-0 w-full bg-black bg-opacity-60 text-white text-center px-2 py-1"
                  [style.backgroundColor]="menuGroup.color" [style.color]="menuGroup.textColor">
                  {{ menuGroup.name }}
                </span>
              </ng-template>

              <ng-template #noImage>
                <div *ngIf="menuGroup.image_icon; then imageIconShow else noImageIcon"></div>
                <ng-template #imageIconShow>
                  <img src="{{ menuGroup.image_icon }}" class="absolute w-[25%]" />
                  <span class="absolute bottom-0 w-full bg-black bg-opacity-60 text-white text-center px-2 py-1"
                    [style.backgroundColor]="menuGroup.color" [style.color]="menuGroup.textColor">
                    {{ menuGroup.name }}
                  </span>
                </ng-template>
                <ng-template #noImageIcon>
                  <span class="relative p-2" [style.color]="menuGroup.textColor">
                    {{ menuGroup.name }}
                  </span>
                </ng-template>
              </ng-template>
            </button>
          </ng-template>
          <ng-template #notDynamicHeight>
            <button
              class="relative border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md vxs:!text-xs vxs:rounded-md hover:bg-gray-100 text-ellipsis drop-shadow-md 2xl:h-[240px] xl:h-[162px] lg:h-[135px] md:h-[130px] xs:h-[100px] vxs:h-[100px] flex items-center justify-center"
              *ngFor="let menuGroup of menuGroups" (click)="onMenuGroupSelect(menuGroup)"
              [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" [style.backgroundColor]="menuGroup.color">
              <div *ngIf="menuGroup.image; then imageShow else noImage"></div>
              <ng-template #imageShow>
                <img src="{{ menuGroup.image }}" class="absolute inset-0 w-full h-full object-cover" />
                <span class="absolute bottom-0 w-full bg-black bg-opacity-60 text-white text-center px-2 py-1"
                  [style.backgroundColor]="menuGroup.color" [style.color]="menuGroup.textColor">
                  {{ menuGroup.name }}
                </span>
              </ng-template>
              <ng-template #noImage>
                <div *ngIf="menuGroup.image_icon; then imageIconShow else noImageIcon"></div>
                <ng-template #imageIconShow>
                  <img src="{{ menuGroup.image_icon }}" class="absolute w-[25%]" />
                  <span class="absolute bottom-0 w-full bg-black bg-opacity-60 text-white text-center px-2 py-1"
                    [style.backgroundColor]="menuGroup.color" [style.color]="menuGroup.textColor">
                    {{ menuGroup.name }}
                  </span>
                </ng-template>
                <ng-template #noImageIcon>
                  <span class="relative p-2" [style.color]="menuGroup.textColor">{{ menuGroup.name }}</span>
                </ng-template>
              </ng-template>
            </button>
            <button
              class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md 2xl:h-[240px] xl:h-[162px] lg:h-[135px] md:h-[130px] xs:h-[100px] vxs:h-[100px] hover:bg-gray-100 text-ellipsis overflow-hidden p-2 drop-shadow-md hot-button"
              [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" (click)="addMisc()">
              Add Misc.
            </button>
            <button
              class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md 2xl:h-[240px] xl:h-[162px] lg:h-[135px] md:h-[130px] xs:h-[100px] vxs:h-[100px] hover:bg-gray-100 text-ellipsis overflow-hidden p-2 drop-shadow-md hot-button"
              [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" (click)="addGiftCard()">
              Gift Cards
            </button>
          </ng-template>
        </div>
      </ng-template>
      <ng-template #oldPosButton>
        <div *ngIf="store.getFeatureFlag('menu_button_dynamic_height'); then dynamicHeight else notDynamicHeight"></div>
        <ng-template #dynamicHeight>
          <div [ngStyle]="{'max-height': maxHeightMenuItem + 'px'}" class="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 xs:grid-cols-3  
      gap-1 my-3 overflow-hidden xs:!m-1 place-content-start" #swipeContainer>

            <button class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md  
          hover:bg-gray-100 text-ellipsis p-2 drop-shadow-md" *ngFor="let menuGroup of getPaginatedCategories()"
              (click)="onMenuGroupSelect(menuGroup, menuGroup.name)"
              [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" [style.height.px]="buttonHeight"
              [style.backgroundColor]="menuGroup.color" [style.color]="menuGroup.textColor">
              {{ menuGroup.name }}
            </button>
          </div>
        </ng-template>

        <ng-template #notDynamicHeight>
          <div [ngStyle]="{'max-height': maxHeightMenuItem + 'px'}" 
            class="grid grid-cols-5 gap-1 my-3 xs:grid-cols-3 overflow-x-hidden xs:!m-1 place-content-start">
            <button class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md h-20 hover:bg-gray-100
              text-ellipsis p-2 drop-shadow-md" *ngFor="let menuGroup of menuGroups"
              (click)="onMenuGroupSelect(menuGroup)" [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
              [style.backgroundColor]="menuGroup.color" [style.color]="menuGroup.textColor">{{
              menuGroup.name }}</button>
            <button class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md h-20 hover:bg-gray-100
              text-ellipsis p-2 drop-shadow-md hot-button" [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
              (click)="addMisc()">
              Add Misc.
            </button>
            <button class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md h-20 hover:bg-gray-100
              text-ellipsis p-2 drop-shadow-md hot-button" [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
              (click)="addGiftCard()">
              Gift Cards
            </button>
          </div>
        </ng-template>

      </ng-template>
      <div class="flex justify-center mt-3 space-x-2" *ngIf="store.feature_flag['menu_button_dynamic_height']">
        <label *ngFor="let page of totalPagesArray; let i = index">
          <input type="radio" name="pagination" [checked]="i === currentPage" (change)="goToPage(i)" class="appearance-none w-3 h-3 rounded-full border border-gray-500 
                          cursor-pointer checked:bg-black checked:border-black">
        </label>
      </div>
    </mat-step>

    <mat-step label="Item">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      <app-order-item-grid *ngIf="selectedMenuGroup" [validUserData]="validUserData" [items]="selectedMenuGroupNotSub"
        [selected]="selectedMenuGroup.id" (onSelectHandler)="onMenuItemGroupSelect($event)" [storeDynamivHeightFeatureFlag]="store.getFeatureFlag('menu_button_dynamic_height')" [isSearch]="false"></app-order-item-grid>
    </mat-step>

    <mat-step label="Modifier">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      <div *ngIf="store.getFeatureFlag('menu_button_dynamic_height'); then dynamicModHeight else notDynamicModHeight">
      </div>
      <!-- NEW IMPLEMENTATION -->
      <ng-template #dynamicModHeight>
        <div
          class="flex flex-row gap-3 items-stretch w-full pt-[15px] xs:p-3 xs:gap-1.5 overflow-x-scroll dark-bg-color">
          <button *ngIf="items?.length > 1"
            class="border border-gray-300 rounded shadow-lg text-lg xs:!text-base xs:!h-12 xs:!p-1 dark-bg-color p-2"
            (click)="onModifierGroupSelection('portions')"
            [ngClass]="{selected: isSelectedGroup('portions'), 'disabled-cta': !validUserData?.sessionId}"
            [style.color]="!validUserData?.sessionId ? '#8f8f8f' : ''"
            [style.background-color]="!validUserData?.sessionId ? '#e0e0e0' : ''">Portions
          </button>
          <button
            class="border border-gray-300 rounded shadow-lg text-lg xs:!text-base xs:!h-12 xs:!p-1 dark-bg-color p-2"
            *ngFor="let modGp of modifierGroups" (click)="onModifierGroupSelection(modGp)"
            [ngClass]="{'selected': isSelectedGroup(modGp), 'previously-selected': isPreviouslySelectedGroup(modGp.id) && !isSelectedGroup(modGp), 'disabled-cta': !validUserData?.sessionId}"
            [style.color]="!validUserData?.sessionId ? '#8f8f8f' : ''"
            [style.background-color]="!validUserData?.sessionId ? '#e0e0e0' : ''">{{ modGp.name }}
          </button>
          <button
            class="border border-gray-300 rounded shadow-lg text-lg xs:!text-base xs:!h-12 xs:!p-1 dark-bg-color p-2"
            (click)="onModifierGroupSelection('instruction')"
            [ngClass]="{selected: isSelectedGroup('instruction'), 'disabled-cta': !validUserData?.sessionId}"
            [style.color]="!validUserData?.sessionId ? '#8f8f8f' : ''"
            [style.background-color]="!validUserData?.sessionId ? '#e0e0e0' : ''">Special Instructions
          </button>
        </div>

        <div *ngIf="selectedModifierGroup === 'portions'">
          <div class="flex flex-row mb-2 justify-center pt-[10px]">
            <span class="bg-gray-300 rounded-full px-2 py-1 !pb-[0.2rem] text-xs !leading-normal hot-button">1
              REQUIRED</span>
          </div>
          <div class="grid-cols-3 xs:grid-cols-2 gap-2 xs:gap-1 grid">
            <div *ngFor="let menuItem of items"
              class="my-2 border border-gray-300 hover:bg-gray-100 bg-white drop-shadow-md xs:!rounded-md">
              <button (click)="onPortionSelect(menuItem)"
                class="w-full xs:!h-20 min-h-[52px] h-[92px] xs:!rounded-md xs:!text-xs dark-bg-color"
                [ngClass]="{selected: selectedItem?.id === menuItem.id, 'disabled-cta': !validUserData?.sessionId}">
                {{ menuItem.portion }} ({{ menuItem.base_price | currency }})
              </button>
            </div>
          </div>
        </div>

        <div
          *ngIf="selectedModifierGroup && selectedModifierGroup !== 'portions' && selectedModifierGroup !== 'instruction'">
          <div class="flex flex-row mb-2 justify-center pt-[10px]">
            <span class="bg-gray-300 rounded-full px-2 py-1 text-xs hot-button"
              *ngIf="selectedModifierGroup?.is_required && selectedModifierGroup?.max_mods > 0">
              {{ selectedModifierGroup.max_mods }} REQUIRED
            </span>
            <span class="bg-gray-300 rounded-full px-2 py-1 text-xs hot-button"
              *ngIf="!selectedModifierGroup?.is_required && selectedModifierGroup?.max_mods > 0">
              {{ selectedModifierGroup.max_mods }} OPTIONAL
            </span>
            <span class="bg-gray-300 rounded-full px-2 py-1 text-xs hot-button"
              *ngIf="selectedModifierGroup?.max_mods === -1 || selectedModifierGroup?.max_mods === 0">
              OPTIONAL
            </span>
          </div>
        </div>
        <div
          class="box-fixed-design box-fixed xs:!w-full grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 xs:grid-cols-3 gap-2 overflow-y-auto"
          *ngIf="selectedModifierGroup && selectedModifierGroup !== 'portions' && selectedModifierGroup !== 'instruction'"
          [ngStyle]="{'max-height': maxModHeight + 'px'}" #modSwipeContainer>
          <div *ngFor="let modifierOption of getPaginatedMod()"
            class="h-fit border border-gray-300 hover:bg-gray-100 bg-white drop-shadow-md xs:rounded-md">
            <button (mousedown)="onModifierMouseDown(selectedModifierGroup, modifierOption)"
              (mouseup)="onModifierMouseUp()" (mouseleave)="onModifierMouseLeave()"
              (click)="onSelectModifier(selectedModifierGroup, modifierOption)"
              (touchstart)="onModifierMouseDown(selectedModifierGroup, modifierOption)" (touchend)="onModifierMouseUp()"
              class="w-full xs:!h-20 min-h-[52px] xs:!text-xs px-2 xs:!text-xs dark-bg-color" [ngClass]="{
      selected: isSelectedModifier(modifierOption),
      'disabled-cta': !validUserData?.sessionId
    }" [style.height.px]="buttonModHeight">
              {{ modifierOption.name }} <br> ({{modifierOption.upcharge | currency}})
            </button>
            <div [ngClass]="{'disabled-cta': !validUserData?.sessionId}" class="left-right-radio-buttons"
              *ngIf="isSelectedModifier(modifierOption) && modifierOption.has_half_mod">
              <mat-button-toggle-group class="w-full !grid grid-cols-3"
                [ngClass]="{'disabled-cta': !validUserData?.sessionId}"
                (change)="setModifierDirection($event, modifierOption)">
                <mat-button-toggle [value]="item.key" *ngFor="let item of halfModOptions | keyvalue"
                  (click)="$event.stopImmediatePropagation()"
                  [checked]="getHalfModCheckedValue(modifierOption) === item.key"
                  [ngClass]="{'disabled-cta': !validUserData?.sessionId}" class="button-h">
                  <mat-icon *ngIf="item.value == 'LEFT'">contrast</mat-icon>
                  <mat-icon *ngIf="item.value == 'WHOLE'">brightness_1</mat-icon>
                  <mat-icon *ngIf="item.value == 'RIGHT'" class="rotate-180">contrast</mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="flex flex-row items-center justify-between"
              *ngIf="isSelectedModifier(modifierOption) &&modifierOption.has_quantity">
              <button mat-flat-button class="quantityButtons  hot-button"
                (click)="setModifierQuantities($event, modifierOption, '-')" [disabled]="!validUserData?.sessionId">-
              </button>
              <div class="quantityOptions hot-button">
                {{modifierQuantities && modifierQuantities[modifierOption.id] ? modifierQuantities[modifierOption.id]
                : 1}}
              </div>
              <button mat-flat-button class="quantityButtons hot-button"
                (click)="setModifierQuantities($event, modifierOption, '+')" [disabled]="!validUserData?.sessionId">+
              </button>
            </div>
            <p class="flex justify-center bg-[#86c1ff] text-white" *ngIf="selectedTags[modifierOption.id]">
              {{ selectedTags[modifierOption.id] }}
            </p>

          </div>
        </div>

        <div class="item-sub-category mt-0" *ngIf="selectedModifierGroup === 'instruction'">
          <div class="my-5 myb">
            <mat-form-field class="w-full">
              <mat-label>Special Instructions</mat-label>
              <textarea [formControl]="special_request" matInput rows="2" placeholder="Special Instructions..."
                [disabled]="!validUserData?.sessionId"></textarea>
            </mat-form-field>
          </div>
        </div>


      </ng-template>
      <ng-template #notDynamicModHeight>
        <!-- OLD IMPLEMENTATION -->
        <div [ngClass]="modifierDisplay ? 'horizontal-design': 'vertical-design'"
        [ngStyle]="{'max-height': maxHeightMenuItem + 'px'}" 
          class="dynamic-height gap-4 overflow-x-hidden overflow-y-auto overscroll-y-none  xs:!m-1">
          <div class="item-sub-category" *ngIf="items && items.length && !loading">
            <ng-container *ngIf="!modifierDisplay">
              <div class="flex flex-row items-center mb-1 xs:!mb-2">
                <span class="text-lg mr-2 xs:!text-base">Portions</span>
                <span class="bg-gray-300 rounded-full px-2 py-1 text-xs invisible xs:!visible hot-button">1
                  REQUIRED</span>
              </div>
              <div class="flex flex-row items-center mb-2 xs:!hidden">
                <span class="bg-gray-300 rounded-full px-2 py-1 !pb-[0.2rem] text-xs !leading-normal hot-button mb-2">1
                  REQUIRED</span>
              </div>
            </ng-container>

            <ng-container *ngIf="modifierDisplay">
              <div class="flex flex-row items-center mb-1 xs:!mb-2">
                <span class="text-lg mr-2 xs:!text-base">Portions</span>
                <span class="bg-gray-300 rounded-full px-2 py-1 text-xs invisible xs:!visible hot-button">1
                  REQUIRED</span>

                <div class="flex flex-row items-center mb-2 mt-2 xs:!hidden">
                  <span
                    class="bg-gray-300 rounded-full px-2 py-1 !pb-[0.2rem] text-xs !leading-normal hot-button mb-2">1
                    REQUIRED</span>
                </div>
              </div>
            </ng-container>

            <div class="grid-cols-5 xs:grid-cols-3 gap-1 xs:!grid box-fixed-design">
              <div *ngFor="let menuItem of items"
                class="my-2 border border-gray-300 hover:bg-gray-100 bg-white drop-shadow-md xs:!rounded-md">
                <button (click)="onPortionSelect(menuItem)"
                  class="w-full xs:!h-20 min-h-[52px] xs:!rounded-md xs:!text-xs dark-bg-color"
                  [ngClass]="{selected: selectedItem?.id === menuItem.id, 'disabled-cta': !validUserData?.sessionId}">
                  {{ menuItem.portion }} ({{ menuItem.base_price | currency }})
                </button>
              </div>
            </div>
          </div>
          <mat-progress-spinner *ngIf="modifiersLoading" mode="indeterminate"></mat-progress-spinner>
          <div class="item-sub-category modifiers mb-2" *ngFor="let modifierGroup of modifierGroups; let i = index">

            <ng-container *ngIf="!modifierDisplay">
              <div class="mb-1 xs:!mb-2 flex flex-wrap mb-2">
                <span class="text-lg mr-2 xs:!text-base">{{ modifierGroup.name }}</span>
                <div class="flex flex-row items-center hidden xs:!block">
                  <span class="bg-gray-300 rounded-full px-2 py-1 text-xs hot-button"
                    *ngIf="modifierGroup.is_required === true && modifierGroup.max_mods > 0">
                    {{ modifierGroup.max_mods }} REQUIRED</span>
                  <span class="bg-gray-300 rounded-full px-2 py-1 text-xs hot-button"
                    *ngIf="modifierGroup.is_required === false && modifierGroup.max_mods > 0">
                    {{ modifierGroup.max_mods }} OPTIONAL</span>
                  <span class="bg-gray-300 rounded-full px-2 py-1 text-xs hot-button"
                    *ngIf="modifierGroup.max_mods === -1 || modifierGroup.max_mods === 0">OPTIONAL</span>
                </div>
              </div>
              <div class="flex flex-wrap xs:!hidden mb-2">
                <div class="flex flex-row items-center">
                  <span class="bg-gray-300 rounded-full px-2 py-1 !pb-[0.2rem] text-xs !leading-normal hot-button mb-2"
                    *ngIf="modifierGroup.is_required === true && modifierGroup.max_mods > 0">
                    {{ modifierGroup.max_mods }} REQUIRED</span>
                  <span class="bg-gray-300 rounded-full px-2 py-1 !pb-[0.2rem] text-xs !leading-normal hot-button mb-2"
                    *ngIf="modifierGroup.is_required === false && modifierGroup.max_mods > 0">
                    {{ modifierGroup.max_mods }} OPTIONAL</span>
                  <span class="bg-gray-300 rounded-full px-2 py-1 !pb-[0.2rem] text-xs !leading-normal hot-button mb-2"
                    *ngIf="modifierGroup.max_mods === -1 || modifierGroup.max_mods === 0">OPTIONAL</span>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="modifierDisplay">
              <div class="mb-1 xs:!mb-2 flex flex-wrap mt-1">
                <span class="text-lg mr-2 xs:!text-base">{{ modifierGroup.name }}</span>
                <div class="flex flex-row items-center hidden xs:!block ">
                  <span class="bg-gray-300 rounded-full px-2 py-1 text-xs hot-button"
                    *ngIf="modifierGroup.is_required === true && modifierGroup.max_mods > 0">
                    {{ modifierGroup.max_mods }} REQUIRED</span>
                  <span class="bg-gray-300 rounded-full px-2 py-1 text-xs hot-button"
                    *ngIf="modifierGroup.is_required === false && modifierGroup.max_mods > 0">
                    {{ modifierGroup.max_mods }} OPTIONAL</span>
                  <span class="bg-gray-300 rounded-full px-2 py-1 text-xs hot-button"
                    *ngIf="modifierGroup.max_mods === -1 || modifierGroup.max_mods === 0">OPTIONAL</span>
                </div>
                <div class="flex flex-wrap xs:!hidden">
                  <div class="flex flex-row items-center">
                    <span
                      class="bg-gray-300 rounded-full px-2 py-1 !pb-[0.2rem] text-xs !leading-normal hot-button mb-2"
                      *ngIf="modifierGroup.is_required === true && modifierGroup.max_mods > 0">
                      {{ modifierGroup.max_mods }} REQUIRED</span>
                    <span
                      class="bg-gray-300 rounded-full px-2 py-1 !pb-[0.2rem] text-xs !leading-normal hot-button mb-2"
                      *ngIf="modifierGroup.is_required === false && modifierGroup.max_mods > 0">
                      {{ modifierGroup.max_mods }} OPTIONAL</span>
                    <span
                      class="bg-gray-300 rounded-full px-2 py-1 !pb-[0.2rem] text-xs !leading-normal hot-button mb-2"
                      *ngIf="modifierGroup.max_mods === -1 || modifierGroup.max_mods === 0">OPTIONAL</span>
                  </div>
                </div>
              </div>
            </ng-container>


            <div class="grid-cols-5 xs:grid-cols-3 gap-1 xs:!grid box-fixed-design box-fixed xs:!w-full">
              <div *ngFor="let modifierOption of modifierGroup.options"
                class="h-fit border border-gray-300 hover:bg-gray-100 bg-white drop-shadow-md xs:rounded-md">
                <button (mousedown)="onModifierMouseDown(modifierGroup, modifierOption)" (mouseup)="onModifierMouseUp()"
                  (mouseleave)="onModifierMouseLeave()" (click)="onSelectModifier(modifierGroup, modifierOption)"
                  (touchstart)="onModifierMouseDown(modifierGroup, modifierOption)" (touchend)="onModifierMouseUp()"
                  class="w-full xs:!h-20 min-h-[52px] xs:!text-xs px-2 xs:!text-xs dark-bg-color" [ngClass]="{
          selected: isSelectedModifier(modifierOption),
          'disabled-cta': !validUserData?.sessionId
        }">
                  {{ modifierOption.name }} ({{modifierOption.upcharge | currency}})
                </button>
                <div [ngClass]="{'disabled-cta': !validUserData?.sessionId}" class="left-right-radio-buttons"
                  *ngIf="isSelectedModifier(modifierOption) && modifierOption.has_half_mod">
                  <mat-button-toggle-group class="w-full d-grid grid-auto-col"
                    [ngClass]="{'disabled-cta': !validUserData?.sessionId}"
                    (change)="setModifierDirection($event, modifierOption)">
                    <mat-button-toggle [value]="item.key" *ngFor="let item of halfModOptions | keyvalue"
                      (click)="$event.stopImmediatePropagation()"
                      [checked]="getHalfModCheckedValue(modifierOption) === item.key"
                      [ngClass]="{'disabled-cta': !validUserData?.sessionId}">
                      <mat-icon *ngIf="item.value == 'LEFT'">contrast</mat-icon>
                      <mat-icon *ngIf="item.value == 'WHOLE'">radio_button_checked</mat-icon>
                      <mat-icon *ngIf="item.value == 'RIGHT'" class="rotate-180">contrast</mat-icon>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
                <div class="flex flex-row items-center justify-between"
                  *ngIf="isSelectedModifier(modifierOption) &&modifierOption.has_quantity">
                  <button mat-flat-button class="quantityButtons  hot-button"
                    (click)="setModifierQuantities($event, modifierOption, '-')"
                    [disabled]="!validUserData?.sessionId">-
                  </button>
                  <div class="quantityOptions hot-button">
                    {{modifierQuantities && modifierQuantities[modifierOption.id] ?
                    modifierQuantities[modifierOption.id]
                    : 1}}
                  </div>
                  <button mat-flat-button class="quantityButtons hot-button"
                    (click)="setModifierQuantities($event, modifierOption, '+')"
                    [disabled]="!validUserData?.sessionId">+
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="item-sub-category mt-0">
            <span class="text-lg mb-3">Item Quantity</span>
            <div class="flex flex-row items-center justify-center">
              <button
                class="w-20 h-14 xs:!h-12 border border-gray-300 bg-white hover:bg-gray-100 text-lg mr-2 hot-button"
                (click)="decreaseQuantity()" [disabled]="!validUserData?.sessionId">-
              </button>
              <input class="w-20 h-14 xs:!h-12 text-center text-xl border border-gray-300 hot-button" type="tel"
                [(ngModel)]="selectedItemQuantity" [disabled]="!validUserData?.sessionId" />
              <!--          <button class="w-14 h-14 text-xl">{{selectedItemQuantity}}</button>-->
              <button
                class="w-20 h-14 xs:!h-12 border border-gray-300 bg-white hover:bg-gray-100 text-lg ml-2 hot-button"
                (click)="increaseQuantity()" [disabled]="!validUserData?.sessionId">+
              </button>
            </div>
            <div class="my-5 myb">
              <mat-form-field class="w-full">
                <mat-label>Item Instructions</mat-label>
                <textarea [formControl]="special_request" matInput rows="2" placeholder="Item Instructions..."
                  [disabled]="!validUserData?.sessionId"></textarea>
              </mat-form-field>
            </div>
            <button class="w-full lab-bg-blue text-white	h-10 font-semibold" type="button"
              (click)="checkPOSPermissionSubmit($event)"
              *ngIf="stepper.selectedIndex === 2 && validUserData?.sessionId">
              <span>{{editMode ? "Update" : "Add"}}</span>
            </button>
          </div>
        </div>
      </ng-template>
    </mat-step>

    <mat-step label="Search">
      <div *ngFor="let menuGroup of menuGroups" class="mt-3">
        <p class="text-2xl mb-3 w-full lab-text-blue text-center my-2">{{ menuGroup.name }}</p>
        <span *ngFor="let subgroup of menuGroup.subGroups">
          <p class="text-1xl mb-2 lab-text-blue my-2 xs:pl-[5px] vxs:pl-[5px]" *ngIf="subgroup.name != 0">{{
            subgroup.name }}</p>
          <app-order-item-grid [validUserData]="validUserData" [items]="subgroup.items"
            (onSelectHandler)="onMenuItemGroupSelectFromSearch($event)" [storeDynamivHeightFeatureFlag]="store.getFeatureFlag('menu_button_dynamic_height')" [isSearch]="true"></app-order-item-grid>
        </span>
      </div>
    </mat-step>

    <mat-step label="Misc">
      <div class="row xs:!pl-2 xs:!pr-2" id="misc-items">
        <p class="text-lg mb-3">Add Miscellaneous</p>
        <div class="overflow-auto w-full">
          <div class="flex flex-row" *ngFor="let fg of selectedOrderMiscFromGroup?.controls; let i = index">
            <div [formGroup]="fg" class="flex flex-row items-center mb-2">
              <mat-form-field class="width-15" subscriptSizing="dynamic">
                <mat-label>Quantity</mat-label>
                <input formControlName="quantity" matInput placeholder="Qty" type="number"
                  [disabled]="!validUserData?.sessionId" />
              </mat-form-field>

              <mat-form-field class="width-30" subscriptSizing="dynamic">
                <mat-label>Name</mat-label>
                <input formControlName="item_name" matInput placeholder="Name" [disabled]="!validUserData?.sessionId" />
              </mat-form-field>

              <mat-form-field class="width-20" subscriptSizing="dynamic">
                <mat-label>Price</mat-label>
                <input formControlName="price" matInput placeholder="Price" [disabled]="!validUserData?.sessionId" />
              </mat-form-field>

              <mat-checkbox formControlName="is_taxable">Taxable</mat-checkbox>
              <div class="form-ops">
                <button mat-icon-button (click)="delete(i, fg.value.id); (false)"
                  [disabled]="!validUserData?.sessionId">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="action xs:!contents">
          <button class="w-full xs:!w-[auto]" mat-stroked-button color="primary" type="button" (click)="add(); false"
            [disabled]="!validUserData?.sessionId">
            Add Item
          </button>

          <button class="xs:!w-[50vh] bg xs:!text-xl xs:!h-14 xs:!mt-5" mat-stroked-button color="primary"
            (click)="submitMisc()" [disabled]="!validUserData?.sessionId">
            {{ orderWithPayment?.misc_items.length > 0 ? "UPDATE" : "ADD" }}
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step label="SubCat">
      <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
      <app-order-sub-cat-grid *ngIf="selectedMenuGroupSub" [validUserData]="validUserData"
        [subcat]="selectedMenuGroupSub" [storeMenuFeatureFlag]="store.getFeatureFlag('pos_menu_item_with_image')" [storeDynamivHeightFeatureFlag]="store.getFeatureFlag('menu_button_dynamic_height')"
        [selected]="selectedMenuGroup.id" (onSubSelectHandler)="onSubCatGroup($event)"></app-order-sub-cat-grid>
    </mat-step>

    <mat-step label="Gift">
      <ng-container *ngIf="stepper.selectedIndex === 6">
        <div class="row xs:!pl-2 xs:!pr-2" id="gift-card">
          <p class="text-lg mb-3">Gift Card</p>
          <div *ngIf="store.getFeatureFlag('pos_menu_item_with_image'); then newGiftButton else oldGiftButton"></div>
          <ng-template #newGiftButton>
            <div [ngStyle]="{'max-height': maxHeightMenuItem + 'px'}" 
              class="grid 2xl:grid-cols-6 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-5 xs:grid-cols-3  gap-1 my-3  overflow-x-hidden  xs:!m-1 place-content-start">
              <button
                class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md 2xl:h-[240px] xl:h-[162px] lg:h-[135px] md:h-[130px] xs:h-[100px] vxs:h-[100px] hover:bg-gray-100 text-ellipsis overflow-hidden p-2 drop-shadow-md hot-button"
                [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" (click)="purchaseGiftCard()">
                Sell Card
              </button>
              <button
                class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md 2xl:h-[240px] xl:h-[162px] lg:h-[135px] md:h-[130px] xs:h-[100px] vxs:h-[100px] hover:bg-gray-100 text-ellipsis overflow-hidden p-2 drop-shadow-md hot-button"
                [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" (click)="addGiftCardValue()">
                Add Value ($)
              </button>
              <button
                class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md 2xl:h-[240px] xl:h-[162px] lg:h-[135px] md:h-[130px] xs:h-[100px] vxs:h-[100px] hover:bg-gray-100 text-ellipsis overflow-hidden p-2 drop-shadow-md hot-button"
                [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }" (click)="giftCardBalanceInquiry()">
                Balance Inquiry
              </button>
            </div>
          </ng-template>
          <ng-template #oldGiftButton>
            <div [ngStyle]="{'max-height': maxHeightMenuItem + 'px'}" 
              class="grid grid-cols-5 gap-1 my-3 xs:grid-cols-3 overflow-x-hidden xs:!m-1 place-content-start">
              <button class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md 2xl:h-[240px] xl:h-[162px] lg:h-[135px] md:h-[130px] xs:h-[100px] vxs:h-[100px] hover:bg-gray-100
            text-ellipsis p-2 drop-shadow-md hot-button" [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
                (click)="purchaseGiftCard()">
                Sell Card
              </button>
              <button class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md 2xl:h-[240px] xl:h-[162px] lg:h-[135px] md:h-[130px] xs:h-[100px] vxs:h-[100px] hover:bg-gray-100
            text-ellipsis p-2 drop-shadow-md hot-button" [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
                (click)="addGiftCardValue()">
                Add Value ($)
              </button>
              <button class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md 2xl:h-[240px] xl:h-[162px] lg:h-[135px] md:h-[130px] xs:h-[100px] vxs:h-[100px] hover:bg-gray-100
            text-ellipsis p-2 drop-shadow-md hot-button" [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
                (click)="giftCardBalanceInquiry()">
                Balance Inquiry
              </button>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </mat-step>
  </mat-horizontal-stepper>
  <div class="flex justify-center space-x-2"
    *ngIf="store.getFeatureFlag('menu_button_dynamic_height') && selectedModifierGroup && selectedModifierGroup?.options?.length != 0 && selectedModifierGroup !== 'portions' && selectedModifierGroup !== 'instruction' && stepper.selectedIndex == 2">
    <label *ngFor="let page of totalModPagesArray; let i = index">
      <input type="radio" name="pagination" [checked]="i === currentModPage" (change)="goToModPage(i)" class="appearance-none w-3 h-3 rounded-full border border-gray-500 
                          cursor-pointer checked:bg-black checked:border-black">
    </label>
  </div>
  <div class="fixed bottom-0 right-0 w-full flex z-10" *ngIf="stepper.selectedIndex === 2">
    <div *ngIf="store.getFeatureFlag('menu_button_dynamic_height'); then dynamicBtnHeight else notDynamicBtnHeight">
    </div>
    <ng-template #dynamicBtnHeight>
      <button type="button" (click)="checkPOSPermissionSubmit($event)"
        class="lab-bg-blue h-20 xs:!h-14 text-2xl font-medium text-white w-4/5 s-fixed" [ngClass]="{
          'add-button-full-width': !drawerOpened,
          'add-button-half-width': drawerOpened,
          'disabled-ctas': !validUserData?.sessionId || !isValid
        }">
        <span class="grid grid-cols-12" *ngIf="smryOpen">
          <span class="add-size col-span-6">&nbsp;</span>
          <span class="add-size text-center col-span-4" [ngClass]="">{{editMode ? "Update" : "Add"}}</span>
          <span class="pr-5 col-span-2">{{totalPrice | currency}}</span>
        </span>
        <span class="grid grid-cols-12" *ngIf="!smryOpen">
          <span class="add-size col-span-5">&nbsp;</span>
          <span class="add-size text-center col-span-5" [ngClass]="">{{editMode ? "Update" : "Add"}}</span>
          <span class="pr-5 col-span-2">{{totalPrice | currency}}</span>
        </span>
      </button>

      <!-- 20% Width Other Content (Item Quantity & Instructions) -->
      <div class="w-1/5 bg-gray-300 h-20 xs:!h-14 flex flex-col items-center justify-center text-black p-2">
        <div class="item-sub-category mt-0">
          <div class="flex flex-row items-center justify-center">
            <button
              class="w-[4.5rem] h-[4rem] xs:!h-8 border border-gray-300 bg-white hover:bg-gray-100 text-[40px] mr-2 hot-button"
              (click)="decreaseQuantity()" [disabled]="!validUserData?.sessionId">-
            </button>
            <input class="w-[4.5rem] h-[4rem] xs:!h-8 text-center text-[24px] border border-gray-300 hot-button"
              type="tel" [(ngModel)]="selectedItemQuantity" [disabled]="!validUserData?.sessionId" />
            <button
              class="w-[4.5rem] h-[4rem] xs:!h-8 border border-gray-300 bg-white hover:bg-gray-100 text-[24px] ml-2 hot-button"
              (click)="increaseQuantity()" [disabled]="!validUserData?.sessionId">+
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #notDynamicBtnHeight>
      <button type="button" (click)="checkPOSPermissionSubmit($event)"
        class="fixed bottom-0 right-0 lab-bg-blue h-20 xs:!h-14 text-2xl font-medium text-white w-full z-10 s-fixed"
        *ngIf="stepper.selectedIndex === 2" [ngClass]="{
      'add-button-full-width': !drawerOpened,
      'add-button-half-width': drawerOpened,
      'disabled-ctas': !validUserData?.sessionId || !isValid
    }">
        <span class="grid grid-cols-3">
          <span class="add-size">&nbsp;</span>
          <span class="add-size">{{editMode ? "Update" : "Add"}}</span>
          <span class="pr-5" fxFlexAlign="end">{{totalPrice | currency}}</span>
        </span>
      </button>
    </ng-template>
  </div>
</div>