import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NMISettlementService } from '../_services/nmi-settlement.service';
import { StoreService } from '../_services/store.service';
import { Store } from '../_models/store';
import { NmiSettlementAction, NmiSettlementAggregate, NmiSettlementPaged } from '../_models/nmi-settlement';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.scss']
})
export class SettlementsComponent implements AfterViewInit {

  orderCounterFilter: string;
  store: Store;
  settlements: NmiSettlementPaged;
  aggregates: any;
  payroc_cloud_settlements: any;
  settlementForm = this.formBuilder.group({
    filter: [''],
    start_date: [''],
    end_date: ['']
  });

  displayedColumns: string[] = [
    'orderCounter', 'action', 'amount', 'transactionId', 
    'cardType', 'cardNumber', 'nameOnCard', 'submittedAt', 'finalStatus'
  ];

  // ✅ Added dataSource for pagination
  dataSource = new MatTableDataSource<any>();

  // ✅ Added paginator reference
  @ViewChild(MatPaginator) paginator: MatPaginator;

  totalRecords = 0;  // ✅ Stores total records from API
  pageSize = 20;     // ✅ Default page size
  currentPage = 1;   // ✅ Default page number

  constructor(private settlementService: NMISettlementService,
              private storeService: StoreService,
              private formBuilder: FormBuilder) {

    this.storeService.current.subscribe(store => {
      if (store) {
        this.store = store;
        this.onSubmit();
      }
    });
  }

  // ✅ Initialize paginator after view init
  ngAfterViewInit() {
    this.paginator.page.subscribe(() => {
      this.currentPage = this.paginator.pageIndex + 1;
      this.pageSize = this.paginator.pageSize;
      this.onSubmit();
    });
  }

  clearSearch() {
    this.orderCounterFilter = '';
    this.settlementForm.reset();
    this.dataSource.data = [];  // ✅ Clear table data
  }

  onSubmit() {
    console.log(this.settlementForm.value);
    console.log(this.store.payment_gateway, "sssssssssssss");

    if (this.store.payment_gateway == "WORLDNET") {
        if (!this.store || !this.store.id) {
            console.error("❌ store_id is missing!");
            return;
        }

        const params = {
            store_id: this.store.id,  // ✅ Ensure store_id is always passed
            page: this.paginator ? this.paginator.pageIndex + 1 : 1,  // ✅ Get current page from paginator
            page_size: this.paginator ? this.paginator.pageSize : 20, // ✅ Get selected page size
            filter: this.settlementForm.value.filter || "",  // ✅ Default values
            start_date: this.settlementForm.value.start_date || "",
            end_date: this.settlementForm.value.end_date || ""
        };

        console.log("🔍 API Request Params:", params);

        this.settlementService.getPayrocCloudSettlements(this.store.id, params).subscribe(data => {
            this.payroc_cloud_settlements = data;
            this.dataSource.data = this.payroc_cloud_settlements.transactions;

            // ✅ Update paginator values
            this.totalRecords = this.payroc_cloud_settlements.total_count || 0;
            this.pageSize = this.payroc_cloud_settlements.page_size || 10;
            
            if (this.paginator) {
                this.paginator.length = this.totalRecords;
                this.paginator.pageSize = this.pageSize;
            }

            console.log(`✅ API Response - Total Records: ${this.totalRecords}, Page Size: ${this.pageSize}`);
        });
    }
    else {
        this.settlementService.getSettlements(this.store.id, this.settlementForm.value).subscribe(data => {
            this.settlements = data;
        });

        this.settlementService.getSettlementsAggregate(this.store.id, this.settlementForm.value).subscribe(data => {
            this.aggregates = data;
        });
    }
}

  loadMore() {
    if (this.settlements.next) {
      if (this.settlements.next && !this.settlements.next.startsWith('https://')) {
        this.settlements.next = this.settlements.next.replace('http://', 'https://');
      }
      this.settlementService.getNextPage(this.settlements.next).subscribe(result => {
        this.settlements.results.push(...result.results);
        this.settlements.next = result.next;
      });
    }
  }

  getSettlementDate(dateStr: string) {
    return moment(dateStr, 'YYYYMMDDhhmmss').toDate();
  }

  getSettledBatches() {
    if (this.aggregates) {
      return this.aggregates.filter(agg => agg.settlement_date !== null);
    } else {
      return [];
    }
  }

  getNonSetledBatches() {
    if (this.aggregates) {
      return this.aggregates.filter(agg => agg.settlement_date === null);
    } else {
      return [];
    }
  }

  getFee(ag: NmiSettlementAggregate) {
    return parseFloat(ag.pos_service_charge)
      + parseFloat(ag.pos_service_charge_from_dollar)
      + parseFloat(ag.pos_service_charge_from_percent)
      + parseFloat(ag.online_service_charge)
      + parseFloat(ag.online_service_charge_from_dollar)
      + parseFloat(ag.online_service_charge_from_percent);
  }
}
