import {ErrorHandler, Injectable} from '@angular/core';
// import {ErrorLoggingService} from '../_services/error-logging.service';
import * as LogRocket from 'logrocket';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {

  // constructor(private logger: ErrorLoggingService) {
  //   super();
  // }

  // handleError(error) {
  //   this.logger.logError(error);
  //   super.handleError(error);
  // }
  handleError(error: any) {
    console.error("🚨 UI Error Captured:", error); // Logs error to the browser console

    // Ensure LogRocket is initialized before logging errors
    if ((window as any).LogRocket && typeof LogRocket.captureException === "function") {
      LogRocket.captureException(error);

      // Capture session replay link for debugging
      LogRocket.getSessionURL(sessionURL => {
        console.log("🔁 UI Error - Session Replay:", sessionURL);
        LogRocket.log(`🔁 UI Error - Session Replay: ${sessionURL}`);
      });
    }

    // Re-throw the error so Angular can still handle it
    // throw error;
  }
}
