import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigitalDisplayService } from 'src/app/_services/digital-display.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-master-category-dialog',
  templateUrl: './master-category-dialog.component.html',
  styleUrls: ['./master-category-dialog.component.scss']
})
export class MasterCategoryDialogComponent implements OnInit {
  submitted = false;
  editMasterCategoryForm: UntypedFormGroup;
  getId: any;
  isLoading = false;
  newScreen: boolean;
  hideCategory: boolean;
  storeId: any;
  searchControl = new FormControl('');
  categorys = new UntypedFormControl('');
  category_list_data: any;
  // categorys = new FormControl('');
  showSelete: any = [];
  emojiLists: any;
  allSelected = false;
  originalCategoryList: any[] = [];

  constructor(
    public fb: UntypedFormBuilder,
    private digitalDisplayService: DigitalDisplayService,
    public dialogRef: MatDialogRef<MasterCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("datadata", data);
  }

  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.categoryList();
    this.cat()
    this.getId = this.data.id;
    this.newScreen = this.data.new_screen;
    this.hideCategory = this.data.hide_category;
    this.editMasterCategoryForm = this.fb.group({
      name: [this.data.name],
      priority: [this.data.priority],
      new_screen: [this.data.new_screen],
      hide_category: [this.data.hide_category],
      categorys: [this.categorys],
      font_color: [this.data.font_color],
      emoji: [this.data.emoji],
      store_id: [this.storeId],
    })
    this.emojiList();

    // Add this to make the search reactive
    this.searchControl.valueChanges.pipe(
      debounceTime(300) // Waits for 300ms after the user stops typing
    ).subscribe(value => {
      this.filterCategories(value);
    });

    // Store the original list when you first get the data
    this.originalCategoryList = [...this.category_list_data];
  }

  onNoClick() {
    this.dialogRef.close();
  }

  cat() {
    var cat_data = []
    for (let j = 0; j < this.data.categorys.length; j++) {
      const element = this.data.categorys[j].id;
      // this.showSelete.push(element);
      cat_data.push(element)
    }
    this.categorys.setValue(cat_data);

  }

  toggleAllSelection1() {
    if (this.allSelected) {
      this.searchControl.setValue('');
      this.categorys.setValue([]);
    } else {
      // Select all items in the search results
      const filteredIds = this.category_list_data
        .filter(item => {
          const itemText = (item.name || '').toString().toLowerCase();
          const searchText = (this.searchControl.value || '').toLowerCase();
          return itemText.includes(searchText);
        })
        .map(topping => topping.id);
      this.categorys.setValue(filteredIds);
    }
    this.allSelected = !this.allSelected;
  }

  toggleSelection1(itemId: any) {
    const selectedIds = this.categorys.value || [];

    if (selectedIds.includes(itemId)) {
      this.categorys.setValue(selectedIds.filter(id => id !== itemId));
    } else {
      this.categorys.setValue([...selectedIds, itemId]);
    }
  }

  newScreenCheck(a) {
    this.editMasterCategoryForm.value.new_screen = a.checked;
  }

  hideCategoryCheck(a) {
    this.editMasterCategoryForm.value.hide_category = a.checked;
  }

  editMasterCategoryFormSubmit() {
    this.submitted = true;
    if (this.editMasterCategoryForm.invalid) {
      return;
    }
    this.editMasterCategoryForm.value.categorys = this.categorys.value
    this.isLoading = true;
    this.digitalDisplayService.editMasterCategory(this.getId, this.editMasterCategoryForm.value).subscribe(data => {
      const resData = { yesSubmit: "YesSubmit" };
      this.isLoading = false;

      this.dialogRef.close(resData);
    })
  }

  categoryList() {
    this.digitalDisplayService.CategoriesOtionList(this.storeId).subscribe(data => {
      this.category_list_data = data.data;
      this.originalCategoryList = [...data.data];
    })
  }

  emojiList() {
    this.digitalDisplayService.getEmojiList().subscribe((data: any) => {
      this.emojiLists = data.data;
    });
  }

  filterCategories(searchValue: string) {
    if (!searchValue) {
      this.category_list_data = [...this.originalCategoryList];
      return;
    }
    
    // Trim the search value to handle multiple spaces
    const searchTerm = searchValue.trim().toLowerCase();
    
    this.category_list_data = this.originalCategoryList.filter(category => {
      const categoryName = (category.name || '').toLowerCase();
      // Split search term by spaces to search for multiple words
      const searchTerms = searchTerm.split(' ').filter(term => term.length > 0);
      
      // Check if all search terms are included in the category name
      return searchTerms.every(term => categoryName.includes(term));
    });
  }
}
