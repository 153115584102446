<div *ngIf="storeDynamivHeightFeatureFlag; then dynamicheight else noDynamicHeight"></div>
<ng-template #dynamicheight>
  <div *ngIf="storeMenuFeatureFlag; then newSubCatButton else oldSubCatButton"></div>
  <ng-template #newSubCatButton>
    <div [ngStyle]="{'max-height': maxHeightMenuItem + 'px'}" class="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 xs:grid-cols-3  
    gap-1 my-3 overflow-x-hidden xs:!m-1 place-content-start" #swipeSubCatContainer>
      <button class="relative border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md  
            vxs:!text-xs vxs:rounded-md hover:bg-gray-100 text-ellipsis drop-shadow-md 
            flex items-center justify-center" *ngFor="let sub_ctaegory of getPaginatedSubCategories()"
        (click)="onSubSelectHandler.next(sub_ctaegory)" [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
        [style.height.px]="buttonHeight">

        <div *ngIf="sub_ctaegory.image; then imageShow else noImage"></div>
        <ng-template #imageShow>
          <img src="{{ sub_ctaegory.image }}" class="absolute inset-0 w-full h-full object-cover" />
          <span class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white px-2 py-1">{{
            sub_ctaegory.name }}</span>
        </ng-template>
        <ng-template #noImage>
          <span class="relative p-2">{{ sub_ctaegory.name }}</span>
        </ng-template>
      </button>
    </div>
  </ng-template>
  <ng-template #oldSubCatButton>
    <div [ngStyle]="{'max-height': maxHeightMenuItem + 'px'}" class="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 xs:grid-cols-3  
      gap-1 my-3 overflow-hidden xs:!m-1 place-content-start" #swipeSubCatContainer>

      <button class="border border-gray-300 bg-white text-lg xs:!text-xs xs:rounded-md  
          hover:bg-gray-100 text-ellipsis p-2 drop-shadow-md" *ngFor="let sub_ctaegory of subcat"
        (click)="onSubSelectHandler.next(sub_ctaegory)" [ngClass]="{ 'disabled-cta': !validUserData?.sessionId }"
        [style.height.px]="buttonHeight">
        {{ sub_ctaegory.name }}
      </button>
    </div>
  </ng-template>
  <div class="flex justify-center mt-3 space-x-2">
    <label *ngFor="let page of totalPagesArray; let i = index">
      <input type="radio" name="pagination" [checked]="i === currentPage" (change)="goToPage(i)" class="appearance-none w-3 h-3 rounded-full border border-gray-500 
                      cursor-pointer checked:bg-black checked:border-black">
    </label>
  </div>
</ng-template>

<ng-template #noDynamicHeight>
  <div *ngIf="storeMenuFeatureFlag; then newSubCatButton else oldSubCatButton"></div>
  <ng-template #newSubCatButton>
    <mat-grid-list [cols]="breakpoint" (window:resize)="onResize($event)" [rowHeight]="heightBox" [gutterSize]="'10px'"
      class="grid-list mt-2 xs:mt-[5px] xs:mr-[2px] xs:mb-[7px] xs:ml-[5px]">
      <mat-grid-tile *ngFor="let sub_ctaegory of subcat" class="submenu-shadow"
        (click)="onSubSelectHandler.next(sub_ctaegory)">
        <button class="menu-item2 submenu-btn-border relative">
          <div *ngIf="sub_ctaegory.image; then imageShow else noImage"></div>
          <ng-template #imageShow>
            <img src="{{ sub_ctaegory.image }}" class="absolute inset-0 w-full h-full object-cover" />
            <span class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white px-2 py-1">{{
              sub_ctaegory.name }}</span>
          </ng-template>
          <ng-template #noImage>
            <span class="relative p-2">{{ sub_ctaegory.name }}</span>
          </ng-template>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </ng-template>
  <ng-template #oldSubCatButton>
    <mat-grid-list [cols]="breakpoint" (window:resize)="onResize($event)" [rowHeight]="heightBox" [gutterSize]="'10px'"
      class="grid-list mt-2">
      <mat-grid-tile *ngFor="let sub_ctaegory of subcat" class="submenu-shadow"
        (click)="onSubSelectHandler.next(sub_ctaegory)">
        <button class="menu-item2 submenu-btn-border">
          <span>{{ sub_ctaegory.name }}</span>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </ng-template>
</ng-template>