<div class="fixed inset-0 bg-white flex flex-col items-center justify-start p-6 w-full h-full" id="darkmode">
    <div class="container w-full xs:pb-10 xl:!px-[14%] 2xl:!px-[11%] vxs:p-2 overflow-auto">

        <!-- Go Back Button -->
        <div class="mb-6 w-full flex justify-start">
            <button (click)="goBack()"
                class="text-blue-600 border border-blue-600 font-normal text-xl px-6 py-3 rounded-md">
                Go Back
            </button>
        </div>

        <!-- Station Selection & Pin Input -->
        <div class="grid grid-cols-4 gap-4 mb-6 items-center text-center">
            <div class="col-span-1">
                <p class="text-xl font-light text-gray-900">Select Station</p>
            </div>
            <div class="col-span-3">
                <input type="text" class="w-full bg-gray-100 text-center h-14 text-6xl xs:text-4xl rounded hidden-text"
                    [ngModel]="result" readonly />
            </div>
        </div>

        <!-- Desktop & Tablet Layout -->
        <div *ngIf="!isMobile" class="grid grid-cols-4 gap-6 w-full">

            <!-- Column 1: Station Selection -->
            <div class="flex flex-col gap-4">
                <button *ngFor="let item of terminalList" (click)="setTerminal(item)"
                    class="border border-gray-300 h-[3.5rem] text-[17px] font-normal rounded-md text-gray-900 w-full px-4 py-3 transition"
                    [ngClass]="{'bg-blue-600 text-white': selectedTerminal == item.id}"
                    [disabled]="isTerminalActive(item.id)">
                    {{ item.name }}
                </button>
            </div>

            <!-- Column 2 & 3: Keypad -->
            <div class="col-span-2 flex justify-center">
                <div class="grid grid-cols-3 gap-2 w-full">
                    <button *ngFor="let num of keypadKeys; trackBy: trackByKey" (click)="pressNum(num)"
                        class="border text-4xl font-normal  rounded-md hover:bg-gray-100 transition" style="aspect-ratio: 1 / 1;">
                        {{ num }}
                    </button>
                    <button (click)="pressNum('0')"
                        class="border text-4xl font-normal  rounded-md hover:bg-gray-100 transition" style="aspect-ratio: 1 / 1;">0</button>
                    <button (click)="allClear()"
                        class="border text-4xl font-normal  rounded-md hover:bg-gray-100 transition" style="aspect-ratio: 1 / 1;">C</button>
                    <button (click)="clear()"
                        class="border text-4xl font-normal  rounded-md hover:bg-gray-100 transition" style="aspect-ratio: 1 / 1;">
                        <span class="flex justify-center">
                            <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
                                    fill="#636363" />
                            </svg>
                        </span>
                    </button>
                </div>
            </div>

            <!-- Column 4: Login & Cancel Buttons -->
            <div class="grid grid-cols-1 grid-rows-4 gap-2">
                <button [disabled]="input?.length < 4 || !selectedTerminal" (click)="logInCheckDrawer()"
                    class="w-full bg-[#039900] text-white text-[33px] font-normal rounded-[4px] py-[21px] border border-[#039900] shadow-md tracking-[1.25px] leading-[20px] cursor-pointer hover:bg-[#028800] transition">
                    Login
                </button>
                <label *ngIf="errorMessage" class="text-red-600  fs-13 font-weight-bold" translate>
                    Invalid pin
                </label>
                <div></div>
                <div></div>
                <button (click)="onNoClick()"
                    class="w-full text-gray-900 text-[33px] font-normal rounded-[4px] py-[21px] border border-gray-300 tracking-[1.25px] leading-[20px] cursor-pointer hover:bg-gray-100 transition">
                    Cancel
                </button>
            </div>
        </div>

        <!-- Mobile Layout -->
        <div *ngIf="isMobile" class="flex flex-col items-center w-full gap-6">

            <!-- Station Selection (Stacked for Mobile) -->
            <div class="w-full grid grid-cols-3 gap-2">
                <button *ngFor="let item of terminalList" (click)="setTerminal(item)"
                    class="border border-gray-300 text-base font-normal rounded-md text-gray-900 w-full transition"
                    [ngClass]="{'bg-blue-600 text-white': selectedTerminal == item.id}"
                    [disabled]="isTerminalActive(item.id)">
                    {{ item.name }}
                </button>
            </div>

            <div class="grid grid-cols-4 xs:grid-cols-3 gap-4 xs:gap-2">
                <div class="numpad col-span-2 min-h-[200px] flex items-center justify-center overflow-auto">
                    <!-- Keypad (Stacked in 3 columns) -->
                    <div class="grid grid-cols-3 gap-2 w-full">
                        <button *ngFor="let num of keypadKeys; trackBy: trackByKey" (click)="pressNum(num)"
                            class="border text-3xl font-normal px-[24px] py-[18px] rounded-md hover:bg-gray-100 transition">
                            {{ num }}
                        </button>
                        <button (click)="pressNum('0')"
                            class="border text-3xl font-normal px-[24px] py-[18px] rounded-md hover:bg-gray-100 transition">0</button>
                        <button (click)="allClear()"
                            class="border text-3xl font-normal px-[24px] py-[18px] rounded-md hover:bg-gray-100 transition">C</button>
                        <button (click)="clear()"
                            class="border text-3xl font-normal px-[24px] py-[15px] rounded-md hover:bg-gray-100 transition">
                            <svg width="28" height="20" viewBox="0 0 36 28" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M33 0.5H10.5C9.465 0.5 8.655 1.025 8.115 1.82L0 14L8.115 26.165C8.655 26.96 9.465 27.5 10.5 27.5H33C34.65 27.5 36 26.15 36 24.5V3.5C36 1.85 34.65 0.5 33 0.5ZM33 24.5H10.605L3.6 14L10.59 3.5H33V24.5ZM15.615 21.5L21 16.115L26.385 21.5L28.5 19.385L23.115 14L28.5 8.615L26.385 6.5L21 11.885L15.615 6.5L13.5 8.615L18.885 14L13.5 19.385L15.615 21.5Z"
                                    fill="#636363" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div class="grid grid-cols-1 grid-rows-4 gap-2">
                    <button (click)="logInCheckDrawer()"
                        class="w-full bg-[#039900] text-white text-2xl font-normal rounded-md py-4 shadow-md hover:bg-[#028800] transition">
                        Login
                    </button>
                    <label *ngIf="errorMessage" class="text-red-600  fs-13 font-weight-bold" translate>
                        Invalid pin
                    </label>
                    <div></div>
                    <div></div>
                    <button (click)="onNoClick()"
                        class="w-full border border-gray-300 text-2xl font-normal text-gray-900 rounded-md py-4 hover:bg-gray-100 transition">
                        Cancel
                    </button>
                </div>

            </div>


        </div>
    </div>
</div>