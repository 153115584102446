import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrdersWithPayment } from 'src/app/_models/order';
import { Store } from 'src/app/_models/store';
import { OrderService } from 'src/app/_services/order.service';
import { TerminalService } from 'src/app/_services/terminal.service';
import { StoreService } from 'src/app/_services/store.service';
import { UserService } from 'src/app/_services/user.service';
import { POSService } from 'src/app/_services/pos-service';
import { WebSocketSubject } from 'rxjs/webSocket';
import { PermissionService } from '../../_services/permission.service';
import { TerminalStatusService } from 'src/app/_services/terminal-status.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayRef } from '@angular/cdk/overlay';
import { CashDrawerManagementComponent } from '../cash-drawer-management/cash-drawer-management.component';
import { PinConfirmComponent } from '../pin-confirm/pin-confirm.component';
import { AlertsMessagesComponent } from 'src/app/alerts-messages/alerts-messages.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pos-full-page',
  templateUrl: './pos-full-page.component.html',
  styleUrls: ['./pos-full-page.component.scss']
})
export class PosFullPageComponent {
  input = '';
  result = null;
  userPin: string;
  orderHash: string;
  orderWithPayment: OrdersWithPayment;
  store: Store;
  terminalId;
  terminalList = [];
  getUserProfile = [];
  errorMessage = false;
  disableInputButton: boolean;
  terminalStatus = [];
  storeId = JSON.parse(localStorage.getItem('currentUser'));
  selectedTerminal: string;
  private updateTimerId: any;
  getLoginUser: any;
  getUserPin: any;
  storeSub: Subscription;
  allUserPins: any = [];
  allUserData: any;
  browser: string;
  private terminalButtonSocket: WebSocketSubject<any>;
  selectedTerminalDetail: any;
  isMobile: boolean;
  isUserLoading: number = 1;
  permission: any;
  isLoading: boolean = false;
  keypadKeys: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  @Output() closeOverlay = new EventEmitter<any>();
  public overlayRef: OverlayRef
  constructor(
    private dialog: MatDialog,
    private orderService: OrderService,
    private terminalService: TerminalService,
    private storeService: StoreService,
    private userService: UserService,
    private posService: POSService,
    private _snackBar: MatSnackBar,
    private chRef: ChangeDetectorRef,
    private permissionService: PermissionService,
    private terminalStatusService: TerminalStatusService,

  ) {
    this.storeSub = this.storeService.current.subscribe((store) => {
      if (store) {
        this.store = Object.assign(new Store(), store);
        this.login();
        this.getTerminalList();
      }
    });
    this.isMobile = window.innerWidth <= 599;
  }

  public unsubscribe: Subject<null> = new Subject<null>();

  ngOnInit() {
    this.browser = localStorage.getItem('browser');
    console.log('browser dialog poss', this.browser);
    if (this.store && this.store.feature_flag['dark_mode']) {
      const element = document.getElementById('darkmode');
      element.classList.add('pos-dark-mode');
    } else {
      const element = document.getElementById('darkmode');
      element.classList.remove('pos-dark-mode');
    }
  }

  trackByKey(index: number, key: string): string {
    return key;
  }

  setTerminal(terminal: any) {
    this.selectedTerminalDetail = terminal;
    if (terminal) {
      this.selectedTerminal = terminal.id;
    }
  }

  isTerminalActive(terminalId: string) {
    const terminal = this.terminalStatus.find(
      (element) => element.terminal === terminalId
    );
    if (terminal) {
      return true;
    }
    return false;
  }

  pressNum(num: string) {
    const updatedText = this.input + num;
    if (updatedText.length === 5) {
      return;
    }
    this.input = this.input + num;
    this.calcAnswer();
  }

  getTerminalList() {
    this.orderService
      .getTerminalId(this.store.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        if (response.length > 0) {
          this.terminalList = response;
          this.chRef.detectChanges();
        }
      });
  }

  login() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.getUserPin = this.userService
      .getAllUser(this.store.id, user.username)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.allUserData = data;
        this.allUserPins = data.map((item) => {
          return item.user_profile.pin;
        });
        this.isUserLoading = 2;
        this.chRef.detectChanges();
      }, (error) => {
        console.error(error);
        this.isUserLoading = 3;
        this.chRef.detectChanges();
      });
  }

  clear() {
    if (this.input !== '') {
      this.input = this.input.substr(0, this.input.length - 1);
      if (!this.input) {
        this.result = null;
        return;
      }
      this.calcAnswer();
    }
  }

  allClear() {
    this.result = '';
    this.input = '';
  }

  logInCheckDrawer() {
    this.isLoading = true;
    const userObject = this.allUserData.find((user) => {
      return user.user_profile.pin == this.result.toString();
    });
    if (userObject) {
      this.permissionService.getUserPermissionById(this.store.id, userObject.id).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
        this.permission = data;
        if (Object.keys(this.permission).length !== 0) {
          if (this.permission['ACCESS'].includes('ORDERS')) {
            if (this.store.feature_flag['cash_drawer_management']) {
              this.isLoading = false;
              this.posService.getManageDrawerStatus(this.store.id, this.selectedTerminal, userObject.id).pipe(takeUntil(this.unsubscribe)).subscribe(
                (data: any) => {
                  this.loginUser();
                },
                (error: any) => {
                  if (this.permission['POS_FUNCTIONS'].includes('MANAGE_DRAWER')) {
                    this.cashManageDrawer(userObject);
                    this.openDrawer();
                  } else {
                    this.userPinPermission();
                  }
                }
              );
            } else {
              this.loginUser();
            }
          } else {
            this.isLoading = false;
            this.allClear();
            this._snackBar.open('User does not have permission', 'OK');
          }
        }
        else {
          this.isLoading = false;
          this.allClear();
          this._snackBar.open('User does not have permission', 'OK');
        }
      });
    }
    else {
      this.isLoading = false;
      this.errorMessage = true
    }
  }

  loginUser() {
    const validPin = this.allUserPins.includes(this.result.toString());
    const userObject = this.allUserData.find((user) => {
      return user.user_profile.pin == this.result.toString();
    });
    if (validPin) {
      this.userService.checkTimeClockStatus(this.store.id, userObject.user_profile.user)
        .pipe(takeUntil(this.unsubscribe)).subscribe((tData) => {
          if (tData.length == 0 || tData[0].status != 'CLOCKED IN') {
            this._snackBar.open('Please punch your clock-in time in Time Clock to access the Terminal!', '', {
              duration: 5000,
            });
          } else {
            this.posService.validUserPin = true;
            this.getLoginUser = this.orderService
              .postLoginUser({
                terminal: this.selectedTerminal,
                user: userObject.user_profile.user,
              }).pipe(takeUntil(this.unsubscribe))
              .subscribe((data) => {
                this.isLoading = false;
                localStorage.setItem('currentPIN', userObject.user_profile.pin);
                // this.dialogRef.close({
                //   sessionId: data.id,
                //   terminalId: data.terminal,
                //   user: userObject.user_profile.user,
                //   currentUser: userObject,
                //   getTerminalName: data.terminal_name
                // });
                
                if (!!data.terminal_id) {
                  localStorage.setItem('selectedTerminal', data.terminal_id);
                  localStorage.setItem('terminalId', this.selectedTerminal);
                  localStorage.setItem('posLoggedUser', `${userObject.first_name} ${userObject.last_name}`);
                  localStorage.setItem('selectedTerminalName', data.terminal_name);
                  localStorage.setItem('pinUser', userObject.user_profile.user);
                  this.permissionService.getCurrentUserPermissionByPIN(this.store.id, this.result);
                  this.terminalStatusService.updateStatus({ terminal_id: data.terminal_id, status: 'ON' });
                }

                this.closeOverlay.emit({
                  sessionId: data.id,
                  terminalId: data.terminal,
                  user: userObject.user_profile.user,
                  currentUser: userObject,
                  getTerminalName: data.terminal_name
                });
              }, error => {
                this._snackBar.open('Please select another Terminal!', '', {
                  duration: 3000,
                });
              });
          }
        });
    } else {
      this.errorMessage = true;
    }
  }

  calcAnswer() {
    const formula = this.input;
    this.result = formula;
  }

  onNoClick(): void {
    this.closeOverlay.emit({ reason: 'cancel' });
  }

  number(str) {
    return +str;
  }

  cashManageDrawer(user) {
    let dialogRef = this.dialog.open(CashDrawerManagementComponent, {
      width: '94vw',
      maxWidth: '100vw',
      data: {
        'store_id': this.store.id,
        'terminal_id': this.selectedTerminal,
        'user_id': user.id,
        'terminal_name': this.selectedTerminalDetail.name,
        'status': false
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loginUser();
      }
    });
  }

  openDrawer() {
    this.posService.openCashDrawer(this.selectedTerminalDetail.terminal_id).pipe(takeUntil(this.unsubscribe)).subscribe(
      (data) => {
        console.log('drawer opened');
        this.orderService.errorMessage('Cash drawer opened successfully', 'OK', 1000);
      },
      (error) => {
        this.orderService.errorMessage('Unable to open cash drawer', 'OK', 2000);
      }
    );
  }

  userPinPermission() {
    const dialogRef = this.dialog.open(PinConfirmComponent, {
      width: '500px',
      // height: '410px',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      const pinPermission = data.permission;
      if (pinPermission) {
        const isManageDrawerPermission = pinPermission['POS_FUNCTIONS'].find(item => item === 'MANAGE_DRAWER');
        if (isManageDrawerPermission && this.store.feature_flag['cash_drawer_management']) {
          const userObject = this.allUserData.find((user) => {
            return user.user_profile.pin == this.result.toString();
          });
          this.posService.getManageDrawerStatus(this.store.id, this.selectedTerminal, userObject.id).pipe(takeUntil(this.unsubscribe)).subscribe(
            (data: any) => {
              this.loginUser();
            },
            (error: any) => {
              this.cashManageDrawer(userObject);
              this.openDrawer();
            }
          );
        } else {
          const msg = 'This User/Manager does not have the required permission to perform this operation!'
          this.alertPopup('', msg)
        }
      }


    });
  }

  alertPopup(name, msg) {
    const dialogRef = this.dialog.open(AlertsMessagesComponent, {
      disableClose: true,
      width: '364px',
      data: {
        message: msg
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      if (result) {

      }
    });
  }

  goBack(): void {
    this.closeOverlay.emit({ reason: 'cancel' });
  }

  ngOnDestroy() {
    clearInterval(this.updateTimerId);
    this.storeSub.unsubscribe();
  }

}
