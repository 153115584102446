<div class="flex flex-col gap-3 h-full items-center py-8 p-[20px] w-screen h-screen bg-white">
  <div class="container">
    <p class="cardPayment xs:!text-2xl">
      Card Payment In Progress:
    </p>

    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>

    <div class="status">
      <p *ngFor="let log of logs">{{log}}</p>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="!isConfirmed"></mat-progress-bar>
  </div>

  <div>
    <button mat-flat-button *ngIf="isError && !isConfirmed" (click)="sendPaymentRequest()">Retry</button>
    <!--    <button mat-flat-button color="warn" *ngIf="isConfirmed" (click)="voidTransaction()">Void</button>-->

    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button mat-dialog-close [disabled]="!isConfirmed" color="primary" (click)="done()">Done</button>
  </div>
</div>