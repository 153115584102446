import { Component, ComponentRef, EventEmitter, Inject, Injector, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CheckoutDialogComponent } from '../checkout-dialog/checkout-dialog.component';
import { OrdersWithPayment } from 'src/app/_models/order';
import { takeUntil } from 'rxjs/operators';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-gift-card-partial-payment',
  templateUrl: './gift-card-partial-payment.component.html',
  styleUrls: ['./gift-card-partial-payment.component.scss']
})
export class GiftCardPartialPaymentComponent {
  @Output() closeCheckoutOverlay = new EventEmitter<boolean>();
  crdBalance: number;
  amount: number;
  remainingAmount: number;
  private destroySubject: Subject<void> = new Subject();
  private overlayRef!: OverlayRef;
  constructor(
    public dialogRef: MatDialogRef<GiftCardPartialPaymentComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private overlay: Overlay,
    private injector: Injector
  ) {
    console.log('Gift Card Dialog Data', data);

    this.crdBalance = this.data.data.card_blnce;
    this.amount = this.data.data.amnt;
    this.remainingAmount = Number((this.amount - this.crdBalance).toFixed(2));
  }

  ngOnInit() {
  }

  partialChekout(pt: string) {
    if (pt === 'cash') {
      const orderHash = localStorage.getItem('order_hash');
      // const dialogConfig = new MatDialogConfig();
      // dialogConfig.disableClose = true;
      // dialogConfig.autoFocus = true;

      // const isMobile = window.innerWidth <= 470;
      // const dialogRef = this.dialog.open(CheckoutDialogComponent, {
      //   id: 'gift-checkout-dialog',
      //   width: isMobile ? '100vw' : '630px',
      //   height: isMobile ? 'auto' : '660px',
      //   maxWidth: isMobile ? '100vw' : '',
      //   data: {
      //     openFromgift: true,
      //     orderWithPayment: this.data.data.order,
      //     terminalId: this.data.data.terminalId,
      //     cashTotal: Number((this.amount - this.crdBalance).toFixed(2)),
      //     cashDueTotal: Number((this.amount - this.crdBalance).toFixed(2)),
      //     card_amount: this.crdBalance,
      //     card_id: this.data.data.card_id,
      //     from: 'gift-partial-payment'

      //   },
      //   panelClass: 'checkout-mobile',
      // });

      // dialogRef.afterClosed().subscribe(isComplete => {
      //   if (isComplete) {
      //     this.dialogRef.close(isComplete);
      //     this.dialog.closeAll();
      //   }
      // });

      const data = {
        openFromgift: true,
        orderWithPayment: this.data.data.order,
        terminalId: this.data.data.terminalId,
        cashTotal: Number((this.amount - this.crdBalance).toFixed(2)),
        cashDueTotal: Number((this.amount - this.crdBalance).toFixed(2)),
        card_amount: this.crdBalance,
        card_id: this.data.data.card_id,
        from: 'gift-partial-payment'
      }
      this.openOverlay(CheckoutDialogComponent, data, '');
    } else {
      this.closePartialCash(pt);
    }

  }

  closePartialCash(e) {
    const data = {
      data: true,
      type: e
    }
    this.dialogRef.close(data);
  }

  cancel() {
    this.dialogRef.close(false);
  }


  openOverlay(component, data, e) {
    if (this.overlayRef) {
      this.closeLay();
    }

    data.eventsource = 'gift-cd';

    let overlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
    };
    if (component === CheckoutDialogComponent) {
      overlayConfig = { ...overlayConfig, positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(), };
    }

    this.overlayRef = this.overlay.create(overlayConfig);


    const injector = this.createInjector(data);

    const portal = new ComponentPortal(component, null, injector);
    const componentRef: ComponentRef<any> = this.overlayRef.attach(portal);

    componentRef.instance.closeGiftOverlay?.pipe(takeUntil(this.destroySubject)).subscribe((isComplete: any) => {
      this.closeLay();
      if (isComplete?.value) {
        this.closeCheckoutOverlay.emit(true);
      } else if (isComplete) {
        this.dialogRef.close(true);
      }
      
    });
  }

  closeLay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  private createInjector(data: any): Injector {
    return Injector.create({
      providers: [
        { provide: 'OVERLAY_DATA', useValue: data },
        { provide: OverlayRef, useValue: this.overlayRef },
      ],
      parent: this.injector,
    });
  }
}