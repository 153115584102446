<div class="flex flex-col w-screen h-screen bg-white p-5">
  <div class="flex flex-row">
    <button mat-button type="button" (click)="goBack(1)" class="go-back" tabindex="-1">Go Back</button>
  </div>


  <div class="flex flex-col items-center h-[90%] m-10 !mx-0 !mt-4" *ngIf="!isMobile">
    <span class="lab-text-blue text-4xl text-center !mb-[4%]">
      Would You Like To Leave A Tip?
    </span>

    <span class="text-5xl">
      {{order.getTotal() | currency}}
    </span>

    <span class="text-2xl !mt-[4%]">
      {{taxDueTotal | currency}} <span *ngIf="tipAmount > 0">+ {{tipAmount | currency}} Tip</span>
    </span>

    <div class="tipBox !mt-[6%] !mb-[7%]" *ngIf="tips">
      <div class="grid gap-4" [ngClass]="{
        'grid-cols-2': tips.length === 2,
        'grid-cols-3': tips.length === 3,
        'grid-cols-4': tips.length === 4,
        'grid-cols-5': tips.length >= 5
      }">
        <button
          class="text-center border-4 lab-border-blue rounded-lg bg-white drop-shadow-md w-[14rem] md:w-[8rem] lg:w-[11rem] xl:w-[14rem] min-w-[8rem] min-h-[8rem] aspect-square"
          *ngFor="let tip of tips; let index = index" (click)="calculateTip(tip)"
          [ngClass]="{'lab-bg-blue text-white': tipPercent === tip.value, 'bg-white': tipPercent !== tip.value}">
          <span class="flex flex-col h-full items-center justify-center">
            <ng-container
              *ngIf="tip.name == 'Custom' && tip.value != 'custom'; then defaultBlock; else oldBlock "></ng-container>
            <ng-template #defaultBlock>
              <span class="text-3xl">
                {{tip.amount | currency}}
              </span>
            </ng-template>
            <ng-template #oldBlock>
              <span *ngIf="tip.name == 'No Tip' || tip.name == 'Custom'" class="text-3xl">
                {{tip.name}}
              </span>
              <span *ngIf="tip.name != 'No Tip' && tip.name != 'Custom'" class="text-3xl">
                {{tip.name}}
              </span>
              <span *ngIf="tip.amount > 0" class="tip-amount" class="text-xl">
                {{tip.amount | currency}}
              </span>
            </ng-template>
          </span>
        </button>
      </div>
    </div>
    <button class="w-full h-20 rounded-md text-2xl min-h-[80px]" (click)="onPay(0)"
      [ngStyle]="{'background-color':tipAmount > 0 || tipAmount === 0 ? '#0c83ff' : '#C4C4C4' }"
      [disabled]="!tipAmount && tipAmount != 0">
      <span class="add-size" [ngStyle]="{'color': '#FFF', 'margin-bottom': '4%'}">Pay</span>
    </button>
  </div>

  <div class="flex flex-col items-center h-[85%] m-10 xs:!m-0 xs:!mt-[20px]" *ngIf="isMobile">
    <span class="lab-text-blue text-4xl text-center xs:!text-2xl !mb-[20px]">
      Leave Us A Tip
    </span>
    <div class="flex mb-[4%] flex-row flex-nowrap justify-evenly !w-full" *ngIf="tips">
      <div class="grid gap-4 !grid-cols-1 !w-[90%]">
        <button
          class="text-center border-4 lab-border-blue rounded-lg bg-white drop-shadow-md min-w-[8rem] min-h-[8rem] !p-[14px]"
          *ngFor="let tip of tips; let index = index" (click)="calculateTip(tip)"
          [ngClass]="{'lab-bg-blue text-white': tipPercent === tip.value, 'bg-white lab-text-blue': tipPercent !== tip.value}">
          <span class="flex flex-row h-full items-center justify-center">
            <span *ngIf="tip.name == 'No Tip' || tip.name == 'Custom'" class="text-3xl">
              {{tip.name }}
            </span>
            <span *ngIf="tip.name != 'No Tip' && tip.name != 'Custom'" class="!text-[34px] font-medium">
              {{tip.name}}&nbsp;
            </span>
            <span *ngIf="tip.amount > 0" class="tip-amount" class="!text-3xl">
              = {{tip.amount | currency}}
            </span>
          </span>
        </button>
      </div>
    </div>
    <button class="w-full h-20 rounded-md text-[34px] !mt-7" (click)="onPay(0)"
      [ngStyle]="{'background-color':tipAmount > 0 || tipAmount === 0 ? '#0c83ff' : '#C4C4C4' }"
      [disabled]="!tipAmount && tipAmount != 0">
      <span class="add-size" [ngStyle]="{'color': '#FFF', 'margin-bottom': '4%'}">Pay</span>
    </button>
  </div>
</div>